import { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import Helper from "../utils/helpers";
import { CURRENCY_STYLE } from "../constants";
import { useForm } from "react-hook-form";
import { ErrorLabel } from "./ErrorLabel";
import { Link } from "react-router-dom";
import ListingService from "../services/ListingService";

import { Toast } from 'primereact/toast';

export const ListingOffer = ({info, listing, onLoginFailed, onSubmitBid}) => {
    const [show, setShow] = useState(false);
    const [min, setMin] = useState(parseFloat(listing.starting_price));
    const toast = useRef(null);

    useEffect(() => {
        if (listing.bids && listing.bids.length) {
            const max = Helper.maxInArray(listing.bids);
            setMin(max);
        }

    }, [listing])

    const handleOffer = () => {
        if (!info) {
            onLoginFailed();
            return;
        }
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = async data => {
        const payload = {
            amount: data.amount,
            listing_type: listing.listing_option,
            owner_id: listing.user_id,
            listing_id: listing.id
        }

        ListingService.bid(payload)
        .then(() => {
            setShow(false);
            reset();
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Awesome!, Your Bid has been sent to Owner. Once it\'s approved it will be listed on the page.', life: 3000 });
        })
        .catch(e => {
            toast.current.show({ severity: 'error', detail: e.response?.data?.message, life: 3000 });
        });

    }

    const handleBuyNow = () => {

    }


    return(
        <>
            {listing && listing.listing_option === 'auction' &&
                <Button variant="primary" onClick={()=>handleOffer()} size="lg" type="button">Make Offer</Button>
            }

            <Button variant="success" onClick={()=>handleBuyNow()} size="lg" type="button">Buy Now ({Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(listing.buy_now_price)})</Button>
            
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                <Modal.Title>Make Your Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>By placing this bid, you are committing to buy <strong className="text-primary">{listing.website_business_name}</strong> from <strong className="text-primary">{listing.user.name}</strong> as per our Terms and Conditions. The Current Price is <strong>{Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(min)}</strong></p>
                    <h3 className="text-dark text-center">Buyer Safety</h3>
                    <p className="text-dark">{process.env.REACT_APP_SITE_NAME} recommends completing thorough due diligence before placing a bid on a listing. Make sure all claims (ownership, revenue, costs, traffic, etc.) are legitimate, and there are no hidden attributes associated to the asset. Ensure you ask all of your questions about the asset before bidding. As a safety measure and to protect your interest, please ensure all communication with the seller is conducted on {process.env.REACT_APP_SITE_NAME}.</p>

                    <Toast ref={toast} />
                    <Form  onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Reserve Price</Form.Label>
                            <InputGroup >
                                <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                <Form.Control 
                                    type='text'
                                    size="lg"
                                    {...register('amount', {
                                        required: true,
                                        patter: {
                                            value: /^(0|[1-9][0-9]*)$/,
                                            message: 'Enter a valid number' 
                                        },
                                        min: min
                                    })}
                                />
                            </InputGroup>
                            {(errors.amount && errors.amount.type==='required') && <ErrorLabel >Amount is required</ErrorLabel>}
                            {(errors.amount && errors.amount.type==='min') && <ErrorLabel >Min value is {Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(min)}</ErrorLabel>}
                            {(errors.amount?.message) && <ErrorLabel >{errors.amount?.message}</ErrorLabel>}
                        </Form.Group>

                        <div className="d-grid gap-3 my-3">
                            <Button variant="primary" size="lg" type="submit">Submit bid</Button>

                            <Link to={``}  className="btn btn-success btn-lg">Buy Now for {Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(listing.buy_now_price)}</Link>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
        
    )
}