import { useEffect, useState } from "react";
import { Card, Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";


import ListingService from "../../services/ListingService";

import { ErrorLabel, Spinner } from "../../components";
import { useToast } from "../../hooks/ToastContext";

export const SellOption = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [option, setOption] = useState('auction');
    const [serverError, setServerError] = useState([]);
    const [type, setType] = useState('website');
    const showToast = useToast();

    const [auctionValidation, setAuctionValidation] = useState({
        startingPrice: {
            required: true,
            patter: {
                value: /^(0|[1-9][0-9]*)$/,
                message: 'Enter a valid number' 
            }
        },
        reservePrice: {
            required: true,
            patter: {
                value: /^(0|[1-9][0-9]*)$/,
                message: 'Enter a valid number' 
            }
        },
        buyNow: {
            patter: {
                value: /^(0|[1-9][0-9]*)$/,
                message: 'Enter a valid number' 
            }
        },
    })

    const [classifiedValidation, setClassifiedValidation] = useState({
        minOffer: {
            required: true,
            patter: {
                value: /^(0|[1-9][0-9]*)$/,
                message: 'Enter a valid number' 
            }
        },
        buyNow: {
            patter: {
                value: /^(0|[1-9][0-9]*)$/,
                message: 'Enter a valid number' 
            }
        },
    })

    const handleValidation = (type) => {
        setAuctionValidation({});
        setClassifiedValidation({})
        if (type === 'auction') {
            setAuctionValidation({
                startingPrice: {
                    required: true,
                    patter: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: 'Enter a valid number' 
                    }
                },
                reservePrice: {
                    required: true,
                    patter: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: 'Enter a valid number' 
                    }
                },
                buyNow: {
                    required: true,
                    patter: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: 'Enter a valid number' 
                    }
                },
            })
        }

        if (type === 'classified') {
            setClassifiedValidation({
                minOffer: {
                    required: true,
                    patter: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: 'Enter a valid number' 
                    }
                },
                buyNow: {
                    required: true,
                    patter: {
                        value: /^(0|[1-9][0-9]*)$/,
                        message: 'Enter a valid number' 
                    }
                },
            })
        }

        setOption(type);
    }

    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = data => {
        setServerError([])
        setSpinner(true)

        let payload = {};
        if (option === 'auction') {
            payload = {
                starting_price: data.starting_price,
                reserve_price: data.reserve_price,
                buy_now_price: data.buy_now_price,
            }
        } else {
            payload = {
                min_offer: data.min_offer,
                buy_now_price: data.buy_now_price,
            }
        }

        payload['listing_option'] =  data.listing_option;
        payload['type'] =  type;
        payload['step'] =  1;
        


        ListingService.update(id, payload)
        .then(() => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/pay/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }


    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            setType(res.data.type);
            reset(res.data);
            if (!res.listing_option) {
                res.listing_option = 'auction'
            }
            setOption(res.listing_option);
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    return (
        <Card>
            <Card.Header>
                Selling Options
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <div className="form-check form-check-inline">
                                <Form.Check
                                    type="radio"
                                    label="Auction"
                                    name="listing_option"
                                    id="auction"
                                    value="auction"
                                    {...register('listing_option', {})}
                                    onClick={() => handleValidation('auction')}
                                    checked={option === "auction"}
                                />
                            </div>
                            <div className="form-check form-check-inline">
                                <Form.Check
                                    type="radio"
                                    label="Classified"
                                    name="listing_option"
                                    id="classified"
                                    value="classified"
                                    {...register('listing_option', {})}
                                    onClick={() => handleValidation('classified')}
                                    checked={option === "classified"}
                                />
                            </div>
                            
                            
                        </Col>
                    </Row>

                    <hr />
                    
                    { option === 'auction' && 
                    <Row>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Starting Price</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                    <Form.Control 
                                        type='text'
                                        {...register('starting_price', auctionValidation.startingPrice)}
                                    />
                                </InputGroup>
                                {(errors.starting_price && errors.starting_price.type==='required') && <ErrorLabel >Starting price is required</ErrorLabel>}
                                {(errors.starting_price?.message) && <ErrorLabel >{errors.starting_price?.message}</ErrorLabel>}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Reserve Price</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                    <Form.Control 
                                        type='text'
                                        {...register('reserve_price', auctionValidation.reservePrice)}
                                    />
                                </InputGroup>
                                {(errors.reserve_price && errors.reserve_price.type==='required') && <ErrorLabel >Reserve price is required</ErrorLabel>}
                                {(errors.reserve_price?.message) && <ErrorLabel >{errors.reserve_price?.message}</ErrorLabel>}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Buy Now Price</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                    <Form.Control 
                                        type='text'
                                        {...register('buy_now_price', auctionValidation.buyNow)}
                                    />
                                </InputGroup>
                                {(errors.buy_now_price && errors.buy_now_price.type==='required') && <ErrorLabel >Buy now price is required</ErrorLabel>}
                                {(errors.buy_now_price?.message) && <ErrorLabel >{errors.buy_now_price?.message}</ErrorLabel>}
                            </Form.Group>
                        </Col>
                    </Row>}

                    { option === 'classified' && 
                        <Row>
                            
                            <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Minimum Offer</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                    <Form.Control 
                                        type='text'
                                        {...register('min_offer', classifiedValidation.minOffer)}
                                    />
                                </InputGroup>
                                {(errors.min_offer && errors.min_offer.type==='required') && <ErrorLabel >Minimum offer is required</ErrorLabel>}
                                {(errors.min_offer?.message) && <ErrorLabel >{errors.min_offer?.message}</ErrorLabel>}
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group>
                                <Form.Label>Buy Now Price</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                    <Form.Control 
                                        type='text'
                                        {...register('buy_now_price', classifiedValidation.buyNow)}
                                    />
                                </InputGroup>
                                {(errors.buy_now_price && errors.buy_now_price.type==='required') && <ErrorLabel >Buy now price is required</ErrorLabel>}
                                {(errors.buy_now_price?.message) && <ErrorLabel >{errors.buy_now_price?.message}</ErrorLabel>}
                            </Form.Group>
                        </Col>
                    </Row>}
                    

                    <Row>
                        <Col lg={12} className="text-right">
                            <Button className="my-4" type="submit" color="primary" >Next</Button>
                        </Col>
                    </Row>
                    

                    {spinner && (
                        <Spinner />
                    )}

                    
                </Form>
            </Card.Body>
        </Card>
    );
}