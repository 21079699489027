import { useEffect, useState } from "react";
import ListingService from "../services/ListingService";
import { CategoryLink } from "./CategoryLink";
import { Loader } from "./Loader";

export const CategoryWidget = () => {

    const [categories, setCategories] = useState(null);
    useEffect(()=> {
        ListingService.categories()
        .then(res => {
            setCategories(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    return (
        <section className="container text-center">
            <h4>Popular Listing Categories</h4>
            <p>Explore the Hottest Categories - Find Your Next Venture! </p>

            <div className="p-3">
                {!categories && (<Loader />)}
                {categories && categories.map(category => {
                    return <CategoryLink key={category.id} category={category} /> 
                })}
            </div>
        </section>
        
    );
}