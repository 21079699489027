export const PageHeaderComponent = ({title, summary}) => {

    return (
        <div className="page-title">
            <div className="container">
                <h1 className="text-light">{title}</h1>
                {summary && <p className="text-light ">{summary}</p>}
            </div>
            
        </div>
    )
}