import { CURRENCY_STYLE } from "../constants";

export const ListingNetProfit = ({anual}) => {

    let profit = null;
    if(anual) {
        profit = parseFloat(anual) / 12;
    }
    
    return (
        <div className="text-dark  ">
            {profit ? (` ${Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(profit)} p/mo`) : ('N/A') }
        </div>
    )
}