import { ListGroup } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

export const DomainLinks = ({step, id, current = 0}) => {
    const links = [
        {
            step: 1,
            to: '/user/listings/domain/' + id,
            title: 'Domain'
        },
        {
            step: 2,
            to: '/user/listings/domain-business/' + id,
            title: 'Business Information'
        },
        {
            step: 3,
            to: '/user/listings/domain-meta/' + id,
            title: 'Meta Information' 
        },
        {
            step: 4,
            to: '/user/listings/domain-description/' + id,
            title: 'Description' 
        },
        {
            step: 5,
            to: '/user/listings/domain-cover/' + id,
            title: 'Cover Image' 
        }

    ];

    return(
        <>
            <ListGroup className="my-1">
                <ListGroup.Item>
                    <Link to="/user/selling">Complete Listing</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/user/drafted">Incomplete Listing</Link>
                </ListGroup.Item>
            </ListGroup>

            <ListGroup  as="ol" numbered>
                {links.map(item => (
                    <ListGroup.Item as="li"  key={item.step}>
                        {item.step <= step ? (
                            <NavLink className={`${current === item.step ? 'strong italic ' : ''}`} to={item.to}>{item.title}</NavLink>
                        ) : (
                            <span>{item.title}</span>
                        )}
                        
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
        
    );
}