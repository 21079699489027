
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button, Badge } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ListingService from "../../services/ListingService";





export const BusinessModelPage = () => {
	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors } } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

	useEffect(() => {
        ListingService.businessModels()
        .then(res => {
            setCategories(res.data)
        })
        .catch(e => {
            console.log(e);
        })

	}, [])

	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			
			const selection = [];
			user.data.business_models.map((category) => {
				selection.push(category.id);
			});
			setSelectedCategories(selection);
		})
		.catch(e => {
			console.log(e);
		})
	}, [id])

	const onSubmit = data => {
        data['model'] = selectedCategories
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/business-industry/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

    const handleCheckboxChange = (categoryId) => {
        setSelectedCategories((prevSelectedCategories) => {
          if (prevSelectedCategories.includes(categoryId)) {
            return prevSelectedCategories.filter((id) => id !== categoryId);
          } else {
            return [...prevSelectedCategories, categoryId];
          }
        });
      };

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
					<CardBody>
						<ProgressBar variant="success" now={18} />

                        <h3 className="text-center text-dark my-5">Which types of business models are you interested in exploring?</h3>
						<form onSubmit={handleSubmit(onSubmit)}>
								
							{ serverError && serverError.map((e, i) => (
								<p key={i} className="text-danger">{e}</p>
							))}

							

							<div className="form-group my-4">
								
                                <div className="">
									{categories && categories.map(category => (
										<Badge bg="secondary" className="m-1" key={category.id}>
											<input checked={selectedCategories.includes(category.id)} onChange={() => handleCheckboxChange(category.id)} type="checkbox" id={`cat-${category.id}`} /> <label htmlFor={`cat-${category.id}`}>{category.name}</label>
										</Badge>
										
									))}
								</div>
							</div>

							
							<div className="d-flex justify-content-between my-5">
								<Button onClick={()=>{navigate('/profile/business-experience/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
								<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
							</div>
								
								
						</form>
					</CardBody>
			</Card>
			
		</div>
	);
}
