import { ListGroup } from "react-bootstrap"
import { FaEye, FaSignOutAlt, FaTag } from "react-icons/fa"
import { FaEarthEurope, FaGauge, FaListCheck, FaMessage, FaUsers } from "react-icons/fa6"
import { NavLink, useNavigate } from "react-router-dom"
import AuthService from "../services/AuthService"
import { LogoutButton } from "./LogoutButton"

export const AdminNav = () => {
    return (
        <ListGroup className=" m-2 dashboard-nav d-none d-md-block d-lg-block">
            <ListGroup.Item><NavLink to={`/admin/dashboard`}> <FaGauge /> Dashboard</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/admin/listings`}> <FaListCheck /> Listings</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/admin/users`}> <FaUsers /> Users</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/admin/packages`}> <FaEye /> Packages</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/admin/pages`}> <FaEarthEurope /> Pages</NavLink></ListGroup.Item>
            <ListGroup.Item><LogoutButton icon={true} /></ListGroup.Item>
        </ListGroup>
    )
}