import { ListGroup } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

export const WebsiteLinks = ({step, id, current = 0}) => {
    const links = [
        {
            step: 1,
            to: '/user/listings/website/' + id,
            title: 'Website URL'
        },
        {
            step: 2,
            to: '/user/listings/business/' + id,
            title: 'Business Information' 
        },
        {
            step: 3,
            to: '/user/listings/financial/' + id,
            title: 'Financial Information' 
        },
        {
            step: 4,
            to: '/user/listings/monetization/' + id,
            title: 'Monetization Method' 
        },
        /*{
            step: 5,
            to: '/user/listings/integration/' + id,
            title: 'Integration' 
        },*/
        {
            step: 6,
            to: '/user/listings/meta/' + id,
            title: 'Meta Information' 
        },
        {
            step: 7,
            to: '/user/listings/description/' + id,
            title: 'Description' 
        },
        {
            step: 8,
            to: '/user/listings/social/' + id,
            title: 'Social Following' 
        },
        {
            step: 9,
            to: '/user/listings/images/' + id,
            title: 'Banner' 
        }
    ];

    return(
        <>
            <ListGroup className="my-1">
            <ListGroup.Item>
                    <Link to="/user/selling">Complete Listing</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/user/drafted">Incomplete Listing</Link>
                </ListGroup.Item>
            </ListGroup>

            <ListGroup  as="ol" numbered>
                {links.map(item => (
                    <ListGroup.Item as="li"  key={item.step}>
                        {item.step <= step ? (
                            <NavLink className={`${current === item.step ? 'strong italic ' : ''}`} to={item.to}> {item.title}</NavLink>
                        ) : (
                            <span>{item.title}</span>
                        )}
                        
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </>
    );
}