import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";


import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { DomainLinks } from "./DomainLink";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

export const DomainUrl = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState('Domain listing');
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = data => {
        setSpinner(true)

        data.type = 'domain';
        data.step = step;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/domain-business/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Domain listing' : 'Domain Listing');
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    usePageTitle(title);


    return (

        <Row>
            <Col lg={3}>
                <DomainLinks step={step} id={id} current={1} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Create Domain Listing 
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Label htmlFor="url">Enter URL</Form.Label>
                                    <Form.Control type="text" size="lg"
                                        {...register('url', {
                                            required: true,
                                            pattern: {
                                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                                message: 'Enter a valid url'
                                            }  
                                        })}
                                        placeholder="https://yourdomain.com"
                                    />
                                    {(errors.url && errors.url.type==='required') && <ErrorLabel >URL is required</ErrorLabel>}
                                    {(errors.url?.message) && <ErrorLabel >{errors.url?.message}</ErrorLabel>}
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="text-right">
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        


    )
}