import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";



import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";


import { Card, Form, Button, Row, Col, InputGroup, ListGroup } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";


export const WebsiteMonetization = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [methods, setMethods] = useState([]);
    const [step, setStep] = useState(4);
    const [title, setTitle] = useState('Monetization Methods');
    const showToast = useToast();

    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data.step = step;
        const monetizationMethods = [];
        
        methods.map(({id, isChecked}) => {
            if (isChecked) {
                monetizationMethods.push(id);
            }
        })

        data.monetization_methods = monetizationMethods;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            // navigate(`/user/listings/integration/${id}`);
            navigate(`/user/listings/meta/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name + ' - Monetization Method');
            ListingService.methods().then(response => {                
                response.data.map((method, index) => {
                    const isSelected = res.data.methods.find(({id}) => id === method.id );
                    return response.data[index].isChecked = isSelected ? true : false;
                });
                setMethods(response.data);
            })
            .catch(e => {
                showToast({ severity: 'error',  detail: e.response?.data?.message })
            });
            
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

        

    }, []);
    usePageTitle(title);

    const handleMethodChange = (e, position) => {
        const updated = methods.map((method, i) => {
            if (i === position) {
                method['isChecked'] = e.target.checked;
            }
            return method;
        })
        setMethods(updated);
    }

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step}  current={5} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Monetization Methods
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <ListGroup>
                                {methods.map((method, index) => {
                                    return (
                                        <ListGroup.Item as="li" key={method.id}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    id={`method-${method.id}`}
                                                    name={`method-${method.id}`}
                                                    value={method.id}
                                                    checked={method.isChecked}
                                                    onChange={(e) => handleMethodChange(e,index)}
                                                />

                                                <label className="mx-3" htmlFor={`method-${method.id}`}>{method.name}</label>
                                            </div>
                                            <div className="text-secondary">
                                                {method.description}
                                            </div>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>                            

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/financial/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}