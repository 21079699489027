import { ActiveListings, CategoryWidget, ServicesComponent, SearchFormComponent } from "../../components";
import { usePageTitle } from '../../hooks/usePageTitle'

import { StepToSuccess } from "../../components/StepToSuccess";
import { TrendingSale } from "../../components/TrendingSale";

export const HomePage = () => {

    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Home');

    return (
        <>
            <SearchFormComponent />

            <CategoryWidget />

            <StepToSuccess />
            
            <ActiveListings />

            <TrendingSale />

            <ServicesComponent />
        </>
    );
}