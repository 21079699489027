import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import ListingService from "../../services/ListingService";
import { Accordion, Button } from "react-bootstrap";


export const AdvanceSearchbox = ({query, listings, handleSearchSubmit}) => {

    const { register, handleSubmit, reset } = useForm();

    

    const [categories, setCategories] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    useEffect(()=> {
        ListingService.categories()
        .then(res => {
            setCategories(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    const handleCheckboxChange = (categoryId) => {
        setSelectedCategories((prevSelectedCategories) => {
          if (prevSelectedCategories.includes(categoryId)) {
            return prevSelectedCategories.filter((id) => id !== categoryId);
          } else {
            return [...prevSelectedCategories, categoryId];
          }
        });
    };

    const [industries, setIndustries] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);

	useEffect(() => {
        ListingService.businessIndustries()
        .then(res => {
            setIndustries(res.data)
        })
        .catch(e => {
            console.log(e);
        })

	}, [])


    const handleIndustriesChange = (industryId) => {
        setSelectedIndustries((prevSelectedIndustries) => {
          if (prevSelectedIndustries.includes(industryId)) {
            return prevSelectedIndustries.filter((id) => id !== industryId);
          } else {
            return [...prevSelectedIndustries, industryId];
          }
        });
    };


    const [models, setModels] = useState([]);
    const [selectedModels, setSelectedModels] = useState([]);

	useEffect(() => {
        ListingService.businessModels()
        .then(res => {
            setModels(res.data)
        })
        .catch(e => {
            console.log(e);
        })

	}, [])

    const handleModelChange = (modelId) => {
        setSelectedModels((prevSelectedModels) => {
          if (prevSelectedModels.includes(modelId)) {
            return prevSelectedModels.filter((id) => id !== modelId);
          } else {
            return [...prevSelectedModels, modelId];
          }
        });
    };

  
    const onSubmit = (data) => {
        const payload = {
            industries: selectedCategories,
            models: selectedModels,
            categories: selectedCategories
        };
        if(data.query) payload['query'] = data.query
        if(data.age) payload['age'] = data.age
        if(data.revenue_min) payload['revenue_min'] = data.revenue_min
        if(data.revenue_max) payload['revenue_max'] = data.revenue_max
        if(data.profit_min) payload['profit_min'] = data.profit_min
        if(data.profit_max) payload['profit_max'] = data.profit_max
        if(data.range_min) payload['range_min'] = data.range_min
        if(data.range_max) payload['range_max'] = data.range_max
        handleSearchSubmit(payload);
    }

    const location = useLocation();
    useEffect(() => {
        const param = new URLSearchParams(location.search);
        const query = param.get('query');
        const age = param.get('age');

        const revenueMin = param.get('revenue_min');
        const revenueMax = param.get('revenue_max');

        const profitMin = param.get('profit_min');
        const profitMax = param.get('profit_max');

        const rangeMin = param.get('range_min');
        const rangeMax = param.get('range_max');

        reset({
            query,
            age,
            revenue_min: revenueMin,
            revenue_max: revenueMax,
            profit_min: profitMin,
            profit_max: profitMax,
            range_min: rangeMin,
            range_max: rangeMax
        })
    }, [location, reset])
  
    return(
      
        <div>    
            <form onSubmit={handleSubmit(onSubmit)} >
                
                <div className="form-group my-2">
                    <input type="text" {...register('query')}  className="form-control" placeholder="Find exactly what you need..." />
                </div>

                <div className="form-group my-2">
                    <input type="text" {...register('age')}  className="form-control" placeholder="Age" />
                </div>

                <div className="form-group my-2">
                    <label>Anual revenue</label>
                    <div className="input-group">
                    <input type="text" {...register('revenue_min')}  className="form-control" placeholder="Min revenue" />
                    <input type="text" {...register('revenue_max')}  className="form-control" placeholder="Max revenue" />
                    </div>
                </div>

                <div className="form-group my-2">
                    <label>Anual Profit</label>
                    <div className="input-group">
                    <input type="text" {...register('profit_min')}  className="form-control" placeholder="Min Profit" />
                    <input type="text" {...register('profit_max')}  className="form-control" placeholder="Max Profit" />
                    </div>
                </div>

                <div className="form-group my-2">
                    <label>Deal Range</label>
                    <div className="input-group">
                    <input type="text" {...register('range_min')}  className="form-control" placeholder="Min Range" />
                    <input type="text" {...register('range_max')}  className="form-control" placeholder="Max Range" />
                    </div>
                </div>

                <div className="form-group my-2 d-grid gap-2">
                    <Button variant="primary" size="sm"  type="submit" >Filter</Button>
                </div>

                <Accordion className="custom-accordion" defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Business Category</Accordion.Header>
                        <Accordion.Body>
                            {categories && categories.map(category => (
                                <div  key={category.id}>
                                    <input checked={selectedCategories.includes(category.id)} onChange={() => handleCheckboxChange(category.id)} type="checkbox" id={`cat-${category.id}`} /> <label htmlFor={`cat-${category.id}`}>{category.name}</label>
                                </div>
                                
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Business Industry</Accordion.Header>
                        <Accordion.Body>
                            {industries && industries.map(industry => (
                                <div  key={industry.id}>
                                    <input checked={selectedIndustries.includes(industry.id)} onChange={() => handleIndustriesChange(industry.id)} type="checkbox" id={`industry-${industry.id}`} /> <label htmlFor={`industry-${industry.id}`}>{industry.name}</label>
                                </div>
                                
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Business Model</Accordion.Header>
                        <Accordion.Body>
                            {models && models.map(model => (
                                <div  key={model.id}>
                                    <input checked={selectedModels.includes(model.id)} onChange={() => handleModelChange(model.id)} type="checkbox" id={`model-${model.id}`} /> <label htmlFor={`model-${model.id}`}>{model.name}</label>
                                </div>
                                
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                
            </form>
        </div>
    )
  }
  