import { useEffect, useState } from "react";
import { Accordion, Badge, Button, Card } from "react-bootstrap";
import moment from"moment";

import { usePageTitle } from "../../hooks/usePageTitle";

import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";
import { Spinner as Spin } from 'react-bootstrap'
import { ConfirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { FaArrowsRotate, FaEnvelope } from "react-icons/fa6";
import { FaEnvelopeOpen, FaSpinner } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";

export const ConversationPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Conversations');
    const [conversations, setConversations] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [reloading, setReloading] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();
    const [info, setInfo] = useState(user?.user)

    useEffect(() => {
        
        ListingService.conversations()
        .then((res) => {
            setConversations(res.data);
            setSpinner(false);
        })
        .catch(e => console.error('Error fetching conversations', e))

    }, []);

    const handleConversationClick = (conversation) => {
        if (info.id === conversation.listing_owner_id) {
            navigate(`/user/conversation/${conversation.id}/${conversation.initiater_id}/${conversation.listing_id}`);
        } else {
            navigate(`/user/conversation/${conversation.id}/${conversation.listing_owner_id}/${conversation.listing_id}`);
        }
        
    };

    const handleReload = () => {
        setReloading(true);
        ListingService.conversations()
        .then((res) => {
            setConversations(res.data);
            setReloading(false);
        })
        .catch(e => console.error('Error fetching conversations', e))
    }


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                <div className="d-flex justify-content-between">
                    <>Conversations</>
                    <Button variant="primary" size="md" onClick={()=>handleReload()}>
                        {reloading ? (<Spin size="sm" />) : <FaArrowsRotate /> } 
                    </Button>
                </div>
                
            </Card.Header>
            <Card.Body>

            <ul className="list-group">
                {conversations.map((conversation) => (
                    <li
                        key={conversation.id}
                        className="list-group-item d-flex justify-content-between align-items-center"
                        onClick={() => handleConversationClick(conversation)}
                        style={{ cursor: 'pointer' }}
                    >
                        <div>
                            <strong>{conversation.listing.website_business_name}</strong>
                            <p className="mb-0 text-muted">
                                {conversation.unread_messages_count ?

                                    (<FaEnvelope className="mx-1 text-primary"  />)
                                    :
                                    (<FaEnvelopeOpen  className="mx-1" />)
                                
                                }
                                    Latest Message: {conversation.messages[0]?.message || 'No messages yet'}
                            </p>
                        </div>
                        <span className="badge bg-primary rounded-pill">View</span>
                    </li>
                ))}
            </ul>
            </Card.Body>
            <ConfirmDialog />
            {spinner && <Spinner />}
        </Card>
    );
}