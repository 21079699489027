
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";
import whos from '../../assets/data/who_am_i.json'

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const AcquireDetailPage = () => {

	// step 1

	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				detail: user.data.detail
			})
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/how-many-business/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={36} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-5 text-center">
							<label className="w-100 h3">In as much detail as possible, what are you looking to acquire?</label>

							<p className="text-muted my-3">Online businesses come in many forms. Provide the details below, and we'll use them to match you with relevant opportunities.</p>
							
						</div>

						<div className="form-group my-4">
							<textarea rows={4} className="form-control" name="detail" placeholder="e.g. We are a private company with interest in acquiring a profitable SaaS business with low churn and defensible technology in one of either the heath care sector or the medtech sector." {...register('detail')}></textarea>
							
						</div>

						

						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/business-industry/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
