import { Outlet } from "react-router-dom";
import { HomeHeader } from './HomeHeader';
import { FooterSection } from './FooterSection';

export const HomeLayout = () => {
  return (
    <>
      <HomeHeader />
      <Outlet />
      <FooterSection />
    </>

  );
};