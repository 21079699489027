import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";


import { usePageTitle } from "../../hooks/usePageTitle";


import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import { Card, Form, Button, Row, Col } from "react-bootstrap";

import { WebsiteLinks } from "./WebsiteLinks";
import { useToast } from "../../hooks/ToastContext";


export const WebsiteSocial = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const showToast = useToast();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(8);
    const [title, setTitle] = useState('Social Following');
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();


    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data.step = step;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/images/${id}`);
        })
        .catch(e =>{
            setSpinner(false);
            showToast({ severity: 'error',  detail: e.response?.data?.message });
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name + ' - Social Following');
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

    }, []);

    usePageTitle(title);

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step}  current={8} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Create Website Listing 
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>

                            <Row>
                                <Col lg={3}>
                                    <Form.Label >Facebook likes</Form.Label>
                                    <Form.Control type="text" size="lg" 
                                        {...register('facebook', {
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/,
                                                message: 'Enter a valid number'
                                            }
                                        })}
                                    />
                                    {(errors.facebook && errors.facebook.type==='required') && <ErrorLabel >This field is required</ErrorLabel>}
                                    {(errors.facebook?.message) && <ErrorLabel >{errors.facebook?.message}</ErrorLabel>}
                                </Col>

                                <Col lg={3}>
                                    <Form.Label >Twitter followers</Form.Label>
                                    <Form.Control type="text" size="lg"
                                        {...register('twitter', {
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/,
                                                message: 'Enter a valid number'
                                            }
                                        })}
                                    />
                                    {(errors.twitter && errors.twitter.type==='required') && <ErrorLabel >This field is required</ErrorLabel>}
                                    {(errors.twitter?.message) && <ErrorLabel >{errors.twitter?.message}</ErrorLabel>}
                                </Col>

                                <Col lg={3}>
                                    <Form.Label >Instagram followers</Form.Label>
                                    <Form.Control type="text" size="lg" 
                                        {...register('instagram', {
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/,
                                                message: 'Enter a valid number'
                                            }
                                        })}
                                    />
                                    {(errors.instagram && errors.instagram.type==='required') && <ErrorLabel >This field is required</ErrorLabel>}
                                    {(errors.instagram?.message) && <ErrorLabel >{errors.instagram?.message}</ErrorLabel>}
                                </Col>

                                <Col lg={3}>
                                    <Form.Label >Linkedin followers</Form.Label>
                                    <Form.Control type="text" size="lg"
                                        {...register('linkedin', {
                                            pattern: {
                                                value: /^(0|[1-9][0-9]*)$/,
                                                message: 'Enter a valid number'
                                            }
                                        })}
                                    />
                                    {(errors.linkedin && errors.linkedin.type==='required') && <ErrorLabel >This field is required</ErrorLabel>}
                                    {(errors.linkedin?.message) && <ErrorLabel >{errors.linkedin?.message}</ErrorLabel>}
                                </Col>
                            </Row>

                            


                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/description/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}