import { useEffect, useState } from "react";

import ListingService from "../services/ListingService";
import { ListingItem } from "./ListingItem";
import { Loader } from "./Loader";
import { PaginationComponent } from "./PaginationComponent";
import { Spinner } from "./Spinner";

export const ActiveListings = () => {
    const [listings, setListings] = useState(null);
    const [spinner, setSpinner] = useState(false);
    useEffect(()=> {
        ListingService.activeListings('')
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    const handlePageChange = (page) => {
        setSpinner(true);
        ListingService.activeListings(`?page=${page}`)
        .then(res => {
            setSpinner(false);
            setListings(res.data)
        })
        .catch(e => {
            setSpinner(false);
            console.log(e);
        })
    }

    return (
        <section className="team section">
            <div className="container">
                { !listings && <Loader />}
                {spinner && <Spinner />}
                { listings && listings.data.map((listing) => {
                    return (<ListingItem key={listing.id} item={listing}  />)
                })}

                { listings && 
                    <PaginationComponent 
                        currentPage={listings.current_page}
                        pages={listings.total}
                        onPageChange={handlePageChange}
                        itemsPerPge={10} />
                }

            </div>
        </section>
    );
}