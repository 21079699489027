import { Button, Card, Col, Row } from "react-bootstrap"
import { PageHeaderComponent } from "../../components/PageHeaderComponent"
import { SocialLinks } from "../../components/SocialLinks"
import { useForm } from "react-hook-form";
import { FaEnvelopeOpenText } from "react-icons/fa";

export const ContactPage = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = () => {

    }

    return (
        <>
            <PageHeaderComponent title={'Contact Us'} summary={'How can we help you?'} />
            <section className="container">
                <Row>
                    <Col lg={6} className="mx-auto">
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <Card className="shadow-lg border-0">
                            <Card.Body>
                                <div className="m-3 text-center">
                                    <a href="mailto:hello@mergermagic.com"><FaEnvelopeOpenText className="text-primary" /></a> <SocialLinks />
                                </div>
                                <Row className="my-1">
                                    <Col lg={6}>
                                        <div className="form-group">
                                            
                                            <input type="text" 
                                                    className="form-control" 
                                                    placeholder="Full Name"
                                                    {...register('name', {
                                                        required: true,
                                                        minLength: {
                                                            value: 3,
                                                            message: 'At least 3 characters'
                                                        }
                                                    })} 
                                            />

                                            {(errors.name && errors.name.type==='required') && <div className='text-danger font-sm'>Enter your name</div>}
                                            {(errors.name?.message) && <div className='text-danger font-sm'>{errors.name?.message}</div>}
                                            
                                        </div>
                                    </Col>
                                    <Col lg={6} >
                                        <div className="form-group ">
                                            
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="floatingInput" 
                                                    placeholder="name@example.com"
                                                    {...register('email', {
                                                        required: true,
                                                        pattern: {
                                                            value: /[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9])?/,
                                                            message: 'Enter a valid email address'
                                                        }
                                                    })} 
                                            />

                                            {(errors.email && errors.email.type==='required') && <div className='text-danger font-sm'>Enter your email address</div>}
                                            {(errors.email?.message) && <div className='text-danger font-sm'>{errors.email?.message}</div>}
                                            
                                        </div>
                                    
                                    </Col>
                                </Row>

                                <Row className="my-3">
                                    <Col lg={12}>

                                        <div className="form-group">
                                            <input type="text" 
                                                    className="form-control" 
                                                    placeholder="Subject"
                                                    {...register('subject', {
                                                        required: true,
                                                        minLength: {
                                                            value: 6,
                                                            message: 'At least 6 characters'
                                                        }
                                                    })} 
                                            />

                                            {(errors.name && errors.name.type==='required') && <div className='text-danger font-sm'>Enter your subject</div>}
                                            {(errors.name?.message) && <div className='text-danger font-sm'>{errors.name?.message}</div>}
                                            
                                        </div>
                                    
                                    </Col>
                                </Row>

                                <Row className="my-1">
                                    <Col lg={12} >
                                        <div className="form-group">
                                            <textarea type="text" 
                                                    className="form-control" 
                                                    placeholder="Ask Away - We Have Got Answers!"
                                                    {...register('message', {
                                                        required: true,
                                                        minLength: {
                                                            value: 6,
                                                            message: 'At least 6 characters'
                                                        }
                                                    })} 
                                            ></textarea>

                                            {(errors.name && errors.name.type==='required') && <div className='text-danger font-sm'>Type your message</div>}
                                            {(errors.name?.message) && <div className='text-danger font-sm'>{errors.name?.message}</div>}
                                            
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="my-3">
                                    <Col lg={12} className="text-right">
                                        <Button type="submit" variant="success" >Send</Button>
                                    </Col>
                                    
                                </Row>

                            </Card.Body>

                        </Card>
                        </form>
                        
                        

                    </Col>
                </Row>
            </section>
        </>
        
    )
}