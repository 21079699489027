import { useEffect, useState } from "react";
import { Card, Modal, Table } from "react-bootstrap";

import { ConfirmDialog } from 'primereact/confirmdialog';


import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

import { PaginationComponent, Spinner } from "../../components";

import UserService from "../../services/UserService";
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6";



export const AdminUserListing = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Users');
    const [users, setUsers] = useState(null);

    const [user, setUser] = useState(null);

    const [spinner, setSpinner] = useState(true);
    const showToast = useToast();
    
    useEffect(()=> {
        UserService.users('')
        .then(res => {
            setUsers(res.data)
            setSpinner(false);
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }, [showToast]);


    const handlePageChange = (page) => {
        setSpinner(true);
        UserService.users(`?page=${page}`)
        .then(res => {
            setSpinner(false);
            setUsers(res.data)
        })
        .catch(e => {
            setSpinner(false);
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const handleUserDetail = (data) => {
        setShow(true);
        setUser(data);
    }

    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0">
                Users
            </Card.Header>
            <Card.Body>
                
                <Table>
                    <thead>
                        <tr>
                            <td>ID</td>
                            <td>Name</td>
                            <td>Email</td>
                            <td>Admin</td>
                            <td>Type</td>
                            <td>Imported</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.data.map(item => {
                            return(
                                <tr key={item.id} className="cursor-pointer" onClick={()=> handleUserDetail(item)}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.email_verified_at ? <FaCircleCheck className="text-success" /> : <FaCircleXmark className="text-muted" />} {item.email}</td>
                                    <td>{item.is_admin ? <FaCircleCheck className="text-success" /> : <FaCircleXmark className="text-danger" />}</td>
                                    <td className="capitalize">{item.type}</td>
                                    <td>{item.is_imported ? <FaCircleCheck className="text-success" /> : <FaCircleXmark className="text-danger" />}</td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td colSpan={5} >
                                {users &&
                                    <PaginationComponent 
                                        currentPage={users.current_page}
                                        pages={users.total}
                                        onPageChange={handlePageChange}
                                        itemsPerPge={100}
                                    />
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
            <ConfirmDialog />

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>User Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        
                    </div>
                </Modal.Body>
            </Modal>
        </Card>
    );
}