import { Alert, Button } from "react-bootstrap"
import AuthService from "../services/AuthService";



import { useToast } from "../hooks/ToastContext";

export const VerifyEmailNotice = () => {

    const showToast = useToast();

    const handleResend = () => {
        AuthService.resend()
        .then(()=>{
            showToast({ severity: 'success',  detail: 'An email is sent to you, follow the instruction to verify your email' })
        }).catch(e => {
            
        })
    }

    return (
        <Alert variant="warning" className="p-1 px-2">
            Your email is not verified. Please check your inbox to complete the verification.
            If you haven't received an email, click <Button  size="sm" variant="light" onClick={()=>handleResend()} >here</Button> to receive the verification email.
        </Alert>
    )
}