import { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Pagination, Table } from "react-bootstrap";
import moment from"moment";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

import ListingService from "../../services/ListingService";
import { PaginationComponent, Spinner } from "../../components";
import { FaCircleCheck, FaCircleXmark, FaPaperPlane } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";



export const AdminListing = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Listings');
    const [listings, setListings] = useState(null);

    const [spinner, setSpinner] = useState(true);
    const showToast = useToast();
    
    useEffect(()=> {
        ListingService.imported('')
        .then(res => {
            setListings(res.data)
            setSpinner(false);
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }, [showToast]);



    const handleInvitation = (item, resend = false) => {
        confirmDialog({
            message: `Are you sure you want to ${resend ? 'resend' : 'send'} invitation to this user ?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => {
                setSpinner(true);
                ListingService.sendInvitation(item.user.id, item.id)
                .then(res => {
                    setSpinner(false);
                    const updatedItem = res.data.listing;

                    setListings(prevItems => {
                        if (Array.isArray(prevItems.data)) {
                            return {
                                ...prevItems,
                                data: prevItems.data.map(item =>
                                    item.id === updatedItem.id ? updatedItem : item
                                )
                            };
                        } else {
                            console.error('prevItems.data is not an array', prevItems);
                            return prevItems;
                        }
                    });


                    showToast({ severity: 'success',  detail: `User is successfully invited.` })
                })
                .catch(e => {
                    setSpinner(false);
                    showToast({ severity: 'error',  detail: e.response?.data?.message })
                })
            },
        });
    }

    const handlePageChange = (page) => {
        setSpinner(true);
        ListingService.imported(`?page=${page}`)
        .then(res => {
            setSpinner(false);
            setListings(res.data)
        })
        .catch(e => {
            setSpinner(false);
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }

    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0">
                Imported Listings
                <Link className="btn btn-sm btn-success float-right" to="/admin/upload-listings">Upload Listing</Link>
            </Card.Header>
            <Card.Body>
                
                <Table>
                    <tbody>
                        {listings && listings.data.map(item => {
                            return(
                                <tr key={item.id}>
                                    <td>
                                        <strong>{item.website_business_name} </strong> 
                                        {item.url && <a href={item.url} className="text-primary" target="_blank" rel="noreferrer"><FaExternalLinkAlt /></a>}
                                        <div>
                                            <Badge className="m-1">{item.type}</Badge>
                                            {item.status === 1 && <Badge className="m-1">Active</Badge>}
                                            <div>Expiring { moment(item.expire_date, "YYYYMMDDh").fromNow() }</div>
                                        </div>
                                        
                                        
                                    </td>
                                    <td>
                                        {item.user && (<>
                                            <strong>User: {item.user.name}</strong> 
                                            <div>Email: {item.user.email}</div>
                                        </>)}
                                    </td>

                                    <td>
                                        {item.user && (<div>Imported: {item.user.is_imported ? <FaCircleCheck className="text-success" /> : <FaCircleXmark className="text-danger" />}</div>)}
                                    </td>
                                    <td>
                                        {item.user && (<>
                                            Invited: {item.user.is_invited 
                                                ? (<FaCircleCheck className="text-success" />) 
                                                : (<FaCircleXmark className="text-danger" />)}

                                            <div>Opened: {item.user.status ? <FaCircleCheck className="text-success" /> : <FaCircleXmark className="text-danger" />}</div>
                                        </>)}
                                        
                                    </td>
                                    <td>
                                        {item.user.is_invited 
                                            ? (<Button variant="outline-primary" className="mx-2" size="sm" onClick={() => handleInvitation(item, true)}><FaPaperPlane /> Resend</Button>) 
                                            : (<Button variant="outline-primary" className="mx-2" size="sm" onClick={() => handleInvitation(item, false)}><FaPaperPlane /> Send</Button>)}
                                    </td>
                                     
                                </tr>
                            )
                        })}

                        <tr>
                            <td colSpan={5} >
                                {listings &&
                                    <PaginationComponent 
                                        currentPage={listings.current_page}
                                        pages={listings.total}
                                        onPageChange={handlePageChange}
                                    />
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
            <ConfirmDialog />
        </Card>
    );
}