import { Col, Row } from "react-bootstrap";
import Step1 from '../assets/img/step-1.png';
import Step2 from '../assets/img/step-2.png';
import Step3 from '../assets/img/step-3.png';


import Security from '../assets/img/security.png';
import Support from '../assets/img/support.png';
import Profit from '../assets/img/profit.png';

import Commission from '../assets/img/commisions.png';
import Bid from '../assets/img/bid.png';
import Visibility from '../assets/img/visibility.png';

export const TrendingSale = () => (
    <div className="container">
        <Row>
            <Col className="text-center">
                <h1>Trending Sales & Recent Closings  </h1>
                <p className="text-secondary">Explore Our Hottest SaaS and Classified Listings! </p>
            </Col>
        </Row>
        <Row>
            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Security} />
                    </span>
                    
                    <h4>Ironclad Deals</h4>
                    <p className="text-secondary">Elevate Your Business with Unbeatable Security for Profitable Deals!</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Support} />
                    </span>

                    <h4>Always-On Assistance</h4>
                    <p className="text-secondary">Never Face Challenges Alone - Our Support Team's Got You Covered, 24/7!</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Profit} />
                    </span>
                    
                    <h4>Maximizing Returns</h4>
                    <p className="text-secondary">Unlock Sky-High Profits - Your Success Story Starts Here!</p>
                </div>
            </Col>
        </Row>

        <Row>
            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Commission} />
                    </span>
                    
                    <h4>Wallet-Friendly Fees</h4>
                    <p className="text-secondary">More Money in Your Pocket - We Offer the Lowest Commissions!</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Bid} />
                    </span>

                    <h4>Bids That Win</h4>
                    <p className="text-secondary">Outbid the Competition - Dominate with Our Advanced Bidding System!</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-3">
                    <span className="process-img">
                        <img className="img-fluid" src={Visibility} />
                    </span>
                    
                    <h4>Stand Out & Shine</h4>
                    <p className="text-secondary">Shine Bright in the Market - Achieve Unmatched Visibility!</p>
                </div>
            </Col>
        </Row>
    </div>
)