import { FaMapMarkerAlt, FaCalendarAlt, FaDollarSign, FaEye } from "react-icons/fa";
import moment from"moment";
import { CURRENCY_STYLE } from '../constants'
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListingAttribute } from "./ListingAttribute";
import { ListingMonetization } from "./ListingMonetization";
import { ListingNetProfit } from "./ListingNetProfit";

import placeholder from '../assets/img/placeholder.jpg';

export const ListingItem = ({item}) => {
    return (

        <Link to={`/${item.slug ? item.slug : item.id}/${item.id}`}>
            <div className="team-member align-items-start my-5">
                <div className="pic">
                    {item.cover 
                    ? (<img src={item.cover} className="img-fluid" alt="" />) 
                    : (<img src={placeholder} className="img-fluid" alt="" />)}
                    
                </div>
                <div className="member-info text-dark">
                    <Row>
                        <Col lg={6}>
                            <h4>{item.type} | {item.website_business_name}</h4>
                        </Col>

                        <Col lg={6} className="d-flex justify-content-between text-secondary">
                            
                        </Col>
                    </Row>
                    
                    
                    <span></span>


                    <Row className="my-2">
                        <Col lg={9}>
                        <Row className="my-2">
                            <Col>
                                <p className="text-dark">{item.tagline}</p>
                            </Col>
                        </Row>

                        <Row className="my-2">
                            <Col className="d-flex justify-content-start text-secondary">
                                <div className="px-2 border-right">
                                    Type
                                    <ListingAttribute data={item.type} />
                                </div>

                                {item.category && (
                                    <div className="px-2 border-right">
                                        Industry
                                        <ListingAttribute data={item.category.name} />
                                    </div>
                                ) }

                                {item.methods && (
                                    <div className="px-2 border-right">
                                        Monetization
                                    <ListingMonetization methods={item.methods} />
                                    </div>
                                ) }

                                <div className="px-2 border-right">
                                    Age
                                    <ListingAttribute data={`${item.age} years`} />
                                </div>

                                <div className="px-2 border-right">
                                    Net Profit
                                    <ListingNetProfit anual={item.annual_profit} />
                                </div>
                                

                            </Col>
                        </Row>
                                    
                        
                        </Col>
                        <Col lg={3}>
                            <div className="my-2"><FaMapMarkerAlt className="text-secondary" /> {item.business_registered_country}</div>
                            <div className="my-2"><FaEye className="text-secondary" /> Views <strong>{item.views}</strong></div>
                            <div className="my-2"><FaCalendarAlt className="text-secondary" /> Expiring { moment(item.expire_date, "YYYYMMDDh").fromNow() }</div>
                            <div className="my-2"><FaDollarSign className="text-secondary" />Asking price <i className="text-primary strong regular">{ `${Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(item.buy_now_price)}` }</i> </div>
                        </Col>
                    </Row>

                    

                </div>
            </div>
        </Link>

        
    );
}