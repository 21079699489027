import { Button, Card, CardBody } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight, FaCheck } from "react-icons/fa";





export const UserTipsPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
    const navigate = useNavigate();
    const {id} = useParams();

    return (
        <div className=" w-50 m-auto">
            <Card className="border-0">
                <CardBody>
                    
                    <h2 className="my-3 text-center text-dark">Here are our suggestions for maximizing your experience:</h2>
                    <div className="alert alert-success"><FaCheck className="mx-3" />The more accurate your answers, the better your matches will be.</div>
                    <div className="alert alert-success"><FaCheck className="mx-3" />Share valuable information to help sellers evaluate your suitability for their business. </div>
                    <div className="alert alert-success"><FaCheck className="mx-3" />Once completed, you'll gain access to exclusive off-market opportunities and unique deal insights.</div>
                    <div className="d-flex justify-content-between my-5">
                        <Button onClick={()=>{navigate('/profile/tips/' + id)}} variant="outline-secondry" size="lg" ><FaAngleLeft /> Back</Button>

                        <Button onClick={()=>{navigate('/profile/online-business/' + id)}} variant="primary" size="lg" >Continue <FaAngleRight /></Button>
                    </div>
                </CardBody>
            </Card>
            
            
        </div>
    );
}