import { PageHeaderComponent } from "../../components/PageHeaderComponent"

export const PrivacyPage = () => {
    return (
        <>
            <PageHeaderComponent title={'Privacy Policy'} summary={'Last updated on October 31 2023'} />
            <section className="container">
            
                <p >This policy outlines the privacy practices of MergerMagic.com ("Merger Magic," collectively "we," "us," and "our") concerning the Website and other Services provided by Merger Magic. It's important to note that this policy does not extend to the practices of third parties or any of our users who might collect or receive data in connection with their use of the Services.</p>
                <p >Our commitment to the Data Protection Act 2018, the European General Data Protection Regulation ("GDPR"), and the California Consumer Privacy Act ("CCPA") is highlighted through this policy, to the extent that we offer Services or monitor the activity of individuals in the European Union and the USA.</p>
                <p >We process your personal information to effectively manage our business and offer the Services. By accepting our Terms of Service and, in some jurisdictions, by acknowledging this policy, you affirm that you have thoroughly read and comprehended this policy, including how and why we utilize your information. If you prefer that we do not collect or process your personal information as described in this policy, refraining from using the Services is advised.</p>
                <p >By utilizing the Services, you recognize that Merger Magic will utilize your information in the United States, Europe, Australia, and any other countries where Merger Magic operates. Please be aware that the privacy laws and standards in some countries, including the rights of authorities to access your personal information, may differ from those applicable in your country of residence. Our transfer of personal information is solely to countries permitted by law, as explained further below.</p>
                <h3 >1. How we collect personal information.</h3>
                <p >In the process of providing Services to you, we directly collect or receive your personal information from you, for instance, during registration; indirectly from you based on your activity and interaction with our Services, or from the device or browser you use to access the Services; from our vendors and suppliers that assist in providing services you may interact with, and from our third-party advertising and marketing partners. You often have the choice of what information to provide, but at times we may require specific information to deliver Services. Merger Magic uses the personal information it receives and collects in accordance with the purposes outlined in this policy.</p>
                <p >For the use of our Services, a valid email address must be entered. Upon registration, you must provide a name linked to your account, which you can modify through your account settings. This information is necessary to enable us to offer you the Services. Additional information, such as billing and payment details (including billing contact name, address, telephone number, credit card information), a telephone number, and/or a physical postal address, may be required to provide a particular service. As a Merger Magic seller, should you choose to use our payment service ("Merger Magic Payments"), we require your full name, identification ID or tax ID, date of birth, bank account information, photograph, credit card information, and/or other proof of identification to verify your identity, offer this service, and comply with relevant laws.</p>
                <p >For both buyers and sellers, Merger Magic mandates the provision of this information to facilitate the provision of Services (including verifying account ownership, mitigating fraud and abuse, complying with regulatory obligations, or enabling transactions between buyers and sellers). Credit card information may be stored for billing and payment purposes. Merger Magic buyers might be requested to pre-authenticate their payment method before placing a bid.</p>
                <p >You might supply your photo, name, and other personal information (like birthday, gender, location) in connection with your account and activities. This information is editable or removable through your account settings. The name and photo linked to your account (modifiable in your account settings) are publicly displayed and linked to your Merger Magic activity. Other registered users may view your account's creation date, ratings, reviews, information pertaining to assets you've sold or bought, or comments you've left on listings.</p>
                <p >For sellers, we may gather and employ information about you to showcase you in marketing and advertising materials and campaigns, and to highlight your listings, as stipulated in our Terms of Service.</p>
                <p >Merger Magic automatically obtains and records information from your browser or mobile device during your visit to the Website or while using certain Service features, such as your IP address or unique device identifier, cookies, and data about your page visits and interactions. This information aids in the operation and provision of the Services and is stored in log files. We may combine this browser or mobile device information with other data collected by us or our advertising and marketing partners, including cross-device data. This data is utilized for fraud prevention, maintaining Service security, analyzing Service functionality for registered users and visitors, delivering advertising across devices, and offering a more personalized experience for registered users and visitors.</p>
                <p >Merger Magic obtains information about you from our advertising and marketing partners, including attribution information via cookies and UTM tags in URLs to trace the source of visits to Merger Magic, responses to marketing emails and ads, offer responses, and audience data from partners with whom you've consented to share such information.</p>
                <p >We collect data about your Service usage for advertising, analytics, content delivery, and Service protection. This can include your IP address and browser information (including referrers). When selling on Merger Magic, you may choose to disclose your location. We obtain location information from your profile or your IP address. Non-precise location services vital for the Website's functionality based on your IP address or submitted address are critical. Your geolocation details are shared with third parties solely to provide you with the Services.</p>
                <p >Certain users might opt to link to Merger Magic or register using a third-party application, such as Google. Connecting your Merger Magic account to third-party applications or services is voluntary. If you choose to link your account to a third-party app, Merger Magic can access information from that app to offer the Services. Specific third-party applications may enable you to opt out of receiving messages from us or forward those messages to another email address. Failure to receive critical account-related messages might lead to the suspension or termination of your account.</p>
                <p >Merger Magic receives or obtains information (like an email address or IP address) about an individual not yet a registered Merger Magic user (a "non-registered user") in connection with certain Merger Magic features, such as when a non-registered user subscribes to a Merger Magic newsletter or a registered user invites a non-registered user to visit the Website. Non-registered user information is used only for the disclosed purposes upon submission to Merger Magic, for the necessary functioning of Merger Magic's Services, or when Merger Magic has a legitimate interest, or to facilitate action authorized by a user.</p>
                <h3 >2. Communication from Merger Magic</h3>
                <p >Periodically, Merger Magic may need to contact you. These communications are primarily transmitted via email or the discussion forum for various reasons, including marketing, transactions, and service updates. You have the option to unsubscribe from receiving marketing communications via email or by using the unsubscribe link in any marketing email you receive.</p>
                <p >To ensure the proper delivery of notifications, we require specific device information, such as the operating system and user identification details. Every account must maintain a valid email address on file to receive messages. Merger Magic may also reach out to you by phone to provide support or for transaction-related purposes upon your request. Moreover, subject to your consent where necessary by applicable law, Merger Magic may send you an SMS (or similar) message, or contact you by phone, for customer support, research or feedback, or to provide information on listings and features that may interest you. You have the option to update your contact preferences in your account settings.</p>
                <p >Certain messages from Merger Magic are service-related and essential for all users. You acknowledge and agree that Merger Magic may send you non-marketing emails, forum messages, or notifications related to transactions, your account, security, or listing alterations. Examples of service-related messages include email address confirmation/welcome email upon account registration, bid or offer notifications, service availability updates, key feature or function modifications, correspondence with buyers, and interactions with our customer support team.</p>
                <p >Upon registering for an account, subscribing to a newsletter, or providing your email address or phone number, you receive notification and agree (in certain jurisdictions and situations, with additional explicit consent) to receive marketing emails and messages from us. You retain the option to unsubscribe from marketing emails or messages at any time via the opt-out link included in marketing emails or messages. Registered users and subscribers can also manage the types of marketing emails or messages they receive from Merger Magic through their account settings. Please note that certain changes to your account settings may take a few days to take effect.</p>
                <h3 >3. Communicating with other users and commenting</h3>
                <p >Merger Magic as a marketplace and community offers several features enabling registered users to connect, communicate, and comment. Exercise sound judgment and discretion if you decide to post, comment, communicate, or share personal information with others via the Services. Please note that any personal information you choose to submit may be accessible, collected, or utilized by others, potentially leading to unsolicited messages. You bear the responsibility for any personal information you opt to post while using the Services.</p>
                <h3 >4. How we use, share and disclose information&nbsp;</h3>
                <p >Merger Magic refrains from disclosing your name, email address, or other personal information to third parties without your explicit consent, except as specified in this policy. You maintain control over your privacy settings through your account settings. Please be aware that certain changes to your privacy settings may take a few days to come into effect. We or our sellers might advertise our Services or the listings of our sellers through various mediums, relying on your consent for off-site promotion.</p>
                <h4 >Legal bases</h4>
                <p >When accessing or using the Services, we collect, use, share, and process your personal information in line with the purposes outlined in this policy. We rely on multiple legal bases to utilize your information in these capacities, including when:</p>
                <ul>
                <li  aria-level="1">
                <p  role="presentation">It's necessary to fulfill contractual obligations within our Terms of Service and to provide the Services to you;</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">You have given your consent to the processing, which can be revoked at any time;</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">It's necessary to comply with legal or regulatory obligations, court orders, or to pursue or defend any pending or asserted legal claims;</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">It's necessary for the purposes of our or a third party's legitimate interests, such as those of visitors, registered users, or partners (further detailed below);</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">You have expressly made the information public;</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">It's necessary in the public interest, such as for crime prevention; and</p>
                </li>
                <li  aria-level="1">
                <p  role="presentation">Occasionally it's necessary to protect your vital interests or those of others (in rare instances where sharing information may prevent loss of life or personal injury).</p>
                </li>
                </ul>
                <h4 >Our legitimate interests&nbsp;</h4>
                <p >We primarily rely on consent (which can be withdrawn at any time) (i) to send marketing messages, (ii) for third-party data sharing related to advertising, and, where applicable, (iii) for the use of location data for advertising purposes.</p>
                <p >We depend on consent for targeted online and offline marketing, including through tools like Facebook Audience Match and Google Customer Match. We or our sellers may advertise our Services or sellers' listings through various mediums, banking on your consent for off-site promotions. In this context, we may collaborate with advertising partners such as Facebook, Google, and other partners mentioned in our Cookies &amp; Tracking Policy. These partners maintain their privacy policies and consent mechanisms for their customers, alongside Merger Magic's controls. You can review the privacy policies and privacy choices for these partners, as well as the information you share with them, in our Cookies &amp; Tracking Policy.</p>
                <p >In instances where we process your information based on legitimate interests, we do so as follows:</p>
                <h4 >To provide and improve our Services&nbsp;</h4>
                <p >We utilize your information to offer our Services, aiming to enhance and customize them for users, including sharing your information for these purposes. This action is essential to pursue our legitimate interests in comprehending how our Services are being used, as well as exploring strategies for business growth and development. It's also crucial to pursue our legitimate interests in enhancing our Services, efficiency, and gaining insights into usage patterns. We hold a legitimate interest in tailoring your on-site experience to assist in asset discovery, including aiding sellers in effectively marketing and vending their assets on Merger Magic.</p>
                <h4 >For advertising and marketing</h4>
                <p >We rely on our legitimate interest to send you marketing messages (where permissible irrespective of consent) and for Merger Magic's advertising programs (including on-site advertising and marketing).</p>
                <h4 >For safety and security of Services&nbsp;</h4>
                <p >To uphold the security of our Services, we utilize your information for safety and security purposes, a measure undertaken in the pursuit of our legitimate interests and that of third parties. This includes reinforcing our community's defense against spam, harassment, intellectual property breaches, crime, and other security risks. Your information aids us in the provision and enhancement of Services, billing and payments, identification and authentication, and general research. For instance, information provided may be employed in resolving disputes with Merger Magic or other users.</p>
                <h4 >To facilitate the sales process&nbsp;</h4>
                <p >As part of the buying and selling process, Merger Magic facilitates the exchange of information between the involved users.</p>
                <p >We may leverage your information, including but not limited to browsing history, profile information, and site activity, in our proprietary and third-party algorithms to suggest or invite you to matched deals and buyers. When matched, relevant sellers will be presented with your profile information, excluding your phone number, street address, or email.</p>
                <p >This may require the sharing of your information with select third-party partners to enable us to deliver the Services. Such partners process your personal information in line with their own privacy policies. While subject to Merger Magic's privacy and security requirements, these partners are responsible for safeguarding the personal information under their control. By engaging in a sale or purchase on Merger Magic, you authorize us to share your information in this manner, a vital component of the Services we offer. We expect you to respect the privacy of the user whose information you've received. Pursuant to our Terms of Service, you possess a limited license to use that information exclusively for Merger Magic-related communications or transactions facilitated by Merger Magic. No license is granted to engage in unauthorized transactions or send unsolicited commercial messages in violation of applicable laws, including consent requirements of the recipient's jurisdiction.</p>
                <h4 >To customise your Merger Magic experience</h4>
                <p >We gather insights about your preferences from your browsing and purchasing activities on and off the Website, enabling us to suggest potential purchases. This personalization is a fundamental aspect of our Services, aiming to help you discover relevant items and recommended purchases, while also assisting sellers in effectively marketing and selling their listings on Merger Magic.</p>
                <h4 >For legal and security reasons&nbsp;</h4>
                <p >Under limited circumstances, Merger Magic may retain, preserve, or disclose your personal information to a third party. This includes responding to legitimate national security or law enforcement requirements, protecting and asserting our legal rights, or complying with legal obligations or court orders.</p>
                <p >If Merger Magic receives a verified request for user information, we may disclose personal information, such as a user's name, address, phone number, email address, company/trading name, and, where appropriate, bank account and transaction details.</p>
                <h4 >For our affiliates&nbsp;</h4>
                <p >Working closely with a diverse range of businesses, Merger Magic collaborates with them to perform and improve the Services. Affiliated businesses may sell items or services through the Website or Services or offer promotions with your consent.</p>
                <h4 >For our service providers&nbsp;</h4>
                <p >Merger Magic collaborates with third-party entities to operate, provide, and market the Services. These service providers have limited access to your information and are obligated not to disclose or use your information for other purposes.</p>
                <h4 >In the event of a restructure</h4>
                <p >In cases where asset transactions are necessary, user information is one of the business assets transferred. If such circumstances arise, we will notify you beforehand and provide an opportunity to opt out before your information becomes subject to a different privacy policy.</p>
                <p >Third-Party Plug-Ins</p>
                <p >Certain third-party plug-ins on the Website collect information about your usage. When you load a page on Merger Magic featuring a social plug-in from a third-party site, like a "Like" or "Send" button, content from that site is also loaded, potentially requesting cookies directly from your browser. These interactions are governed by the privacy policy of the respective third-party site. Moreover, select cookies and similar technologies used on the Website serve third parties for targeted online marketing and other purposes.</p>
                <p >These technologies allow a partner to identify your device during each use of the Services. It's important to note that when you engage with third-party sites or services, your use is subject to their terms and privacy policies. While Merger Magic selects and manages these third-party technologies, they remain under the purview of the respective third party's privacy policy. For more details, please refer to our Cookies &amp; Tracking Policy. We rely on your consent to deploy and access these cookies when not strictly necessary or required for another purpose, such as legitimate interest.</p>
                <h4 >Exclusion of Third-Party Practices</h4>
                <p >This policy does not extend to the practices of third parties, including other users selling assets, specific third-party service providers, or API users, that Merger Magic does not own or oversee. If you provide information to such third parties in connection with your use of the Services, different privacy practices may apply. Although these third parties are expected to adhere to Merger Magic's privacy and security standards, their privacy or security policies are beyond Merger Magic's control. As per the applicable laws, Merger Magic is not accountable for the privacy or security practices of these sellers, API users, or other internet websites, even those linked to or from the Services. We recommend reviewing their privacy policies and seeking clarification from third parties before disclosing your personal information. Under European law, these sellers, third-party providers, and API users act as independent data controllers, thereby responsible for establishing and adhering to their policies concerning any personal information collected through the Services.</p>
                <h3 >5. Data Movement</h3>
                <p >The laws governing personal information differ between the United States, European Economic Area ("EEA") Member States, and other countries. When your data is transferred from your home country to another, the legal protections may vary. Understanding this, Merger Magic facilitates global Services, necessitating the transfer of your data to various jurisdictions, including Australia. To ensure the safety of your information, we often rely on the European Commission's standard contractual clauses (Model Clauses) for data transferred outside of Europe. At present, Merger Magic does not engage in Privacy Shield.</p>
                <h3 >6. Personal Information Security: Our Commitment to Protection</h3>
                <p >We adhere to industry-standard security protocols to safeguard your data during transmission and storage. Certain sensitive information, such as credit card numbers, is encrypted using TLS (Transport Layer Security). Although we strive to ensure your data's security, no method of online transmission or electronic storage is entirely foolproof. For your account's protection, it is crucial to maintain a strong password, log out after each use, and keep your password and device secure. If you have any security concerns, please reach out to us at support@MergerMagic.com.</p>
                <h3>7. Personal Information Retention: Data Storage Duration</h3>
                <p >Merger Magic retains your information only as long as necessary to fulfill the purposes outlined in this policy or for the duration of your active account. If you choose to close your account, we will retain your data as required to meet legal obligations, settle disputes, enforce agreements, or for purposes mentioned in this policy. Similarly, Merger Magic sellers may be obligated to retain your information for their legal compliance. Please note that closing your account may not immediately free up your email address for reuse. We also retain log files for internal analysis, site safety and security, and functional improvements, as needed or required by law.</p>
                <h3 >8. Your rights&nbsp;</h3>
                <p >Under global privacy laws, including the European General Data Protection Regulation (GDPR), you have rights concerning your personal information. At Merger Magic, we offer you the ability to access, edit, or delete specific information and manage your communication preferences. You can update your account details and control certain publicly visible information. If required, you can permanently close your account and erase associated data. Your location may grant you additional rights, some of which apply in specific situations.</p>
                <h4 >Your Access and Control Rights</h4>
                <p ><strong>Access to Your Information:</strong> You can view your data and request a copy in an easily accessible format.</p>
                <p ><strong>Rectification:</strong> You have the right to correct any inaccuracies in your data through your account settings.</p>
                <p ><strong>Limiting Processing:</strong> In certain cases, you can restrict how we use your personal information.</p>
                <p ><strong>Deletion:</strong> You can request the deletion of your data, except where retention is required by law or for safety and security purposes.</p>
                <p ><strong>Objection:</strong> If we process your data based on legitimate interests, you can object, and we will cease processing unless legally obligated to continue.</p>
                <p ><strong>Withdrawal of Consent:</strong> You can withdraw consent for data processing by using specific features or settings provided.</p>
                <p >To manage your information or exercise any of these rights, use your Merger Magic account settings or contact us through our help center, chat support, or support email. Please note that limiting or deleting your data may affect certain features. However, we do not discriminate based on the exercise of these rights.</p>
                <p >For identity verification, we may request government-issued identification. You can also designate an authorized agent to act on your behalf, subject to verification. Control your communication preferences through your account settings, including opting out of non-essential messages. Detailed information about cookies and how to manage them is available in our Cookies Policy. For further assistance, please reach out to Merger Magic through the provided channels, and we will respond promptly.</p>
                <h3 >9. Seller Responsibilities</h3>
                <p >As a seller using our Services, you may handle certain personal information and act as an independent data controller. Your responsibilities are defined in our Terms of Service.</p>
                <h3 >10. Policy Amendments</h3>
                <p >We reserve the right to revise or update this policy periodically. In the event of material changes, we will notify you through one or more of the following methods: posting the changes on the Services, sending you an email, or delivering a message. We recommend checking back regularly and reviewing any updates.</p>
                <h3 >11. Contact us</h3>
                <p >If you have any questions, please feel free to contact our support team at support@MergerMagic.com</p>
                <p >Send an email to Merger Magic's Data Protection Officer at privacy@Merger Magic.com</p>
                <p >Or write to us at the following address:&nbsp;15 Hastings Avenue, Bradford, West Yorkshire, England</p>
                <p >For more information about privacy issues and GDPR, please contact the European Data Protection Supervisor, the European Union's independent data protection authority or visit their website at: https://edps.europa.eu/</p>
                                    
            </section>
        </>
        
    )
}