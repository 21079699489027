import { useEffect, useState } from "react";

import { Button, Card, CardBody } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { FaHistory } from "react-icons/fa";
import check from '../../assets/svgs/check.png'





export const CompletePage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Welcome on board');
    const navigate = useNavigate();
    const {id} = useParams();
    const [info, setInfo] = useState(null);
    const [user, setUser] = useLocalStorage("user", {});
    
   useEffect(() => {
    setInfo(user?.user)
   }, [user]);

    return (
        <div className="text-center w-100 m-auto">

            <Card className="border-0">
                <CardBody>
                    
                    <h1 className="text-dark">Thank you for setting up your profile.</h1>

                    <div className="my-5">
                        <img src={check} alt="user profile" className="user-avatar" />
                    </div>

                    <p className="">An email has been sent to <strong className="text-primary">{info && info.email}</strong>, please follow the instruction to verify it.</p>

                    <Button onClick={()=>{navigate('/')}} variant="primary" size="lg" >Start Searching</Button>
                </CardBody>
            </Card>
            
            
        </div>
    );
}