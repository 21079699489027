import { useState } from "react"
import { ListingSocial } from "./ListingSocial"
import { Badge } from "react-bootstrap";

export const WebAnalytics = ({item}) => {


    return (
        <>
            <h5 className="text-dark">About the business</h5>
            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
            <hr />
            <div>
                <h5 className="text-dark">Meta Description</h5>
                <p>{item.meta_description}</p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Tags</h5>
                <p>
                    {item.meta_keywords && item.meta_keywords.split(',').map((tag,i) => (<Badge key={i} bg="secondary" className="m-1">{tag}</Badge>))}
                </p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Purchasing Fullfilment</h5>
                <p>{item.purchasing_fulfillment}</p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Why selling</h5>
                <p>{item.why_selling}</p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Suits for</h5>
                <p>{item.suits_for}</p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Social Media</h5>
                <ListingSocial item={item} />
            </div>
        </>

    )
} 