import { useState } from "react";
import { Button, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { useToast } from "../hooks/ToastContext";
import { useForm } from "react-hook-form";
import AuthService from "../services/AuthService";
import { useAuth } from "../hooks/useAuth";
import { Spinner } from "./Spinner";

export const ActivateListingComponent = ({user, listing, createPassword}) => {



    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const showToast = useToast();
    const [spinner, setSpinner] = useState(false);
    const { login } = useAuth();
    const info = user?.user || null
    
    const handleActivate = () => {
        if (info && info.id && info.id === listing.user.id) {
            navigate(`/user/listings/${listing.type}/${listing.id}`);
        }
        if (!info?.id) {
            setShow(true);
        }
    }

    const onSubmit = data => {
        
        const payload = {
            token: createPassword.token,
            password: data.password,
            password_confirmation: data.cpassword,
            email: createPassword.email
        }

        setSpinner(true)
        AuthService.createPassword(payload)
        .then(async (res) => {
            setSpinner(false)
            await login({token: res.data.token, user: res.data.user});
            showToast({ severity: 'success', detail: res.data?.message })
            navigate(`/user/listings/${listing.type}/${listing.id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message || 'Something went wrong, please try again' })
        });
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="d-grid gap-2 m-3">
            <Button size="lg" variant="outline-success" onClick={() => handleActivate()} >Activate your listing</Button>

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Create Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {spinner && <Spinner />}
                    <p>Create your password to activate your listing in two easy steps.</p>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <div className="form-group mt-3">
                            <label>New Password</label>
                            <input type="password" 
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', {required: true, 
                                        maxLength: 100,
                                        minLength: {
                                            value: 6,
                                            message: 'Password must be at least 6 character long'
                                        }})} 
                            />

                            {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                            {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                        </div>

                        <div className="form-group mt-3">
                            <label>Confirm Password</label>
                            <input type="password" 
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    {...register('cpassword', {required: true, 
                                        validate: (value) => value === watch("password") || "Password do not match"
                                    })} 
                            />

                            {(errors.cpassword && errors.cpassword.type==='required') && <div className='text-danger'>Confirm Password is required</div>}
                            {(errors.cpassword?.message) && <div className='text-danger'>{errors.cpassword?.message}</div>}
                        </div>

                    
                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Submit</button>
                        
                    </form>
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}