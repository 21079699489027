import { useEffect, useState } from "react";

import { Button, Card, CardBody } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { FaHistory } from "react-icons/fa";





export const UserWelcomePage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Welcome on board');
    const navigate = useNavigate();
    const {id} = useParams();
    const [info, setInfo] = useState(null);
    const [user, setUser] = useLocalStorage("user", {});
    
   useEffect(() => {
    setInfo(user?.user)
   }, [user]);

    return (
        <div className="text-center w-100 m-auto">

            <Card className="border-0">
                <CardBody>
                    <h1 className="text-dark">Hello {info && info.name},</h1>

                    <h2 className="my-3 text-dark">Let's set up your profile, and we'll present assets and businesses that align perfectly with your criteria.</h2>

                    <p className="my-5 text-muted"><FaHistory /> Estimated Completion Time: 2 Minutes </p>

                    <Button onClick={()=>{navigate('/profile/tips/' + id)}} variant="primary" size="lg" >Setup Profile</Button>
                </CardBody>
            </Card>
            
            
        </div>
    );
}