import { useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, Form } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { Link, useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/ToastContext";




export const ForgotPasswordPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Forgot Password');
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, watch } = useForm();

    const [spinner, setSpinner] = useState(false);
    

    const showToast = useToast();
    
    
    const onSubmit = data => {
        setSpinner(true)
        AuthService.recover(data)
        .then(async (res) => {
            setSpinner(false)
            showToast({ severity: 'success', detail: res.data?.message })
            navigate('/auth/reset-link')
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.email || 'Something went wrong, please try again' })
        });
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                       
                        <div className="form-group">
                            <label htmlFor="floatingInput">You email address</label>
                            <input type="text" 
                                    className="form-control" 
                                    id="floatingInput" 
                                    placeholder="name@example.com"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9])?/,
                                            message: 'Enter a valid email address'
                                        }
                                    })} 
                            />

                            {(errors.email && errors.email.type==='required') && <div className='text-danger'>Email is required</div>}
                            {(errors.email?.message) && <div className='text-danger'>{errors.email?.message}</div>}
                            
                        </div>
                        
                    
                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Submit</button>
                        
                        <p className="text-sm ">
                            Already have an account? <Link to="/login">Login here</Link>
                        </p>
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}