import { Link } from "react-router-dom"
import { SocialLinks } from "../components/SocialLinks"
import { useEffect, useState } from "react";
import ListingService from "../services/ListingService";
import { FaAngleRight } from "react-icons/fa";
import { CategoryLink } from "../components";

export const FooterSection = () => {

    const [categories, setCategories] = useState([]);
    useEffect(() => {
        ListingService.categories()
        .then(res => {
            setCategories(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, [])

    return (
        <footer id="footer" className="footer">

            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center text-center">
                    <div className="col-lg-6">
                        <h4>Join Our Newsletter</h4>
                        <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
                        <form  method="post" className="php-email-form">
                            <div className="newsletter-form">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </div>
                            <div className="loading">Loading</div>
                            <div className="error-message"></div>
                            <div className="sent-message">Your subscription request has been sent. Thank you!</div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>

            <div className="container footer-top">
            <div className="row gy-4">
                <div className="col-lg-4 col-md-6 footer-about">
                <a href="index.html" className="d-flex align-items-center">
                    <span className="sitename">MergerMagic</span>
                </a>
                <div className="footer-contact pt-3">
                    
                    <p className="mt-3"><strong>Phone:</strong> <span>+44 5589 55488 55</span></p>
                    <p><strong>Email:</strong> <span>hello@mergermagic.com</span></p>
                </div>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                    <li><FaAngleRight/> <Link to="/">Home</Link></li>
                    <li><FaAngleRight/> <Link to="/privacy-policy">Privacy Policy</Link></li>
                    <li><FaAngleRight/> <Link to="/term-of-service">Terms of service</Link></li>
                </ul>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                <h4>Browse</h4>
                <ul>
                    {categories && categories.map((category) => (
                        <li key={category.id} ><FaAngleRight/><CategoryLink margin={''} category={category} /></li>
                    ))}
                    
                </ul>
                </div>

                <div className="col-lg-4 col-md-12">
                <h4>Follow Us</h4>
                <p>Premier Junction to Buy and Sell Businesses</p>
                <div className="social-links d-flex">
                    <SocialLinks />
                </div>
                </div>

            </div>
            </div>

            <div className="container copyright text-center mt-4">
            <p>© <span>Copyright</span> {new Date().getFullYear()} <strong className="px-1 sitename">MergerMagic</strong> <span>All Rights Reserved</span></p>
            
            </div>

        </footer>
    )
}

