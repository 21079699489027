
import { Card, Form, Row, Col, Button } from "react-bootstrap";

import React, { useEffect, useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import ListingService from "../../services/ListingService";
import useResponsiveFontSize from '../../components/ResponsiveFont';
import { useForm } from "react-hook-form";
import { ErrorLabel, Spinner } from "../../components";
import { useNavigate, useParams } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      [fontSize]
    );
  
    return options;
  };

export const PaymentPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Complete');
    const {id} = useParams();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [spinner, setSpinner] = useState(true);
    const [plans, setPlans] = useState([]);

    const [cardError, setCardError] = useState(true);
    const [expiryError, setExpiryError] = useState(true);
    const [cvcError, setCvcError] = useState(true);
    const showToast = useToast();

    const [userPlan, setUserPlan] = useState(null);

    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = async data => {

        if (userPlan.price > 0) {
            processPaid()
        } else {
            processFree();
        }        
        
    };

    const processFree = () => {
        ListingService.free({
            payment_id: null,
            plan_id: userPlan.id,
            amount: userPlan.price,
            days: userPlan.days,
            listing_id: id
        })
        .then(() => {
            setSpinner(false);
            showToast({ severity: 'success',  detail: 'Your listing is now visible to buyers' })
            navigate(`/user/selling`);
        })
        .catch(e => { 
            showToast({ severity: 'error',  detail: e.response?.data?.message })
            setSpinner(false)
        });
    }

    const processPaid = async () => {
        if (!stripe || !elements) {
            return;
        }

        const method = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement)
        });

        setSpinner(true)

       
        ListingService.pay({paymentMethodId: method.paymentMethod.id, amount:userPlan.price})
        .then(res => {
            ListingService.purchase({
                payment_id: res.data.id,
                plan_id: userPlan.id,
                amount: userPlan.price,
                days: userPlan.days,
                listing_id: id
            })
            .then(() => {
                setSpinner(false);
                showToast({ severity: 'success',  detail: 'Your payment was successful and your listing is now visible to buyers' })
                navigate(`/user/selling`);
            })
            .catch(e => { 
                showToast({ severity: 'error',  detail: e.response?.data?.message })
                setSpinner(false)
            });
        })
        .catch(e => { 
            showToast({ severity: 'error',  detail: e.response?.data?.message })
            setSpinner(false)
        });
    }


    useEffect(() => {
        ListingService.plans()
        .then(plans => {
            setPlans(plans.data);
            setSpinner(false)
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
            setSpinner(false);   
        });
    }, [])

    const handleUserPlan = (plan) => {
        setUserPlan(plan);
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col lg={6}>
                    <Card>
                        <Card.Header>Select a Plan</Card.Header>
                        <Card.Body>

                            {(errors.plan_id && errors.plan_id.type==='required') && <ErrorLabel >You must select a plan</ErrorLabel>}
                            {(errors.plan_id?.message) && <ErrorLabel >{errors.plan_id?.message}</ErrorLabel>}
                            
                            {plans.map(plan => {
                                return (
                                    <div className="plan-box" key={plan.id}>
                                        <div className="form-check form-check-inline">
                                            <Form.Check
                                                type="radio"
                                                value={plan.id}
                                                id={plan.id}
                                                {...register('plan_id', {
                                                    required: true
                                                })}
                                                onClick={()=>handleUserPlan(plan)}
                                            />
                                            {plan.price 
                                                ? (<Form.Label  htmlFor={plan.id}>{plan.name}  <strong>{ process.env.REACT_APP_CURRENCY || '$' + ' ' + plan.price.toFixed(2)}</strong> for {plan.days} Days</Form.Label>) 
                                                : (<Form.Label  htmlFor={plan.id}>{plan.name} for {plan.days} Days</Form.Label>)}
                                            
                                        </div>
                                        <Form.Label className="font-sm"  htmlFor={plan.id}>
                                            {plan.description}
                                        </Form.Label>
                                    </div>
                                )
                            } )}
                        </Card.Body>
                    </Card>
                </Col>
                
                <Col lg={6}>
                {userPlan && userPlan.price ?
                    (<Card>
                        <Card.Header>
                            Payment  {userPlan?.price}
                        </Card.Header>

                        <Card.Body>
                            <Row>
                                <Col lg={12} >

                                    
                                    <Form.Group>
                                        <label>Card Number</label>
                                        <CardNumberElement
                                            className='form-control'
                                            options={options}
                                            onChange={event => {
                                                setCardError(event.error)
                                            }}
                                            
                                        />
                                    </Form.Group>
                                    { cardError && <ErrorLabel>{cardError.message}</ErrorLabel>}
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col lg={8}>
                                    <Form.Group>
                                        <Form.Label>Expiration date</Form.Label>
                                        <CardExpiryElement
                                            className='form-control'
                                            options={options}
                                            onChange={event => {
                                                setExpiryError(event.error)
                                            }}
                                        />
                                    </Form.Group>
                                    { expiryError && <ErrorLabel>{expiryError.message}</ErrorLabel>}
                                </Col>

                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>
                                            CVC
                                        </Form.Label>
                                        <CardCvcElement
                                            className='form-control'
                                            options={options}
                                            onChange={event => {
                                                setCvcError(event.error)
                                            }}
                                        />
                                    </Form.Group>
                                    { cvcError && <ErrorLabel>{cvcError.message}</ErrorLabel>}
                                </Col>
                            </Row>
                            <Row className='my-3'>
                                <Col lg={6}>
                                    <Button type='submit' disabled={!stripe || cardError || expiryError || cvcError}>Submit</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        
                    </Card>)
                    :
                    (
                        <Card>
                            <Card.Body>
                            <Row className='my-3'>
                                <Col lg={6}>
                                    <Button type='submit' disabled={!userPlan} >Submit</Button>
                                </Col>
                            </Row>
                            </Card.Body>
                        </Card>
                    )
                }
                </Col>
            </Row>

            {spinner && ( <Spinner /> )}
        
        </Form>
        
    );
};