import { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import moment from"moment";

import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";

export const WatchPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage Auction');
    const [listings, setListings] = useState(null);
    const [code, setCode] = useState('');
    const [listing, setListing] = useState(null)
    const [spinner, setSpinner] = useState(null);
    const showToast = useToast();

    useEffect(()=> {
        ListingService.watchlist()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }, []);


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Watchlist
            </Card.Header>
            <Card.Body>
            
                <Table>
                    <tbody>
                        {listings && listings.data.map(item => {
                            return(
                                <tr key={item.id}>
                                    <td>
                                        <strong>{item.listing.website_business_name}</strong>
                                        <div>
                                            <Badge className="m-1">{item.listing.type}</Badge>
                                            {item.listing.status === 1 && <Badge className="m-1">Active</Badge>}

                                            Expiring { moment(item.listing.expire_date, "YYYYMMDDh").fromNow() }
                                        </div>
                                        
                                        
                                    </td>
                                    <td className="text-right">
                                        
                                        <p>
                                        <Link to={`/${item.listing.slug ? item.listing.slug : item.listing.id}/${item.listing.id}`} className="btn btn-lg btn-primary" >View Listing</Link>
                                        </p>
                                    </td>    
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
        </Card>
    );
}