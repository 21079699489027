import { Col, Row } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

export const ListingSocial = ({item}) => (
    <>
    { item.type === 'website' && 
        <Row className="my-2">
            <Col>
                <div className="social font-md d-flex text-dark">
                    { item.facebook && (<div><FaFacebook /> {item.facebook}</div>)}
                    { item.twitter && (<div className="mx-3" ><FaTwitter /> {item.twitter}</div>)}
                    { item.instagram && (<div className="mx-3" ><FaInstagram /> {item.instagram}</div>)}
                    { item.linkedin && (<div className="mx-3" ><FaLinkedin /> {item.linkedin}</div>)}
                </div>
            </Col>
        
        </Row>
    }
    </>
)