import http from './http-common'

const config = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

const getOne = async (id) => {
    const res = await http.get(`/listings/${id}`);
    return res;
};

const show = async (id) => {
  return await http.get(`/listings/show/${id}`);
};

const create = async (payload) => {
  const response = await http.post("/listings", payload);
  return response.data;
};

const update = async (id, payload) => {
  return await http.put(`/listings/${id}`, payload)
};

const listing = async () => {
  return await http.get(`/listings`)
};

const completed = async () => {
  return await http.get(`/listings/completed`)
};

const drafted = async () => {
  return await http.get(`/listings/drafted`)
};

const pay = async (payload) => {
  return await http.post(`/listings/charge`, payload)
};

const purchase = async (payload) => {
  return await http.post(`/listings/purchase`, payload)
};

const free = async (payload) => {
  return await http.post(`/listings/free`, payload)
};

const plans = async () => {
  return await http.get(`/plans/plain`)
};


const categories = async () => {
  return await http.get(`/categories/list`)
};

const category = async (id) => {
  return await http.get(`/categories/${id}`)
};

const categoryBySlug = async (slug) => {
  return await http.get(`/categories/${slug}`)
};

const upload = async (payload) => {
  return await http.post(`/file`, payload, config)
};

const activeListings = async (page) => {
  return await http.get(`/listings/active${page}`)
};

const searchListings = async (page, payload) => {
  return await http.post(`/listings/search${page}`, payload)
};

const listingByCategory = async (id, page = '') => {
  return await http.get(`/listings/active-by-category/${id}${page}`)
};

const relatedListings = async (id, listingId) => {
  return await http.get(`/listings/active-by-category-self-exculuded/${id}/${listingId}`)
}

const addToWatchlist = async (payload) => {
  return await http.post(`/watch`, payload)
}

const watchlist = async () => {
  return await http.get(`/watch`)
};

const removeFromWatchlist = async (id) => {
  return await http.delete(`/watch/${id}`)
}

const methods = async() => {
  return await http.get('/methods/plain')
}

const bid = async (payload) => {
  return await http.post(`/bids`, payload)
}

const withdraw = async (id) => {
  return await http.delete(`/bids/${id}`)
}

const approve = async (id) => {
  return await http.get(`/bids/approve/${id}`)
}

const disapprove = async (id) => {
  return await http.get(`/bids/disapprove/${id}`)
}

const buying = async () => {
  return await http.get(`/listings/buying`)
};

const conversations = async () => {
  return await http.get(`/conversations`)
};

const conversationStart = async (listingId, receiverId) => {
  return await http.get(`/conversations/${listingId}/${receiverId}`);
};

const send = async (conversationId, payload) => {
  return await http.post(`/conversations/${conversationId}/send`, payload)
};

const messages = async (listingId, userId) => {
  return await http.get(`/conversations/${listingId}/${userId}`)
};

const businessModels = async () => {
  return await http.get(`/business-models`)
};

const businessExperiences = async () => {
  return await http.get(`/business-experiences`)
};

const businessIndustries = async () => {
  return await http.get(`/business-industries`)
};

const uploadListing = async (payload) => {
  return await http.post(`/admin/listings/bulk-upload`, payload, config)
};

const imported = async (page) => {
  return await http.get(`/admin/listings/imported${page}`)
};

const sendInvitation = async (userId, listingId) => {
  return await http.get(`/admin/users/invite/${userId}/${listingId}`)
};

const ListingService = {
  listing,
  completed,
  drafted,
  getOne,
  show,
  create,
  update,
  pay,
  purchase,
  free,
  plans,
  categories,
  category,
  categoryBySlug,
  upload,
  activeListings,
  searchListings,
  listingByCategory,
  relatedListings,
  methods,
  addToWatchlist,
  removeFromWatchlist,
  watchlist,
  bid,
  withdraw,
  approve,
  disapprove,
  buying,
  conversations,
  conversationStart,
  send,
  messages,
  businessModels,
  businessExperiences,
  businessIndustries,
  uploadListing,
  imported,
  sendInvitation
};

export default ListingService;