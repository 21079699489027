import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

const useLocalTime = (serverTime) => {
  const [localTime, setLocalTime] = useState(null);

  useEffect(() => {
    if (serverTime) {
      const localDate =  moment.tz(serverTime, Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DDTHH:mm:ss");
      setLocalTime(localDate);
    }
  }, [serverTime]);

  return localTime;
};

export default useLocalTime;
