import DomainImg from '../../assets/svgs/domains.svg'
import WebImg from '../../assets/svgs/website.svg'
import AppImg from '../../assets/svgs/app.svg'
import { useNavigate } from "react-router-dom";
import ListingService from '../../services/ListingService';
import { Spinner } from "../../components";
import { useState } from "react";
import { usePageTitle } from '../../hooks/usePageTitle';


export const ListingType = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Listing Type');

    const [spinner, setSpinner] = useState(false);
    const navigate = useNavigate();

    const handleClick = (type) => {
        setSpinner(true)
        ListingService.create({type})
        .then(async (res) => {
            setSpinner(false)
            navigate(`/user/listings/${type}/${res.id}`);
        })
        .catch(e =>{
            setSpinner(false)
        });
    }


    return(
        <div >
            {spinner && (<Spinner />)}
                        
            <div className="container services">

                <div className="row gy-4">

                    <div onClick={()=>{handleClick('domain')}} className="col-xl-4 col-md-6 d-flex pointer" data-aos="fade-up" data-aos-delay="100">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <img src={DomainImg} className="img-fluid" alt='Domain' />
                            </div>
                            <h4 className="stretched-link">
                                Domain
                            </h4>
                            <p>Domain names that are undeveloped or parked. (Only the domain)</p>
                        </div>
                    </div>

                    <div onClick={()=>{handleClick('website')}} className="col-xl-4 col-md-6 d-flex pointer" data-aos="fade-up" data-aos-delay="100">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <img src={WebImg} className="img-fluid" alt='website' />
                            </div>
                            <h4 className="stretched-link">
                                Website
                            </h4>
                            <p>Which are currently trading and is generating revenue.</p>
                        </div>
                    </div>
                    
                    {/* <div onClick={()=>{handleClick('app')}} className="col-xl-4 col-md-6 d-flex pointer" data-aos="fade-up" data-aos-delay="100">
                        <div className="service-item position-relative">
                            <div className="icon">
                                <img src={AppImg} className="img-fluid" alt='mobile apps' />
                            </div>
                            <h4 className="stretched-link">
                                Apps
                            </h4>
                            <p>You are selling an Established or Starter App for mobile or tablet.</p>
                        </div>
                    </div> */}

                </div>

            </div>
        
        </div>
    );
}