import { Link } from "react-router-dom";

export const BlurInfo = () => (
    <div className="blur-bg">
        <div className="blur-content">
            <p className="strong">Create your account to view detailed information, including traffic, financials and seller notes </p>

            <Link to='/register' className="btn btn-primary">Signup</Link>

            <p>Already have an account? <Link to="/login">Login</Link></p>
        </div>
    </div>
)