
import moment from"moment";
import { Toast } from 'primereact/toast';

import { Alert, Badge, Button, Table } from "react-bootstrap"
import { Amount } from "./Amount"

import { confirmDialog } from 'primereact/confirmdialog';
import ListingService from "../services/ListingService";
import { useRef, useState } from "react";


export const SellerBiddingDetail = ({data}) => {
    const [bids, setBids] = useState(data);
    const toast = useRef(null);

    const handleUpdates = (bidId, bidInfo) => {
        const index = bids.findIndex(b => b.id === bidId)
        const updatedBids = [...bids];
        updatedBids[index]['approved'] = bidInfo.approved;
        updatedBids[index]['approved_on'] = bidInfo.approved_on;
        setBids(updatedBids);
    }

    const handleApprove = (id) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => {
                ListingService.approve(id)
                .then((res) => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Bid is approved', life: 3000 });
                    handleUpdates(id, res.data?.data)
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: e.response?.data?.message, life: 3000 })
                })
            },
        });
    }

    const handleCancelApprove = (id) => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => {
                ListingService.disapprove(id)
                .then((res) => {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Approval is successfully cancelled', life: 3000 });
                    handleUpdates(id, res.data?.data)
                })
                .catch(e => {
                    toast.current.show({severity: 'error', summary: 'Error', detail: e.response?.data?.message, life: 3000 })
                })
            },
        });
    }

    return(
        <>
        <Table border={0}>
            <thead>
                <tr>
                    <th>From</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Approevd On</th>
                    <th>Bid On</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {bids && bids.map(bid => (
                    <tr key={bid.id}>
                        <td>{bid.bidder.name}</td>
                        <td><Amount amount={bid.amount} /></td>
                        <td>
                            {bid.approved ? (
                                <Badge bg="success" >Approved</Badge>
                            ) : (
                                <Badge bg="danger">Pending</Badge>
                            )}
                        </td>
                        <td>
                            {bid.approved_on ? (
                                `${ moment(bid.approved_on).format("LLL")	}`
                            ) : (
                                'N/A'
                            )}
                        </td>
                        <td>
                            { moment(bid.created_at).format("LLL")	}
                        </td>
                        <td>
                            {bid.approved ? (
                                <Button variant="danger" onClick={() => handleCancelApprove(bid.id)} size="sm" type="button">Cancel</Button>
                            ) : (
                                <Button variant="success" onClick={() => handleApprove(bid.id)} size="sm" type="button">Approve</Button>
                            )}
                            
                        </td>
                    </tr>
                ))}

                {!bids.length && (
                    <tr>
                        <td colSpan={6}><Alert variant="info">{process.env.REACT_APP_NO_DATA_AVAILABLE}</Alert></td>
                    </tr>
                )}
            </tbody>
            
        </Table>
        <Toast ref={toast} />
        </>
    )
}