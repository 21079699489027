const maxInArray = (array) => {
    let maxValue = Number.MIN_VALUE;
    for (let i = 0; i < array.length; i++) {
        if (array[i].amount > maxValue) {
            maxValue = array[i].amount;
        }
    }
    return maxValue;
}


const Helper = {
    maxInArray,
};

export default Helper;