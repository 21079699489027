import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";

import { Spinner } from "../components";

import AuthService from "../services/AuthService";
import { Card, CardBody } from "react-bootstrap";



import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { Toast } from 'primereact/toast';


export const Login = ({onLogin}) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [spinner, setSpinner] = useState(false);
    const [serverError, setServerError] = useState([]);
    const { login } = useAuth();
    const toast = useRef(null);
    
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
          handleGoogleAuth(jwtDecode(credentialResponse.credential))
        },
        onError: () => {
          console.log('Login Failed');
        },
    });
    
    const onSubmit = (data) => {
        
        setServerError([])
        setSpinner(true)
        AuthService.login(data)
        .then(async (res) => {
            onLogin(res);
            toast.current.show({ severity: 'success', detail: 'You are logged in now'});
            await login(res);
            setSpinner(false)
            
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    const handleGoogleAuth = (user) => {
        AuthService.googleAuth(user)
        .then(async (res) => {
            setSpinner(false)
            onLogin(res);
            toast.current.show({ severity: 'success', detail: 'You are logged in now'});
            await login(res);
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}
            <Toast />

            <form onSubmit={handleSubmit(onSubmit)}>
                { serverError && serverError.map((e, i) => (
                    <p key={i} className="text-danger">{e}</p>
                ))}
                <div className="form-group">
                    <label htmlFor="floatingInput">Email</label>
                    <input type="text" 
                            className="form-control" 
                            id="floatingInput" 
                            placeholder="name@example.com"
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9])?/,
                                    message: 'Enter a valid email address'
                                }
                            })} 
                    />

                    {(errors.email && errors.email.type==='required') && <div className='text-danger'>Email is required</div>}
                    {(errors.email?.message) && <div className='text-danger'>{errors.email?.message}</div>}
                    
                </div>
                
                <div className="form-group mt-3">
                    <label>Password</label>
                    <input type="password" 
                            className="form-control"
                            placeholder="Password"
                            {...register('password', {required: true, 
                                maxLength: 100,
                                minLength: {
                                    value: 6,
                                    message: 'Password must be at least 6 character long'
                                }})} 
                    />

                {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                    
                </div>

            
                <button className="btn btn-primary w-100 py-2 my-3" type="submit">Sign in</button>

                <div className="my-3">

                    <GoogleLogin
                        onSuccess={credentialResponse => {
                            handleGoogleAuth(jwtDecode(credentialResponse.credential))
                        }}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />

                </div>

                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    <Link className="text-primary" to="/register">Create a new account</Link>
                </p>

            </form>
        </div>
    );
}