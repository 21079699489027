import { PageHeaderComponent } from "../../components/PageHeaderComponent"

export const TermsPage = () => {
    return (
        <>
            <PageHeaderComponent title={'Terms of Service'} summary={'Last updated on 27-10-2023'} />
            <section className="container">
                <h3 >1. Introduction</h3>
                <p>Welcome to MergerMagic.com ("Website," "we," "us," or "our"). These Terms of Service ("Terms") govern the utilization of the Website and its affiliated services, encompassing classified listings, messaging, and other interactive features (collectively, the "Services"). Kindly peruse these Terms meticulously before engaging with the Website. Your access or use of the Website implies your explicit acceptance and compliance with these Terms and our Privacy Policy, which is expressly incorporated herein. If you do not concur with these Terms or the Privacy Policy, we respectfully advise refraining from any use of the Website.</p>
                <h3>2. Privacy</h3>
                <p>Ensuring the utmost protection of your privacy is of paramount importance to us. We encourage a thorough review of our Privacy Policy to comprehend the procedures for the collection, utilization, and safeguarding of your personal information.</p>
                <p >Merger Magic is obligated to collect and handle your personal information, encompassing your name, contact details, payment particulars (including, but not limited to, credit card details), browsing history, profile data (including profile photographs), and website activities within our proprietary and third-party algorithms. Our practices adhere strictly to all pertinent privacy laws and data protection regulations, as stipulated by our Privacy Policy, which remains subject to periodic revisions.</p>
                <p >By engaging with our website services, you explicitly acknowledge and agree to the utilization of your personal information in accordance with the guidelines articulated in our Privacy Policy. This encompasses the processing of your browsing history, profile data, and site activities within our proprietary and third-party algorithms to facilitate personalized recommendations and invitations pertinent to potential business deals and prospective buyers. In instances where a match is made, pertinent aspects of your profile, including the summary provided by you, profile photograph, and preferences, may be presented to the relevant parties. We uphold strict confidentiality and do not disclose your phone number, street address, or email to other users on the platform. It is imperative that, unless specifically agreed upon between you and another buyer or seller, you abstain from utilizing their personal information for any purposes beyond the scope of the platform's intended usage. You warrant and assure Merger Magic of your full compliance with all applicable privacy laws and data protection regulations, including, but not limited to, the provisions outlined in the Data Protection Act 2018, concerning the storage, usage, and transfer of personal information.</p>
                <h3 >3. Eligibility</h3>
                <p>To access the Website or its Services, you are required to be at least 18 years of age. By engaging with the Website, you confirm and guarantee that you have attained the age of 18 and possess the legal competence necessary to be bound by these Terms.</p>
                <h3>4. Electronic Communication</h3>
                <p>Upon availing yourself of our services, you hereby consent to receiving electronic communications from us. These communications may encompass crucial updates, informative announcements, and promotional content. We may choose to communicate with you via email or disseminate notifications directly on our website.</p>
                <p >In the course of utilizing Merger Magic Services or transmitting emails, text messages, or other forms of communication from your desktop or mobile device, you engage in electronic communication with us. Our mode of communication with you will be predominantly electronic and may take various forms, including but not limited to email, text messages, or interactive exchanges through our discussion forum. This encompasses the transmission of significant updates, informative announcements, and promotional content. By utilizing our services, you explicitly acknowledge and agree to receive communications from us electronically, thereby affirming that any communications provided to you electronically fulfill all legal requisites mandating written communication.</p>
                <h3 >5. Copyright and Other Rights</h3>
                <p >We maintain a stringent approach towards upholding copyright and other intellectual property rights. Users are unequivocally prohibited from posting any content that encroaches upon these rights. We respectfully urge all users to uphold and honor the intellectual property of others. All content incorporated within or made accessible by Merger Magic, including but not limited to text, graphics, logos, button icons, images, video and audio clips, digital downloads, and data compilations, represents the proprietary assets of Merger Magic and is safeguarded by both UK and International Copyright laws, in addition to authors' rights laws and, where applicable, database right laws.</p>
                <p >With the exception of the extent necessary to utilize Merger Magic Services for their designated purposes and in strict accordance with these Terms, you are explicitly prohibited from copying, extracting, and/or re-utilizing any content from Merger Magic without our express written consent. This includes, but is not limited to, any listings, descriptions, prices, and account information. Of particular significance, you are strictly prohibited from deploying any data mining tools, robots, or similar data extraction and compilation mechanisms to extract, for re-utilization, substantial portions of the Merger Magic Services or their content, without obtaining our express written consent. You are also prohibited from creating and/or publishing your own database that incorporates substantial segments of the Merger Magic Services or their content, without securing our express written consent.</p>
                <h3 >6. Trademarks</h3>
                <p >All trademarks, service marks, and logos displayed on Merger Magic are the exclusive property of their respective owners. Unauthorized use or reproduction of these intellectual properties without explicit consent from the rightful proprietors is strictly prohibited. Graphics, logos, page headers, button icons, scripts, and service names incorporated within or accessible through the Merger Magic Services are recognized as the trademarks of Merger Magic. The utilization of Merger Magic's trademarks is restricted to products and services directly affiliated with Merger Magic. Any use that may lead to confusion among consumers or cast a negative light on the reputation of Merger Magic is expressly prohibited.</p>
                <p>Any other trademarks not directly associated with Merger Magic, yet visible within the Merger Magic Services, remain the sole property of their respective owners, who may or may not have a formal affiliation, connection, or sponsorship with Merger Magic. Users are not authorized to employ framing techniques to enclose any trademark or logo of Merger Magic without obtaining prior express written consent. Additionally, the use of any meta tags or other "hidden text" incorporating Merger Magic's names or trademarks is strictly prohibited without obtaining explicit written consent from Merger Magic.</p>
                <h3 >7. User Registration</h3>
                <p>In order to access specific features of Merger Magic and utilize its services, the creation of an account may be necessary. Upon registration, you pledge to provide precise, current, and comprehensive information about yourself. You are accountable for upholding the confidentiality of your account and password, as well as restricting access to your computer or device. You accept the responsibility for all activities occurring under your account.</p>
                <p >Upon registering and establishing an account with Merger Magic, you affirm and guarantee that you have surpassed the age of 18 and possess the legal capacity to enter into binding agreements as per the applicable UK law. If you are registering an account on behalf of a business, organization, or any other legal entity, you assure and affirm that you possess the requisite authority to bind the said entity. You are permitted to operate multiple accounts on Merger Magic provided that you maintain only one account for personal use and one account for business purposes.</p>
                <p >You are accountable for maintaining the confidentiality of your account and password. Except in cases resulting from our violation of these Terms, you bear responsibility for all activities conducted under your account, irrespective of whether these activities are authorized by you or conducted by you. Merger Magic bears no responsibility for unauthorized access to or misuse of your password or account. It is incumbent upon you to take all necessary precautions to safeguard the confidentiality and security of your password. Kindly notify us promptly if you suspect that your password has become known to any unauthorized party or is being, or is likely to be, used in an unauthorized manner. You must ensure that the information provided to us is accurate and complete and notify us promptly of any changes. You can access and update certain information provided to us through your account dashboard.</p>
                <p >In the event that we have reservations about your account or any activities associated with it, or if you are found to be in violation of the applicable UK laws or these Terms, we reserve the right to take appropriate action on your account in line with these Terms. This may include, but is not limited to, (a) the refusal of service, (b) the suspension or restriction of access to your account, (c) the termination of your account, or (d) the removal or modification of content posted using your account. The action chosen and any notification issued to you will be contingent upon the specific circumstances, the nature of the Merger Magic Services, and our assessment of relevant factors. In instances involving suspected fraud, deliberate misleading of Merger Magic users, or evidence of shill bidding or similar misconduct, an immediate ban will be enforced without the opportunity for recourse.</p>
                <h3 >8. Identity Verification</h3>
                <h4 >8.1 Verification of Government-issued ID</h4>
                <p >We reserve the right to request government-issued identification for the purpose of verification. This measure aids us in ensuring the security and authenticity of our user community. It is imperative to note that any indication of a buyer or seller being "verified" solely implies the completion of the relevant verification or identification process. Such a description does not serve as an endorsement, certification, or guarantee by Merger Magic concerning any buyer or seller, including their identity, background, or their trustworthiness, safety, or suitability.</p>
                <p >To access certain Merger Magic Services, you may be required to provide proof of identity or other means of identity verification. By utilizing the Merger Magic platform, you consent to providing Merger Magic or any third party associated with Merger Magic all necessary identification documents (including, but not limited to, copies of passports and/or government-issued driver's licenses) as and when requested by Merger Magic for the purposes of verifying your identity.</p>
                <h4 >8.2 Evaluation of Authenticated Data</h4>
                <p >In our commitment to upholding a secure and dependable platform, we undertake a comprehensive verification and vetting process for the data furnished by our users. Merger Magic empowers sellers with the option to integrate their assets with various third-party providers, including but not limited to Google Analytics, Google AdSense, Shopify, WooCommerce, and Stripe. A listing labeled as "Verified Data" signifies that the seller has integrated with at least one of these product providers.</p>
                <p >Merger Magic boasts a team of specialists, inclusive of our broker partners, who are adept at scrutinizing assets to ensure the accuracy of presented data, thereby contributing to the preservation of the integrity of our marketplace. A listing denoted as "Verified by Merger Magic" indicates that a Merger Magic representative or broker partner has meticulously reviewed and authenticated the information provided by a seller, confirming its accuracy.</p>
                <p >While an asset or business may bear the mark of being "Verified" and/or "Verified by Merger Magic," Merger Magic does not provide any assurances or representations regarding the precision and comprehensiveness of the displayed data or the present or future performance of a business or asset. Third-party data authentication or review conducted by Merger Magic or one of its broker partners does not serve as a substitute for due diligence. As a prospective buyer, you acknowledge your responsibility to thoroughly investigate all aspects pertaining to the acquisition of assets, which includes independent scrutiny of financial information, market conditions, operational intricacies, supplier connections, and other pertinent factors related to the functioning of the business. Merger Magic advises supplementing the information furnished by Merger Magic or a broker partner with your own diligent examination and analysis.</p>
                <h4 >8.3 Verification of Funds</h4>
                <p >Certain transactions may necessitate the implementation of funds verification protocols to uphold the security and transparency of financial dealings on our platform. Merger Magic grants buyers the opportunity to attain funds verification status ("Verified Funds Buyer"). A Verified Funds Buyer is a registered Merger Magic buyer who has completed the verification process via Plaid.com and/or has submitted the requisite documentation to Merger Magic. A minimum of GBP£3500 in qualifying assets is mandated for verification. The distinctive "Verified Funds" badge on their public profile readily distinguishes a Verified Funds Buyer. In addition, a Verified Funds Buyer enjoys exclusive access to listings featuring an asking price surpassing GBP£150,000. In instances involving a CONFIDENTIAL listing, automatic approval for access is granted upon the buyer's execution of the pertinent Non-Disclosure Agreement (NDA). Buyers who enrolled with Merger Magic before August 1, 2023, possess a purchase history, and have yet to verify proof of funds will be granted access to listings with an asking price equivalent to three times their cumulative purchase history. However, these buyers will not attain the status of Verified Funds Buyers until they have successfully completed the verification process via Plaid or manually submitted the required documentation.</p>
                <h4 >8.4 Role of Merger Magic</h4>
                <p >Merger Magic serves as a platform enabling third-party sellers to list and sell their assets via the Merger Magic Services. It is essential to note that Merger Magic does not possess, fabricate, sell, resell, provide, control, or deliver listings or the assets featured therein. The pertinent seller is clearly indicated on the respective listing page. While Merger Magic assists in facilitating transactions conducted on the Website, it does not function as a buyer or seller in any transaction involving the seller's assets or any other transaction among users of the Services.</p>
                <h5 >8.4.1 Contractual Agreements</h5>
                <p >Merger Magic provides a medium for sellers and buyers to negotiate and finalize transactions. Consequently, the contractual agreement entered into by the sellers and buyers pertaining to the seller's assets is exclusively between the buyer and seller. Merger Magic assumes no role as a participant in this contract and bears no responsibility arising from or associated with the contract and/or the transaction. Merger Magic does not act as an agent for the buyer or seller. The seller is exclusively accountable for the sale of their assets, and the buyer assumes sole responsibility for the purchase of the assets.</p>
                <h5 >8.4.2 Dispute Resolution</h5>
                <p >When utilizing the Services, both buyers and sellers acknowledge that in the event of a dispute between them, Merger Magic does not serve as a participant in any transaction between the parties and therefore will not intervene in any such dispute, except as follows. Buyers and sellers understand and agree that upon receipt of notice of a dispute from either party, and solely prior to the shipping and/or delivery of the assets or under any circumstances where the preceding is not feasible, Merger Magic may elect, at its sole and absolute discretion, to cancel the transaction. In such an instance, both the buyer and seller acknowledge and agree that neither party shall have any grounds for legal action against Merger Magic. In the event that either party pursues litigation against Merger Magic, the non-litigating party shall indemnify Merger Magic in accordance with indemnity clause 22.</p>
                <h5 >8.4.3 Limitation of Responsibilities</h5>
                <p >Merger Magic bears no responsibility for:</p>
                <p >(a) the validity, quality, suitability, or legality of a listed asset;</p>
                <p >(b) the accuracy or completeness of the information within a listing;</p>
                <p >(c) the future performance of a listed asset;</p>
                <p >(d) the conduct of a buyer, seller, or third party on the Website; and/or</p>
                <p >(e) the quality, suitability, or capability of a third party providing goods or services to Merger Magic or to a buyer or seller.</p>
                <h5 >8.4.4 Due Diligence</h5>
                <p >Your decision to procure a listed asset should be based solely on your own examination and that of your legal, tax, and other advisors. You assume sole responsibility for scrutinizing and investigating an asset and all information within a listing. This includes, but is not limited to, associated liabilities, financial statements, tax returns, and any other pertinent facts or information that may influence your decision to purchase the listed asset and the price you are willing to pay. You acknowledge that Merger Magic may only present a summarized description of a listed asset.</p>
                <h5 >8.4.5 Legal Facilitation</h5>
                <p >To ensure a secure transaction and uphold the integrity of the Merger Magic Services, legal services and template legal documents are accessible through Merger Magic Legal powered by Contracts Counsel. You understand that utilizing these documents is at your own risk. You agree that neither Merger Magic nor Contracts Counsel has provided any form of legal or financial advice by offering these documents, nor have they made representations or warranties regarding their suitability for your requirements or needs, which may not be appropriate or suitable for the sale or purchase of a specific asset. Merger Magic advises all parties to seek their own independent legal advice regarding these documents.</p>
                <h3 >9. Listings and Transactions</h3>
                <p >Users are obliged to adhere to our listing criteria when formulating and publishing listings on our platform. These guidelines are instituted to uphold an equitable and reliable marketplace for all users. The subsequent assets are permissible for listing on Merger Magic:</p>
                <p >(i) All references and details pertaining to files within a website or an application, encompassing but not limited to the HTML, code base, other source code, logos, images, music, animations, films, and other media, licenses, and other works associated with the website or application;</p>
                <p >(ii) Any rights and obligations derived from or associated with an agreement with a third party regarding assets, including, but not limited to, hosting agreements, payment provider agreements, affiliate agreements, and seller agreements;</p>
                <p >(iii) A brand name affiliated with a business, encompassing any registered trademarks specified in the listing;</p>
                <p >(iv) Any records;</p>
                <p >(v) All rights to a domain name;</p>
                <p >(vi) Any other documented asset, including inventory or other critical agreements vital to the sale and ongoing operations of a business, comprising legal agreements, employment agreements, leasing agreements, and other contracts; and</p>
                <p >(vii) Any other specifics stipulated as part of a listing.</p>
                <p >It is explicitly clarified that Merger Magic does not advocate, endorse, or facilitate the trade of securities using the Merger Magic Services.</p>
                <h4 >9.1 Listing Creation</h4>
                <p >Upon creating a listing, you will be required to furnish comprehensive and accurate information regarding your asset, including but not limited to a business description and current as well as historical financial data. You are accountable for your listing and are expected to maintain up-to-date listing information at all times. Additionally, you will be prompted to establish an asking price for your asset(s), either a fixed price in the case of a fixed-price listing or a starting price, reserve price, and optional buy-it-now price in the case of an auction-format listing. Please be aware that Merger Magic reserves the right, at its sole discretion, to prohibit you from listing your asset for sale or to suspend an existing listing if the designated price is deemed unreasonable or misaligned with market value.</p>
                <h4 >9.2 Warranty and Representation</h4>
                <p >You assert and affirm to Merger Magic and to each buyer that:</p>
                <p >(i) You are the owner of the asset or have the authority to sell the asset;</p>
                <p >(ii) You hold ownership of all intellectual property rights, including but not limited to copyright, patents, trademarks, designs (whether registered or not) worldwide, and all other rights, titles, and interests in the asset;</p>
                <p >(iii) The asset is transferable to a buyer; and</p>
                <p >(iv) Any agreements with third parties are transferable to the Buyer.</p>
                <h4 >9.3 Prohibited Listings</h4>
                <p >You must refrain from creating a listing for an asset that:</p>
                <p >(i) Is deemed obscene, vulgar, and/or offensive by Merger Magic;</p>
                <p >(ii) Contains material that violates the rights of a third party or aids others in violating the rights of a third party;</p>
                <p >(iii) Engages in activities that contravene the laws of any jurisdiction; or</p>
                <p >(iv) Merger Magic deems inappropriate or in violation of these Terms.</p>
                <p >a. Listing Guidelines: You agree to comply with our listing guidelines when creating and posting classified listings on the Website. Listings must not violate any applicable laws or regulations, including but not limited to those related to fraud, privacy, and intellectual property.</p>
                <p >b. Transaction Responsibility: The Website serves as a platform for users to connect and engage in transactions. We are not a party to any transaction between users, and we do not endorse, guarantee, or authenticate any listings or users. You are solely responsible for any transactions or interactions you engage in through the Website.</p>
                <h4 >9.4 Listing Types</h4>
                <p >Merger Magic facilitates two distinct types of listings — auction-format listings and classified listings.</p>
                <h5 >9.4.1 Classified Listings</h5>
                <p >With a classified listing, any prospective buyer can propose to acquire the asset featured in a listing. The offer must be submitted on the listing. The seller retains the option to accept, reject, or counter any offer. If you are a buyer and have submitted an offer, you recognize and agree that should the seller accept your offer, you are obligated to purchase the asset and may be required to make the necessary payment. As a seller, if you have accepted an offer, you acknowledge and agree that you are committed to selling the listed asset to the buyer.</p>
                <h5 >9.4.2 Auction-Format Listings</h5>
                <p >In the case of an auction-format listing, a seller invites bids from buyers over a predetermined duration, typically 30 days. To be valid, a bid must exceed the starting price or the current bid. Upon the conclusion of the auction, the asset featured in the listing will be deemed sold to the highest bidder, provided their bid surpasses the reserve price specified by the seller. If you emerge as the auction winner, you agree that you are committed to purchasing the listed asset and may be required to make the requisite payment.</p>
                <p >For clarity, it is emphasized that Merger Magic does not act as an auctioneer, and a seller conducts an auction independently.</p>
                <h5 >'Buy It Now' Option</h5>
                <p >Additionally, a listing may display a 'Buy It Now' button alongside an associated price. A buyer has the option to instantly purchase the listed asset by clicking the 'Buy It Now' button, provided it is accepted by the seller. By selecting 'Buy It Now,' a buyer commits to the acquisition of the listed asset.</p>
                <h3 >10. Prohibited Activities</h3>
                <p >During your utilization of Merger Magic, you are expressly prohibited from engaging in any of the following activities:</p>
                <p >a. Utilizing the Website for any unlawful purpose or in contravention of any applicable laws or regulations.</p>
                <p >b. Publishing false, inaccurate, misleading, or deceptive information.</p>
                <p >c. Violating the intellectual property rights of others, including copyright and trademark rights.</p>
                <p >d. Engaging in any form of harassment, threats, or abuse directed at other users.</p>
                <p >e. Disseminating spam or any other form of unsolicited commercial messages.</p>
                <p >f. Disrupting or interfering with the functionality, security, or accessibility of the Website.</p>
                <p >g. Causing, or having the potential to cause, any form of interruption, damage, or impairment to any aspect of the Merger Magic Services, or the accessibility to it, in any manner.</p>
                <p >h. Engaging in any activity that may disrupt or inflict harm upon any other user of the Merger Magic Services, including but not limited to activities that compromise the privacy, security, or user experience of others.</p>
                <p >i. Employing the Merger Magic Services for fraudulent purposes or in connection with any criminal or other unlawful activities, explicitly contravening the regulatory framework established by these Terms.</p>
                <p >j. Engaging in any form of usage that falls outside the scope of what is explicitly permitted under the terms and conditions specified within this document.</p>
                <h3 >11. Ownership of Content</h3>
                <p >You maintain ownership of all content you submit to Merger Magic, encompassing, but not limited to, listings, text, images, and messages. By submitting content, you confer upon us a non-exclusive, worldwide, royalty-free, sublicensable, and transferable license to utilize, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and showcase such content for the sole purpose of delivering the services.</p>
                <h3 >12. Termination of Service</h3>
                <p >We maintain the prerogative to suspend or terminate your access to Merger Magic and Services at our discretion, without prior notice, for any grounds, including if we have reason to believe that you have contravened these Terms.</p>
                <p >In the case of classified websites, termination of service might entail the removal of all associated listings, and the prohibition of the user from further engagement with the platform, including any attempt to create new listings or participate in ongoing transactions. Furthermore, the termination process could involve the issuance of a notice detailing the reasons for termination, a period during which the user may contest the decision, and steps for rectification, if applicable. Upon termination, the user may be required to remove any stored information or data associated with their account, and any outstanding transactions may be subject to further investigation or cancellation.</p>
                <p >The decision to terminate a user's access to a classified website is typically guided by a thorough assessment of the user's conduct in relation to the platform's policies and regulations. Factors that might contribute to such action could include repeated violations of listing rules, engaging in fraudulent activities, or persistent disregard for the platform's community guidelines. The termination process is designed to ensure the integrity and safety of the platform's ecosystem, safeguarding the interests of all users and upholding the credibility of the marketplace.</p>
                <h3 >13. Exclusive Privileges and Restrictive Covenants</h3>
                <p >Privileges of an exclusive nature may be extended to chosen users or listings at our sole discretion, with the underlying objective of fostering an all-encompassing and esteemed community for all our esteemed users. Should an asset featured in a listing possess a stipulated asking price, as observed in fixed-priced listings, or a reserve price, in the context of auction-format listings, amounting to or exceeding GBP£20,000, you are bound to undertake the exclusive promotion and/or sale of the said asset solely through the Merger Magic Services.</p>
                <p>In the instance of assets and online businesses appraised up to GBP£70,000, during the duration in which your asset(s) remains available for sale via Merger Magic, and for an additional 90 days subsequent to the removal of your listing, you guarantee that you will refrain from utilizing any alternative platform, marketplace, or service, inclusive of brokerage services, to advocate or facilitate the sale of your asset, without obtaining the explicit written authorization from Merger Magic.</p>
                <p >Similarly, concerning assets and online businesses evaluated up to GBP£10MM, for the period encompassing the listing's availability for sale through Merger Magic, and a supplementary 180 days following the elimination of your listing, you pledge not to engage any other platform, marketplace, or service, including brokerage services, for the promotion or sale of your asset, without the explicit written consent of Merger Magic.</p>
                <p >Should it come to our attention that you have contravened the stipulations outlined in clause 10, you acknowledge and concede that Merger Magic retains the right to promptly suspend your listing, terminate your account, and potentially pursue measures to recoup a success fee, at our discretion.</p>
                <h3 >14. Evasion of Marketplace</h3>
                <p >It is unequivocally prohibited for users to sidestep our platform, engaging in transactions or communications independently outside the purview of the services extended by our platform. This stringent policy is implemented to safeguard the sanctity and inviolability of our marketplace. All investigations and negotiations pertaining to a listed asset must be conducted exclusively through the Website, unless explicitly sanctioned by Merger Magic. Any attempts at evading the established framework of Merger Magic are strictly forbidden.</p>
                <p >In the event that you effectuate or consent to payment for a listed asset, Merger Magic reserves the right to claim a success fee. In circumstances where Merger Magic is unable to recuperate the success fee from the seller, the buyer is deemed liable for the remittance of the success fee.</p>
                <h3 >15. Charges</h3>
                <h4 >15.1 Charges for Listings</h4>
                <p >We retain the right to levy charges for the provision of listing services on our platform. These charges play a pivotal role in the sustenance and advancement of our services, ensuring an elevated user experience for our esteemed community. Upon listing an asset for sale, a listing fee will be imposed. Kindly refer to the Merger Magic Pricing section for the current fees. Notably, fees are incurred solely during the creation of a listing, with no charge associated with the editing of a listing. Regardless of whether the listed asset is sold or not, the listing fees are non-refundable. Should you choose to cancel your listing or account, please direct your request to support@mergermagic.com and follow the specific instructions provided in response by Merger Magic.</p>
                <h4 >15.2 Charges for Successful Transactions</h4>
                <p >Additional charges may be applicable contingent upon the favorable outcome of transactions facilitated via our platform. These fees are essential to the continual refinement and progression of our platform. Upon a successful sale to a buyer who has been made aware of your listing through our website or has engaged with you via our platform, you are obligated to remit a success fee to Merger Magic. This fee is calculated as a percentage of the total transaction value or the final sale price paid by the buyer for your listed asset, inclusive of any applicable taxes. Kindly refer to our Success Fee Page for the current fees. Notably, the success fee remains non-negotiable and non-refundable. Merger Magic reserves the right to amend the success fee at any time, furnishing you with adequate notice before the changes take effect.</p>
                <h4 >15.3 Collection of Success Fees</h4>
                <p >In cases where an invoice remains unpaid, Merger Magic retains the right to charge your credit card for any outstanding success fees. Your usage of our services serves as authorization for us to charge your credit or debit card on file for the collection of any outstanding fees. We will make reasonable efforts to apprise you beforehand of any impending charges and provide an invoice for the unpaid sum. Should the unpaid balance persist after the due date, we retain the right to initiate the charge on your credit card without further notice. It is incumbent upon you to ensure the accuracy and currency of your credit card information. You undertake to promptly update your payment information to forestall any service interruptions or additional fees.</p>
                <h3 >16. Reimbursements and Credit System</h3>
                <p >Merger Magic retains the authority to issue credits in specific cases, as delineated in our established credits policy. Our refund policy governs the stipulations for qualifying refunds, ensuring an equitable and transparent refund procedure for our users. Should Merger Magic authorize a refund, the repayment will be executed through the same payment method employed for the initial transaction. Merger Magic may reimburse you for an amount previously remitted if such restitution aligns with the provisions set forth in these Terms or if Merger Magic is legally obligated, or deems it necessary in compliance with the law. You acknowledge that Merger Magic's determination pertaining to the necessity of a refund is conclusive and final, precluding any challenge on your part.</p>
                <p>You may accrue points or credits commensurate with your activity on the website. It is important to note that these credits do not hold the same value as currency. However, they may be utilized for the payment of Merger Magic Services, subject to the regulations specified by Merger Magic periodically. Notably, credits cannot be employed for the acquisition of a listed asset. The credit balance associated with your account is subject to reduction by Merger Magic at any time for any sum owed by you to Merger Magic, encompassing indemnifying Merger Magic for any loss determined (at its sole and absolute discretion) to have been incurred due to any violation of these Terms. The balance of your credits will expire 12 months from the date of your last credit utilization. Any credit balance remaining after this 12-month duration will be forfeited. You acknowledge and accept that Merger Magic bears no liability for any unsanctioned withdrawals or unauthorized credit utilization from your account, even in cases where such actions stem from unauthorized account access or use.</p>
                <h3>17. Financial Transaction Management</h3>
                <p >Our priority is to safeguard the reliability and security of payment processing on our platform, achieved through our collaboration with established and reputable third-party services. This approach ensures the execution of all financial transactions in a secure and efficient manner. It is important to note that Merger Magic does not function as an escrow service nor does it act as a custodian for any property on behalf of any party. Additionally, Merger Magic does not operate as a payment provider. In the process of facilitating a transaction, any and all payment processing services associated with your utilization of the Merger Magic Services are rendered to you by one or more independent third-party service providers, as appropriate. By utilizing our services, you hereby provide consent and authorization for Merger Magic to share any information and payment directives you provide with any designated third-party service provider(s).</p>
                <h3>18. Value Genius</h3>
                <p >You bear full responsibility for establishing the price of your listed asset. Merger Magic offers a program known as "Value Genius" to aid in the determination of asset values. It is essential to recognize and accept that any valuation derived from "Value Genius" is strictly an estimation and should not be considered or relied upon as a professional valuation. The valuation is generated through a mathematical model within the software that relies on accessible data, without undertaking a comprehensive analysis of the assets and without considering market circumstances or factors that could influence the value and marketability of the asset.</p>
                <h3 >19. Due Diligence</h3>
                <p >We earnestly advise all users to exercise due diligence and prudence when conducting transactions with other users through our platform. Users bear the responsibility of conducting their own investigation and verification before proceeding with any transaction. Merger Magic offers due diligence services and provides the option to purchase a due diligence report for a listed asset. While Merger Magic strives to ensure the accuracy of the information within this report, we make no assertions, commitments, or guarantees about the accuracy, completeness, or sufficiency of the contents of this report and explicitly disclaim liability for any errors and omissions in its contents. No warranty of any kind, whether implied, expressed, or statutory, including but not limited to warranties of non-infringement of third-party rights, title, merchantability, or fitness for a particular purpose, is provided with respect to the contents of this report.</p>
                <p >Additionally, all information provided herein concerning the listed asset is of an informative nature. We are not a legal advisory or business advisory service and do not claim to provide advice or recommendations on business decisions. You acknowledge and understand that the purchase of a website or online business entails risk. We assume no responsibility or liability for your investment or business outcomes. Factual statements within this report are accurate as of the stated date and are subject to change without prior notice. All information is provided solely for educational purposes, and we encourage you to seek independent advice from a competent professional should you require legal, financial, tax, or other expert assistance.</p>
                <h3 >20. User Communications and Content Guidelines</h3>
                <p >We expect all users to adhere to our community guidelines and policies when contributing reviews, comments, or engaging in communications with other users on our platform. We promote open and respectful communication within our community. You may post reviews, comments, and other content; send communications; and submit questions or other material, provided that the content does not violate the following guidelines:</p>
                <p >(a) Personal or soliciting in nature,</p>
                <p >(b) Unrelated to a listing,</p>
                <p >(c) An attempt to transact outside the website,</p>
                <p >(d) Illegal, obscene, abusive, threatening, defamatory, or an invasion of privacy,</p>
                <p >(e) Infringing upon intellectual property rights, or</p>
                <p >(f) Harmful to third parties or objectionable.</p>
                <p >You must refrain from posting or sending content that contains software viruses, or that involves political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam”. Additionally, you may not use a false e-mail address, impersonate any person or entity, or mislead as to the origin of any such content or communication. We reserve the right (but not the obligation) to remove or edit any content at any time and for any reason at our sole and absolute discretion.</p>
                <p >While comments and reviews are visible to other users, interactions and exchanges via the discussion forum are private and strictly between individuals, with access limited to Merger Magic.</p>
                <h3 >21. Proprietary Information Handling</h3>
                <p >All confidential information shared with us is handled in strict accordance with our Privacy Policy. We have robust security measures in place to protect the confidentiality of all sensitive information. To list an asset for sale on Merger Magic, you will be required to provide certain non-public, proprietary information to Merger Magic related to the asset(s) you wish to list for sale, including but not limited to:</p>
                <p >(a) Information marked or disclosed as confidential by you.&nbsp;</p>
                <p >(b) Financial, traffic, user, and other business information.</p>
                <p >(c) Any details concerning your asset or business, including the URL, if applicable.</p>
                <p >(d) Your profit and loss or balance sheet.</p>
                <p >(e) Any other information related to the listed asset (referred to as "Your Confidential Information").</p>
                <p >Your Confidential Information will consistently remain your property, with all applicable rights, including but not limited to intellectual property rights, the right of publicity, or other personal or proprietary rights, remaining with you.</p>
                <p >Merger Magic agrees to treat Your Confidential Information as confidential and not to disclose Your Confidential Information except in the following circumstances: (a) you make it publicly available to registered users and account holders by setting it live as a public listing, or (b) in the case of a “CONFIDENTIAL” listing, to a registered user or account holder approved by you and who has signed your NDA, or to a Verified Funds Buyer upon signing of your NDA.</p>
                <p >Merger Magic may disclose Seller Confidential Information to its employees, agents, attorneys, accountants, officers, and directors ("Representatives") in connection with Merger Magic’s services or (b) if Merger Magic is required to disclose any Confidential Information, it will provide you with prompt written notice to seek a protective order or waive compliance by Merger Magic with these Terms, where possible pursuant to statutory or regulatory authority. If, despite the absence of a protective order or a waiver, Merger Magic is legally obligated to disclose your Confidential Information, Merger Magic may do so without liability hereunder, based on the advice of its legal counsel.</p>
                <h3 >22. Protection of Intellectual Property Rights</h3>
                <p >We place paramount importance on the preservation of the intellectual property rights of others. Users are required to uphold these rights and abstain from engaging in any activities that may violate or infringe upon them. You bear responsibility for the content you post and warrant that you either own or control all the rights to the content and material you publish. As of the date of posting, you affirm that the content: (i) is accurate; (ii) complies with these Terms; and (iii) does not contravene any applicable laws.</p>
                <p>By posting content or submitting material, and unless explicitly indicated otherwise, you hereby grant Merger Magic a non-exclusive, royalty-free, fully sublicensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content worldwide in any media.</p>
                <p >You acknowledge that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. To the extent permitted by applicable law, you: (i) consent to any infringement of; and (ii) agree to waive any right you have to be identified as the author of such content and any right you have to object to derogatory treatment of such content. You agree to perform all further acts necessary to perfect any of the above rights granted by you to Merger Magic, at our request.</p>
                <p >The name "Merger Magic" and all related names, logos, product and service names, designs, and slogans are trademarks of the company, “The FAS Solutions Ltd” or its affiliates or licensors, and you must not use such marks without our prior written permission. All other names, logos, product and service names, designs, and slogans on the website are the trademarks of their respective owners.</p>
                <p >You agree to indemnify Merger Magic for all claims brought by a third party against Merger Magic arising out of or in connection with the content and material you supply, except to the extent that any liability arises from our failure to remove the content or material.</p>
                <h3 >23. External Web Links</h3>
                <p >The provision of any third-party links on our website does not imply endorsement or assumption of responsibility for the content or practices of these external websites. Users are advised to carefully review the terms and privacy policies of these third-party websites before engaging with them. The website may contain links to resources that are not owned or controlled by us (for example, links to Facebook, Twitter, Pinterest, or Google Analytics). When you access these third-party services, you do so at your own risk. The third parties may require you to accept their own terms of use and privacy policies. Merger Magic is not a party to those agreements; they are exclusively between you and the third party.</p>
                <h3 >24. Disclaimer &amp; Limitation of Liability</h3>
                <h4 >24.1 No Warranty</h4>
                <p >While we diligently endeavor to ensure the accuracy and reliability of the content on our platform, users acknowledge and accept that they use our services entirely at their own risk. We do not warrant the accuracy or completeness of the information provided on our platform. Unless otherwise specified in writing, Merger Magic hereby disclaims, and does not make, any representation or warranty of any kind in respect of the Merger Magic Services, including, without limitation, any representation or warranty,</p>
                <p >a. The Website and Services are provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
                <p >b. We do not guarantee the accuracy, completeness, or reliability of any content on the Website, including listings, messages, or user profiles. You use the Website and its content at your own risk.</p>
                <p >c. We do not warrant that they are free of viruses or other harmful components.</p>
                <p >d. We do not guarantee that your use of the Merger Magic Services will be uninterrupted or error-free.</p>
                <p >e. We do not make any representations as to the suitability or availability of the Merger Magic Services.</p>
                <h4 >24.2 Limitation of Liability</h4>
                <p >To the fullest extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from</p>
                <p >(i) Your use or inability to use the Website or Services,</p>
                <p >(ii) Any unauthorized access to or use of our servers and/or any personal information stored therein,</p>
                <p >(iii) Any interruption or cessation of transmission to or from the Website,</p>
                <p >(iv) Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Website by any third party, or</p>
                <p >(v) Any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Website, whether based on warranty, contract, tort, or any other legal theory, and whether or not we have been advised of the possibility of such damages.</p>
                <p >Merger Magic will not be responsible for:</p>
                <p >Losses arising from the unavailability of, or your inability to use the Merger Magic Services.</p>
                <p >Losses that are not directly caused by any breach on our part.</p>
                <p >Any business loss, loss of sales, profits, revenue, contracts, anticipated savings, data, goodwill, or wasted expenditure.</p>
                <p >Any indirect or consequential losses.</p>
                <p >Any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause beyond our reasonable control.</p>
                <p >For any other loss relating to the Merger Magic Services, we limit our liability to the amount you have paid to us for the relevant Merger Magic Services.</p>
                <p >Nothing in these conditions is intended to:</p>
                <p >(i) Override any express commitments Merger Magic gives to you with respect to the Merger Magic Services (for example, the provision of a refund in certain circumstances) or</p>
                <p >(ii) Exclude, restrict, or modify any right or remedy you have in statute, including under the Australian Consumer Law or otherwise, to the extent that right or remedy cannot be excluded, restricted, or modified under the law. Any disclaimer, exclusion, or limitation in these conditions applies as provided for in these conditions to the full extent permitted by law and subject to any such non-excludable right or remedy.</p>
                <h4 >24.3 No Liability for Technologically Harmful Material</h4>
                <p >TO THE FULLEST EXTENT PROVIDED BY LAW, MERGER MAGIC WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY ANY TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ANY WEBSITE LINKED TO IT.</p>
                <h4 >24.4 No Liability for Specific Damages</h4>
                <p >TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS,</p>
                <h3 >25. Indemnity</h3>
                <p >By utilizing our services, users hereby agree to indemnify and hold us harmless from any claims, damages, or losses arising from their use of our platform. Users are fully responsible for any activities conducted under their account and are liable for any violations of our terms and policies. You hereby agree to indemnify and hold Merger Magic and its affiliates, as well as their officers, directors, employees, and agents, harmless from any and all claims, demands, losses, liabilities, and expenses (including legal fees) arising out of or in connection with:</p>
                <p >i. Your Use of the Website and Merger Magic Services: Including any assets obtained as a result thereof;</p>
                <p >ii. Your Breach or Violation of these Terms: Including any policies incorporated herein;</p>
                <p >iii. Merger Magic's Use of Your Content: In accordance with the terms outlined in our policies;</p>
                <p >iv. Your Violation of the Rights of Any Third Party: Including another seller or buyer.</p>
                <p >You further agree to hold Merger Magic, its principals, officers, directors, brokers, agents, servants, employees, and assigns, harmless from any misrepresentations made by you.</p>
                <h3 >26. Force Majeure</h3>
                <p >We cannot be held liable for any failure or delay in performing our obligations under unforeseen circumstances beyond our control, including but not limited to natural disasters, acts of God, or unforeseen regulatory changes. We will make every effort to minimize any impact on our users in such situations. You hereby agree that Merger Magic will not be liable or responsible for any failure in, or delay to, the provision of the Merger Magic Services or in Merger Magic complying with these Terms, where such failure or delay has arisen or is anticipated to arise as a direct or indirect result of:</p>
                <p >i. Force Majeure Events: Such as fire, earthquake, storm, flood, hurricane, inclement weather, or any other act of God, war, terrorism, explosion, sabotage, industrial accident, or an industrial strike;</p>
                <p >ii. Technological Failures: Including denial of service attacks, telecommunications failure, hardware failure, or the failure of software provided by a third party to function in accordance with its specifications;</p>
                <p >iii. Unusual Demand: Resulting in a failure of Merger Magic's software and hardware to function correctly;</p>
                <p >iv. Third-Party Failures: Including any failure of any third party, such as a bank or other financial organization, to fulfill any obligations to Merger Magic; or</p>
                <p >v. Other Circumstances Beyond Our Control: That prevent the fulfillment of our obligations.</p>
                <h3 >27. Governing Law and Jurisdiction</h3>
                <p >These Terms are governed by and construed in accordance with the laws of England and Wales. By using this Website, you expressly agree and submit to the exclusive jurisdiction of the courts located in England and Wales for the resolution of any disputes arising out of or related to these Terms or your use of the Website. Any dispute relating in any way to these Terms will only be adjudicated in the courts of England and Wales. Each party hereby consents to the exclusive jurisdiction and venue in these courts.</p>
                <p >Notwithstanding the foregoing, it is agreed that either party may seek injunctive relief in any state, federal, or national court of competent jurisdiction for any actual or alleged infringement of such party’s, its affiliates’, or any third party’s intellectual property or other proprietary rights. The United Nations Convention on Contracts for the International Sale of Goods, and any local laws implementing the Convention on Contracts for the International Sale of Goods, shall not apply to this Agreement.</p>
                <h3 >28. Notifications</h3>
                <p >All official communication or notices shall be delivered to users via email or conspicuously displayed notifications on our website. Users are responsible for regularly checking their emails and notifications for any updates or modifications to our terms and policies. Merger Magic may give notice by means of a general notice on the Website, electronic mail to the email address on your account, telephone, or text message to any phone number provided in connection with your account, or by written communication sent by mail or pre-paid post to any address connected with your account. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by mail or pre-paid post) or 12 hours after sending (if sent by email or telephone). You may give notice to Merger Magic, with such notice deemed given when received by Merger Magic, at any time by mail or pre-paid post to our registered agent for service of process, c/o The FAS Solutions Ltd.</p>
                <h3 >29. Transfer of Rights</h3>
                <p >Users shall not assign or transfer their rights and obligations under these terms without our prior written consent. Any attempt to do so without our consent will be considered null and void. Merger Magic retains the right to assign its rights and novate or transfer obligations that arise under these Terms. You must not assign, novate, or otherwise transfer your rights or obligations under these Terms without the prior written consent of Merger Magic (which may be withheld).</p>
                <h3 >30. Waiver of Rights</h3>
                <p >Our failure to enforce any provision of these terms shall not constitute a waiver of that provision or our rights to enforce it at a later time. We reserve the right to enforce any provision at our discretion. A provision of or a right created under these Terms may not be waived except in writing signed by the party or parties to be bound by the waiver. No single or partial exercise by any party of any right, power, or remedy shall preclude any other or further exercise of that or any other right, power, or remedy. The rights, powers, or remedies in these Terms are cumulative and not exclusive of any rights, powers, or remedies provided independently.</p>
                <h3 >31. Entirety of Agreement</h3>
                <p >These Terms supersede all prior representations, arrangements, understandings, and agreements between the parties relating to the subject matter and set forth the entire and exclusive agreement and understanding between the parties.</p>
                <h3 >32. Modifications and Accessibility</h3>
                <p >We retain the authority to effect changes or updates to these terms at any time. Users shall have access to the updated terms via our website. We encourage users to regularly review the terms to remain informed about any modifications or updates. We will notify you of any changes by updating the "Last Updated" date at the beginning of these Terms. Your continued use of the Website after any such changes constitutes your acceptance of the new Terms. We reserve the right to make changes to our website, these Terms, our policies, and our listings at any time in our sole discretion by posting the changes on our website. Your continued access to or use of the Website and Merger Magic Services following the posting of revised Terms will constitute acceptance of the revised Terms. We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable for any reason all or part of the Website or Merger Magic Services are unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website or the entire Website to users, including registered users.</p>
                <h3 >33. Severability Clause</h3>
                <p >Should any provision of these terms be deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect. The invalid provision shall be modified to the extent necessary to render it enforceable while preserving the original intent of the provision. If any of these Terms are judged invalid or unenforceable for any reason whatsoever by a court of competent jurisdiction, such invalidity or unenforceability (unless deletion of such provision would materially adversely affect one of the parties) shall not affect the operation or interpretation of any other term to the intent that the invalid or unenforceable term shall be treated as severed from the Terms.</p>
                <h3 >34. Contact Information</h3>
                <p >If you have any questions or concerns about these Terms, please contact us at info@mergermagic.com.</p>
                <p >By using the Website, you agree to these Terms and our Privacy Policy.</p>
                <p >Thank you for using Merger Magic.</p>			
            </section>
        </>
    )
}