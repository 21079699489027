

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../components";
import ListingService from "../../services/ListingService";


import { Card, Form, Button, Row, Col, InputGroup } from "react-bootstrap";

import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

export const UploadBulkListing = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [file, setFile] = useState(null);
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const handleFileChnge = (e) => {
        e.preventDefault();
        if (e.target.files.length) {
            setFile(e.target.files[0]);
        }
    }

    const onSubmit = data => {
        setSpinner(true)
        const formData = new FormData();
        formData.append('file', file);

        ListingService.uploadListing(formData)
        .then(() => {
            showToast({ severity: 'success',  detail: 'Listings are successfully imported.' })
            setSpinner(false);
            navigate(`/admin/listings`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    

    usePageTitle('Upload listing');


    return (

        <Card className="shadow-sm border-0 m-3 w-50 m-auto">
            <Card.Header className="border-0 h2">
                Bulk Upload
            </Card.Header>
            <Card.Body>
                <Form  onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col lg={12}>
                            <Form.Control type="file" id="cover-image" accept="xlsx" onChange={handleFileChnge} />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12} className="d-flex justify-content-between">
                            <Button className="my-4" type="button" variant="outline-secondary"  onClick={()=>{navigate('/admin/listings')}} >Cancel</Button>
                            <Button className="my-4" type="submit" color="primary" >Upload</Button>
                        </Col>
                    </Row>
                    

                    {spinner && (
                        <Spinner />
                    )}
                    
                </Form>
            </Card.Body>
        </Card>

        


    )
}