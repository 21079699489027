import { Outlet } from "react-router-dom";
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";

export const UserProfileLayout = () => {

    const [user, setUser] = useLocalStorage("user", {});

    const [info, setInfo] = useState(user?.user || null);

    useEffect(()=>{
        setInfo(user?.user)
    }, [user])



  return (
    <>
      <HeaderSection />
      <main className="main">
        <section className="main-content">

        <div className="container" data-aos="fade-up">
          <Outlet />
        </div>

        </section>
        
      </main>
      <FooterSection />
    </>

  );
};