import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DetailApp, DetailDomain, DetailWeb, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

export const DetailPage = () => {

    const {id} = useParams();
    const [listing, setListing] = useState(null);
    const [spinner, setSpinner] = useState(true);
    const [passwordCreate, setPasswordCreate] = useState(null)

    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const token = query.get('token');
    const expires = query.get('expires');
    

    useEffect(()=>{
        if (email && token && expires) {
            setPasswordCreate({email, token, expires, id})
        }
    }, [email, token, expires, id])

    useEffect(()=>{
        ListingService.show(id)
        .then(res => {
            setListing(res.data);
            setSpinner(false);
        })
        .catch(e => {
            setSpinner(false)
            console.log(e);
        })
    }, [id]);

    return (
        <section className="container">
            {spinner && (<Spinner />)}
            {listing && listing.type === 'website' && <DetailWeb createPassword={passwordCreate} listing={listing} />}
            {listing && listing.type === 'domain' && <DetailDomain createPassword={passwordCreate} listing={listing} />}
            {listing && listing.type === 'app' && <DetailApp createPassword={passwordCreate} listing={listing} />}
        </section>
    )
}