import { useEffect, useState } from "react";
import { Accordion, Badge, Card, Table } from "react-bootstrap";
import moment from"moment";


import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";


import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";
import { SellerBiddingDetail } from "../../components/SellerBiddingDetail";
import { ConfirmDialog } from "primereact/confirmdialog";

export const SellingPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage Listings');
    const [listings, setListings] = useState(null);
    const [code, setCode] = useState('');
    const [listing, setListing] = useState(null)
    const [spinner, setSpinner] = useState(null);

    useEffect(()=> {
        ListingService.completed()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Active Listings

                <Link className="btn btn-sm btn-success float-right " to="/user/drafted">Drafted</Link>
                <Link className="btn btn-sm btn-success float-right mx-3" to="/user/listings/type">Post a Listing</Link>
            </Card.Header>
            <Card.Body>

                <Accordion defaultActiveKey={0}>
                    {listings && listings.data.map((item, i) => (
                        <Accordion.Item key={i} eventKey={i}>
                            <Accordion.Header>
                            
                                <div className="d-flex justify-content-between w-75">
                                    <div>
                                        <Badge bg="success" className="m-1 ">{item.type}</Badge> {item.website_business_name}
                                    </div>
                                    
                                    <div>
                                        {item.status === 1 && <Badge bg="success" className="m-1">Active</Badge>} Expiring { moment(item.expire_date, "YYYYMMDDh").fromNow() }
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <h5 className="text-dark">Offers</h5>
                                <SellerBiddingDetail data={item.bids} />
                            </Accordion.Body>
                        </Accordion.Item>
                        
                    ))}
                </Accordion>
            </Card.Body>
            {spinner && <Spinner />}

            <ConfirmDialog />
        </Card>
    );
}