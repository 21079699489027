
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Spinner } from "../../components";

export const SellerLocationPage = () => {
	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const {  handleSubmit  } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);
	const animatedComponents = makeAnimated();
	const [countries, setCountries] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);

	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			const userCountries = user.data.countries.map(c => ({
				value: c.id,
				label: c.name
			}));
			setSelectedOptions(userCountries);
		})
		.catch(e => {
			console.log(e);
		})

		AuthService.countries()
		.then(res => {
			const allCountries = res.data.map(country => ({
				label: country.name,
				value: country.id
			}));
			setCountries(allCountries);
		})
		.catch(e => {
			console.log(e);
		})
	}, [id])

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		const location = [];
		selectedOptions.map(c => (location.push(c.value)));
		AuthService.addInfo(id, {location: location, info:1})
		.then(async () => {
			setSpinner(false)
			navigate('/profile/user-detail/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	const handleChange = (selected) => {
		setSelectedOptions(selected || []);
	};

	
	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={72} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-5">
							<div className="h3 text-center">Do you have a preferred seller location?</div>
						</div>

						<div className="form-group">
							<Select
								closeMenuOnSelect={false}
								components={animatedComponents}
								value={selectedOptions}
								isMulti
								options={countries}
								onChange={handleChange}
								menuPortalTarget={document.body}
								isSearchable={true}
								placeholder="Select you preferred loctions"
							/>
						</div>

						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/deal-range/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
