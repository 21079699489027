import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import ReactQuill from 'react-quill';


import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col, InputGroup, ListGroup } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";


export const WebsiteDescription = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [description, setDescription] = useState(null);
    const [days, setDays] = useState([])
    const [step, setStep] = useState(7);
    const [title, setTitle] = useState('Description');
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();


    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data.description = description;
        data.step = step;
        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/social/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setDescription(res.description);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name + ' - Description' )
            
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

        const d = [];
        for(let i=1; i <= 30; i++) {
            d.push(i);
        }

        setDays(d);

    }, []);

    usePageTitle(title);

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step} current={7} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Description
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row className="my-3">
                                <Col lg={12}>
                                    <Form.Label >Tell us about your business so potential buyers get excited. What does your business do?</Form.Label>
                                    <ReactQuill theme="snow"   
                                        value={description} onChange={(e)=>{
                                            setDescription(e)
                                        }}    
                                    />
                                    {(errors.description) && <ErrorLabel >Description is required</ErrorLabel>}
                                </Col>
                                
                            </Row>

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/meta/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
    )
}