import { Col, Row } from "react-bootstrap";
import Step1 from '../assets/img/step-1.png';
import Step2 from '../assets/img/step-2.png';
import Step3 from '../assets/img/step-3.png';

export const StepToSuccess = () => (
    <div className="container">
        <Row>
            <Col className="text-center">
                <h1>The Steps to Success </h1>
                <p className="text-secondary">Unveil the Magic: Here is How! </p>
            </Col>
        </Row>
        <Row>
            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-5">
                    <span className="process-img">
                        <img className="img-fluid" src={Step1} />
                        <span className="process-num">01</span>
                    </span>
                    

                    <h4>Create An Account</h4>
                    <p>Instant Matchmaking - Your Domain, Their Dream, Our Expertise!</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-5">
                    <span className="process-img">
                        <img className="img-fluid" src={Step2} />
                        <span className="process-num">02</span>
                    </span>
                    

                    <h4>Create a Listing</h4>
                    <p>List a Domain Or a Website to Receive Best Offers & Bids instantly</p>
                </div>
            </Col>

            <Col md={4} sm={4}>
                <div className="working-process p-3 mx-5">
                    <span className="process-img">
                        <img className="img-fluid" src={Step3} />
                        <span className="process-num">03</span>
                    </span>
                    

                    <h4>Select the Buyer</h4>
                    <p>You're in Control - Choose the Best Offer, Seal the Deal!</p>
                </div>
            </Col>
        </Row>
    </div>
)