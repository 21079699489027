import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";



import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";


import { usePageTitle } from "../../hooks/usePageTitle";

import { Card, Form, Button, Row, Col, InputGroup, ListGroup } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";

import { useGoogleLogin } from '@react-oauth/google';
import { useLocalStorage } from "../../hooks/useLocalStorage";

import analyticIcon from '../../assets/svgs/analytics.svg';
import { useToast } from "../../hooks/ToastContext";


export const WebsiteIntegrations = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(5);
    const [title, setTitle] = useState('Integration');
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data.step = step;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/meta/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name + ' - Integrations');
        })
        .catch(e => {
            console.log(e);
        })        

    }, []);
    usePageTitle(title);


    const googleLogin = useGoogleLogin( {
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      responseType: 'token',
      access_type: 'offline',
      scope: 'openid https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.edit',
      prompt: 'consent',
      onSuccess: async ( tokenResponse ) => {
        console.log(tokenResponse)
        const accessToken = tokenResponse?.access_token;
        if ( accessToken ) {
          localStorage.setItem('google-token', accessToken);
          navigate('/user/listings/google-accounts/' + id)
        }
      },
      onError: ( error ) => {
        console.error( error );
      }
    });

    
  

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step}  current={5} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Integrations
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            
                            <Row>
                                <Col>

                                    <div className="card-box p-3 text-center">
                                      <img src={analyticIcon} className="img-fluid img-icon" />
                                      <h5 className="py-3">Google Analytics</h5>
                                      <Button variant="outline-primary" onClick={ googleLogin }>Connect</Button>
                                    </div>
                                    
                                
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/monetization/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}