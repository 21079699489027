

import moment from "moment"
import useLocalTime from "../../hooks/useLocalTime"

export const ChatBoxLeft = ({data}) => {
    const created = useLocalTime(data.created_at);
    return(
        <div className="chat-message-left pb-4">
            
            <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3">
                <div className="font-weight-bold mb-1"><strong>{data.sender.name}</strong> <span className="text-muted">{ moment(created, "YYYYMMDDh").fromNow() }</span></div>
                {data.message}
            </div>
        </div>
    )
}

