import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";


import { Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { DomainLinks } from "./DomainLink";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

export const DomainCover = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(5);
    const [title, setTitle] = useState('Domain listing');
    const [coverImage, setCoverImage] = useState(null);
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const handleCoverImage = (e) => {
        e.preventDefault();
        if (e.target.files.length) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            ListingService.upload(formData)
            .then(file => {
                if (file.data && file.data.path) {
                    setCoverImage(file.data.path);
                }
            })
            .catch(e => {
                showToast({ severity: 'error',  detail: e.response?.data?.message })
            });
        }
    }

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'domain';
        data.step = step;
        data.cover = coverImage;

        ListingService.update(id, data)
        .then(() => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/sell-type/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Domain cover image' : 'Domain cover image');
            setCoverImage(res.data.cover);
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })

    }, []);

    usePageTitle(title);


    return (

        <Row>
            <Col lg={3}>
                <DomainLinks step={step} id={id} current={5} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Cover
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Label >Cover Image </Form.Label>
                                    <Form.Control type="file" id="cover-image" accept="image/*" onChange={handleCoverImage} />
                                    
                                    {coverImage && <><img src={coverImage} className="img-fluid p-3" /> <br/></>}
                                    
                                    <small><strong>Listing Image:</strong> An eye-catching image goes a long way. Upload a photograph of your site, product, or service (prefer 550px x 300px)</small>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/domain-description/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        


    )
}