import { NavLink } from "react-router-dom";
import { useLocalStorage } from '../hooks/useLocalStorage'
import { FaBars, FaCaretDown, FaRegUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import ListingService from "../services/ListingService";
import { CategoryLink } from "../components/CategoryLink";
import { LogoutButton } from "./LogoutButton";

export const Navigation = () => {

    const [user] = useLocalStorage("user", {});
    const [categories, setCategories] = useState([]);
    const [info, setInfo] = useState(user?.user || null);

    useEffect(()=>{
        setInfo(user?.user)
    }, [user])


    useEffect(() => {
        ListingService.categories()
        .then(res => {
            setCategories(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, [])

    const handleMenu = () => {
        document.querySelector('body')?.classList.toggle('mobile-nav-active')
    }

    return (
        <nav id="navmenu" className="navmenu">
            <ul>
                <li><NavLink to="/" className="">Home</NavLink></li>
                
                <li className="dropdown">
                    <a><span>Browse</span> <FaCaretDown className="toggle-dropdown" /> </a>
                    <ul>
                        {categories && categories.map(category => (
                                <li key={category.id}><CategoryLink  category={category} /></li>
                            )
                        )}
                        
                    </ul>
                </li>
                <li><NavLink to="/contact-us">Contact</NavLink></li>
                {!info && (
                    <li><NavLink to="/login" >Login</NavLink></li>
                )
                }

                {info && !info.is_admin && (
                    <li className="dropdown">
                        <a><span><FaRegUserCircle /> Account</span> <FaCaretDown className="toggle-dropdown" /> </a>
                        <ul>
                            <li><NavLink to={`/user/dashboard`}>Dashboard</NavLink></li>
                            <li><NavLink to={`/user/conversations`}>Messages</NavLink></li>
                            <li><NavLink to={`/user/watchlist`}>Watching</NavLink></li>
                            <li><NavLink to={`/user/selling`}>Selling</NavLink></li>
                            <li><NavLink to={`/user/buying`}>Buying</NavLink></li>
                            <li><NavLink to={`/user/profile`}>Profile</NavLink></li>
                            <li><LogoutButton /></li>
                        </ul>
                    </li>
                    )
                }

                {info && info.is_admin && (
                    <li className="dropdown">
                        <a><span><FaRegUserCircle /> Admin</span> <FaCaretDown className="toggle-dropdown" /> </a>
                        <ul>
                            <li><NavLink to={`/admin/dashboard`}>Dashboard</NavLink></li>
                            <li><NavLink to={`/admin/listings`}>Listings</NavLink></li>
                            <li><NavLink to={`/admin/users`}>Users</NavLink></li>
                            <li><NavLink to={`/admin/packages`}>Packages</NavLink></li>
                            <li><NavLink to={`/admin/pages`}>Pages</NavLink></li>
                            <li><LogoutButton /></li>
                        </ul>
                    </li>
                    )
                }
            </ul>
            

            
            <FaBars onClick={handleMenu} className="mobile-nav-toggle d-xl-none" />
        </nav>
    )
}