import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ErrorLabel, Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, Form } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";
import data from '../../assets/data/countries.json'

import { useNavigate, useParams } from "react-router-dom";





export const UserInfoPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
    const navigate = useNavigate();
    const {id} = useParams();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [spinner, setSpinner] = useState(false);
    const [serverError, setServerError] = useState([]);
    const [countries, setCountries] = useState([]);
    const [sources, setSources ] = useState([]); 
    
    const onSubmit = data => {
        setServerError([])
        setSpinner(true)
        data['info'] = '1';
        AuthService.addInfo(id, data)
        .then(async (res) => {
            setSpinner(false)
            if (res.data.type === 'buyer') {
                navigate('/profile/welcome/' + id);
            } else {
                navigate('/user/listings/type');
            }
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    useEffect(() => {
        setCountries(data);
        setSources(AuthService.sources())
    }, [])

    useEffect(() => {
        reset({
            country: 'United Kingdom',
            source: ''
        },
            
        )
    }, [reset])
   

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        { serverError && serverError.map((e, i) => (
                            <p key={i} className="text-danger">{e}</p>
                        ))}
                        
                        <div className="form-group my-3">
                            <label >How do you think you'll use {process.env.REACT_APP_SITE_NAME}?</label>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <input id="buyer" type="radio" value={'buyer'}
                                            {...register('type', {
                                                required: true
                                            })} 
                                    />
                                    <label className="ml-2 strong" htmlFor="buyer">I am looking to buy</label>
                                </div>

                                <div>
                                    <input id="seller" type="radio" value={'seller'}
                                            {...register('type', {
                                                required: true
                                            })} 
                                    />
                                    <label className="ml-2 strong" htmlFor="seller">I am looking to sell</label>
                                </div>
                            </div>

                            {(errors.type ) && <div className='text-danger'>You must select an option</div>}
                            
                        </div>
                        
                        <div className="form-group my-3">
                            <label htmlFor="company">Company</label>
                            <input type="text" 
                                    className="form-control" 
                                    id="company" 
                                    placeholder="Optional"
                                    {...register('comapny')} 
                            />                            
                        </div>

                        <div className="form-group my-3">
                            <Form.Label>Where are you located?</Form.Label>
                            <Form.Select 
                                {...register('country', {
                                    required: true,
                                })}
                                name="country"
                                defaultValue={'US'}
                                >
                                    {countries.length && countries.map(country => (
                                    
                                        <option key={country.code} value={country.name}>{country.name}</option>
                                    ))}
                                    
                            </Form.Select>
                            {(errors.country) && <ErrorLabel >Select your location</ErrorLabel>}
                        </div>

                        <div className="form-group my-3">
                            <label htmlFor="phone">Phone number</label>
                            <input type="text" 
                                    className="form-control" 
                                    id="phone" 
                                    placeholder="Phone number"
                                    {...register('phone', {required: true})} 
                            />
                            {(errors.phone) && <ErrorLabel >Enter your phone number</ErrorLabel>}                       
                        </div>

                        <div className="form-group my-3">
                            <Form.Label htmlFor="source">How did you hear about {process.env.REACT_APP_SITE_NAME}?</Form.Label>
                            <Form.Select 
                                {...register('source', {required: true})}
                                name="source"
                                
                                >
                                    {sources.length && sources.map((source, i) => (
                                    
                                        <option key={i} value={source}>{source}</option>
                                    ))}
                                    
                            </Form.Select>
                            {(errors.source) && <ErrorLabel >Select an option</ErrorLabel>}
        
                        </div>
                    
                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Create my account</button>
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}