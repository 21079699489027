import { ListGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

export const ProfitMultiple = ({item}) => {
    const profit = (parseFloat(item.annual_profit,2) / parseFloat(item.buy_now_price,2) * 12 ).toFixed(1);
    const revenue = (parseFloat(item.last_12months_revenue, 2) / parseFloat(item.buy_now_price,2) * 12 ).toFixed(1);
    return (
        <div className=" d-flex justify-content-between">

            <div>

                <ListGroup horizontal>
                    <ListGroup.Item>Multiple</ListGroup.Item>
                    <ListGroup.Item>{profit}x Profit</ListGroup.Item>
                    <ListGroup.Item>{revenue}x Revenue</ListGroup.Item>
                </ListGroup>

            </div>
            
            

            <div>
                <ListGroup horizontal>
                    <ListGroup.Item><FaEye /> Views</ListGroup.Item>
                    <ListGroup.Item>{item.views}</ListGroup.Item>
                </ListGroup>
            </div>
        </div>
    )
}