
import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";


export const VerifyEmailPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Verify Email');
    const [spinner, setSpinner] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [resend, setResend] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const url = queryParams.get('verify_url'); 

    const [user, setUser] = useLocalStorage("user", {});
    const info = user?.user; 

    useEffect(()=> {
        AuthService.verifyEmail(url)
        .then((res) => {
            setSpinner(false);
            if (info) {
                user['user']['email_verified_at'] = new Date();
            }
            localStorage.setItem('user', JSON.stringify(user));
            setSuccess('Your email is successfully verified');
        })
        .catch(e => {
            setSpinner(false);
            setError(e.response?.data?.message)
        })
    }, [url]);

    const handleResend = () => {
        setSpinner(true);
        setResend(null);
        AuthService.resend()
        .then((res) => {
            setSpinner(false);
            setResend('A new email is sent, please follow instruction to verify yuor email');
        })
        .catch(e => {
            setSpinner(false);
            setResend(null);
            setError(e.response?.data?.message)
        })
    }
    

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <h1 className="h3 mb-3 fw-normal">Verifying your email</h1>

                    {error && (
                        <>
                            <div className="text-danger">{error}</div>
                            <button onClick={()=>handleResend()} className="btn btn-primary my-2">Resend</button>
                        </>
                        
                    )}

                    {success && (
                        <>
                            <div className="text-success">{success}</div>
                            <p className="py-3">Click <Link  to="/user/dashboard">here</Link> to visit your account.</p>
                        </>
                    )}

                    {resend && (
                        <>
                            <div className="text-success">{resend}</div>
                        </>
                    )}
                </CardBody>
            </Card>
            
            
        </div>
    );
}