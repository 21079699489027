import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";


import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col, InputGroup } from "react-bootstrap";
import { DomainLinks } from "./DomainLink";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

export const DomainMeta = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(3);
    const [title, setTitle] = useState('Meta Information');
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'domain';
        data.step = step;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/domain-description/${id}`);
        })
        .catch(e =>{
            setSpinner(false);
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Meta Information' : 'Meta Information');
        })
        .catch(e => {
            console.log(e);
        })

    }, []);

    usePageTitle(title);


    return (

        <Row>
            <Col lg={3}>
                <DomainLinks step={step} id={id} current={3} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        More about your business
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Label >Tagline</Form.Label>
                                    <Form.Control type="text" size="lg"
                                        {...register('tagline', {
                                            required: true,
                                        })}
                                    />
                                    {(errors.tagline) && <ErrorLabel >Tagline is required</ErrorLabel>}
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col lg={6}>
                                    <Form.Label >Meta Description of the Domain</Form.Label>
                                    <Form.Control as="textarea" size="lg"
                                        {...register('meta_description', {
                                            required: true,
                                        })}
                                    />
                                    {(errors.meta_description) && <ErrorLabel >Meta description is required</ErrorLabel>}
                                </Col>
                                <Col lg={6}>
                                    <Form.Label >Meta Keywords</Form.Label>
                                    <Form.Control as="textarea"  size="lg"
                                        {...register('meta_keywords', {
                                            required: true,
                                        })}
                                    />
                                    {(errors.meta_keywords) && <ErrorLabel >Meta is required</ErrorLabel>}
                                </Col>
                            </Row>


                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/domain-business/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        


    )
}