
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ErrorLabel, Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const DealRangePage = () => {
	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				deal_range_min: user.data.deal_range_min || null,
				deal_range_max: user.data.deal_range_max || null,
				require_financing: user.data.require_financing  ?  'Yes' : 'No',
			})
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		data['require_financing'] = data.require_financing === 'Yes' ? 1 : 0
		
		AuthService.addInfo(id, data)
		.then(async () => {
			setSpinner(false)
			navigate('/profile/seller-location/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={63} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-5">
							<div className="h3 text-center">What is your preferred deal size?</div>
						</div>

						<div className="row g-0">
							<div className="col"><label htmlFor="acquisition_budget">Deal Size Range	</label></div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<div className="form-group">
									<input type="text" className="form-control"
										name="deal_range_min" id="deal_range_min"
										placeholder="Minimum value of your deal"
										{...register('deal_range_min', {
											required: true,
											pattern: {
												value: /^(0|[1-9][0-9]*)$/,
												message: 'Enter a valid number'
											}
									})} /> 
								
									{(errors.deal_range_min && errors.deal_range_min.type==='required') && <ErrorLabel >Please provide an amount</ErrorLabel>}
									{(errors.deal_range_min?.message) && <ErrorLabel >{errors.deal_range_min?.message}</ErrorLabel>}
								</div>
								
							</div>

							<div className="col-lg-6">
								<div className="form-group">
									<input type="text" className="form-control"
										name="deal_range_max" id="deal_range_max"
										placeholder="Maximum value of your deal"
										{...register('deal_range_max', {
											required: true,
											pattern: {
												value: /^(0|[1-9][0-9]*)$/,
												message: 'Enter a valid number'
											}
									})} /> 
								
									{(errors.deal_range_max && errors.deal_range_max.type==='required') && <ErrorLabel >Please provide an amount</ErrorLabel>}
									{(errors.deal_range_max?.message) && <ErrorLabel >{errors.deal_range_max?.message}</ErrorLabel>}
								</div>
								
							</div>
						</div>

						<div className="form-group my-4">
							<label className="strong">For your preferred deal size, will you require financing? </label>
							<div className="d-flex">
								<div>
									<input type="radio" 
										className="mr-1" id="finance_yes" 
										name="require_financing" value="Yes"
										{...register('require_financing')}/> <label htmlFor="finance_yes">Yes</label>
								</div>
								<div className="mx-5">
									<input type="radio" 
										className="mr-1" id="finance_no" 
										name="require_financing" value="No"
										{...register('require_financing')}/> <label htmlFor="finance_no">No</label>
								</div>
							</div>
						</div>


						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/how-many-business/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
