
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ErrorLabel, Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const BudgetPage = () => {
	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				acquisition_budget: user.data.acquisition_budget || null
			})
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/deal-range/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={54} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-5">
							<div className="h3 text-center">What is your overall acquisition budget?</div>

						</div>
						<div className="form-group my-4">
							<label htmlFor="acquisition_budget">Overall budget</label>
							<input type="text" className="form-control"
								name="acquisition_budget" id="acquisition_budget"
                                {...register('acquisition_budget', {
                                    required: true,
                                    pattern: {
                                        value: /^(0|[1-9][0-9]*)$/,
                                        message: 'Enter a valid number'
                                    }
                            })} /> 
						
							{(errors.acquisition_budget && errors.acquisition_budget.type==='required') && <ErrorLabel >Please provide an amount</ErrorLabel>}
							{(errors.acquisition_budget?.message) && <ErrorLabel >{errors.acquisition_budget?.message}</ErrorLabel>}
						</div>


						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/how-many-business/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
