
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";
import numbers from '../../assets/data/numbers.json'

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const HowManyBusinessPage = () => {
	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				how_many_business_to_buy: user.data.how_many_business_to_buy
			})
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/budget/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={45} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group ">
							<label className="h3 w-100 text-center my-5">What is your overall acquisition budget?</label>
							{numbers && numbers.map((item, i) => (
								<div className="py-2 " key={i}>
									<label htmlFor={`why-${i}`}>
										<input name="how_many_business_to_buy" 
												id={`why-${i}`} className="mr-1" 
												type="radio" value={item} 
												{...register('how_many_business_to_buy')} /> { item }
									</label>	
								</div>
							))}
							{(errors.type ) && <div className='text-danger'>You must select an option</div>}
						</div>


						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/acquire-detail/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
