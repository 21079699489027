import { Col, Row } from "react-bootstrap";
import { ListingAttribute } from './ListingAttribute';

import { useLocalStorage } from "../hooks/useLocalStorage";
import { BlurInfo } from "./BlurInfo";
import { MoreLikeThis } from "./MoreLikeThis";
import { ListingFinance } from "./ListingFinance";
import { DomainAnalytics } from "./DomainAnalytics";

export const DetailDomain = ({listing}) => {

    const [user, setUser] = useLocalStorage("user", {});

    return (
        <Row>
            <Col lg={8}>
                <h1>{listing.website_business_name}</h1>

                <Row className="my-4">
                    <Col className="d-flex justify-content-start text-secondary">
                        <div className="px-2 border-right">
                            Type
                            <ListingAttribute data={listing.type} />
                        </div>

                        {listing.category && (
                            <div className="px-2 border-right">
                                Category
                                <ListingAttribute data={listing.category.name} />
                            </div>
                        ) }

                        
                        <div className="px-2 border-right">
                            Age
                            <ListingAttribute data={`${listing.age} years`} />
                        </div>

                        <div className="px-2 border-right">
                            Views
                            <ListingAttribute data={`${listing.views}`} />
                        </div>

                        
                        

                    </Col>
                </Row>


                <div>{listing.tagline}</div>
                
                <hr/>
                
                {!user || !user.token ? ( <BlurInfo />) : (
                    <DomainAnalytics item={listing} />
                )}
            </Col>
            <Col lg={4}>
                <ListingFinance item={listing} />

                {listing.category && listing.category.id && <MoreLikeThis id={listing.category.id} listingId={listing.id} />}
                
            </Col>
            
        </Row>

        
    )
    
}
