import { Button, Col, Row } from "react-bootstrap";
import { ListingAttribute } from './ListingAttribute';
import { ListingMonetization } from './ListingMonetization';
import { ListingNetProfit } from './ListingNetProfit';
import { ProfitMultiple } from './ProfitMultiple';
import { ProfitMargin } from './ProfitMargin';
import { useLocalStorage } from "../hooks/useLocalStorage";
import { BlurInfo } from "./BlurInfo";
import { WebAnalytics } from "./WebAnalytics";
import { MoreLikeThis } from "./MoreLikeThis";
import { ListingFinance } from "./ListingFinance";
import { ActivateListingComponent } from "./ActivateListingComponent";

export const DetailWeb = ({listing, createPassword}) => {
    const [user, setUser] = useLocalStorage("user", {});

    return (
        <Row>
            <Col lg={8}>
                <h1>{listing.website_business_name}</h1>
                <div>{listing.tagline}</div>
                <Row className="my-4">
                    <Col className="listing-attributes justify-content-between text-secondary">
                        <div className="px-2 border-right">
                            Type
                            <ListingAttribute data={listing.type} />
                        </div>

                        {listing.category && (
                            <div className="px-2 border-right">
                                Category
                                <ListingAttribute data={listing.category.name} />
                            </div>
                        ) }

                        {listing.methods && (
                            <div className="px-2 border-right">
                                Monetization
                            <ListingMonetization methods={listing.methods} />
                            </div>
                        ) }

                        <div className="px-2 border-right">
                            Age
                            <ListingAttribute data={`${listing.age} years`} />
                        </div>

                        <div className="px-2 border-right">
                            Net Profit
                            <ListingNetProfit anual={listing.annual_profit} />
                        </div>

                        <div className="px-2 ">
                            Profit Margin
                            <ProfitMargin listing={listing} />
                        </div>
                        

                    </Col>
                </Row>

                <Row>
                    <Col className='py-3'>
                        <ProfitMultiple item={listing} />
                    </Col>
                </Row>

                
                
                <hr/>
                
                {!user || !user.token ? ( <BlurInfo />) : (
                    <WebAnalytics item={listing} />
                )}
            </Col>
            <Col lg={4}>

                {createPassword &&
                    <ActivateListingComponent user={user} listing={listing} createPassword={createPassword} />
                }
                <ListingFinance item={listing} />

                {listing.category && listing.category.id && <MoreLikeThis id={listing.category.id} listingId={listing.id} />}
                
            </Col>
            
        </Row>

        
    )
    
}
