import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, Form } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useToast } from "../../hooks/ToastContext";




export const ResetPasswordPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Reset Password');
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const showToast = useToast();
    const [spinner, setSpinner] = useState(false);
    const { token } = useParams();
    const location = useLocation();
    const [email, setEmail] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const emailFromQuery = searchParams.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }
    }, []);
    
    
    const onSubmit = data => {
        
        const payload = {
            token,
            password: data.password,
            password_confirmation: data.cpassword,
            email
        }

        setSpinner(true)
        AuthService.reset(payload)
        .then(async (res) => {
            setSpinner(false)
            showToast({ severity: 'success', detail: res.data?.message })
            navigate('/login')
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.email || 'Something went wrong, please try again' })
        });
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="h3 mb-3 fw-normal">Create a password</h1>                        
                        
                        <div className="form-group mt-3">
                            <label>New Password</label>
                            <input type="password" 
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', {required: true, 
                                        maxLength: 100,
                                        minLength: {
                                            value: 6,
                                            message: 'Password must be at least 6 character long'
                                        }})} 
                            />

                            {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                            {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                        </div>

                        <div className="form-group mt-3">
                            <label>Confirm Password</label>
                            <input type="password" 
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    {...register('cpassword', {required: true, 
                                        validate: (value) => value === watch("password") || "Password do not match"
                                    })} 
                            />

                            {(errors.cpassword && errors.cpassword.type==='required') && <div className='text-danger'>Confirm Password is required</div>}
                            {(errors.cpassword?.message) && <div className='text-danger'>{errors.cpassword?.message}</div>}
                        </div>

                    
                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Submit</button>
                        
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}