import { Link } from "react-router-dom";
import Logo from '../assets/img/logo-white.png';

import { useEffect } from "react";

import { Navigation } from "./Navigation";
import { TopHeader } from "./TopHeader";

export const HeaderSection = () => {

    const handleMenu = () => {
        document.querySelector('body')?.classList.toggle('mobile-nav-active')
    }

    const toggleScrolled = () => {
        const selectBody = document.querySelector('body');
        const selectHeader = document.querySelector('#header');

        if (!selectHeader) return;

        if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
        window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    }


    useEffect(() => {

        document.querySelector('body')?.classList.remove('index-page')
        document.querySelector('body')?.classList.add('starter-page-page')


        document.querySelectorAll('#navmenu a').forEach(navmenu => {
            navmenu.addEventListener('click', () => {
                if (document.querySelector('.mobile-nav-active')) {
                    handleMenu();
                }
            });
        });


        document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
            navmenu.addEventListener('click', function(e) {
              if (document.querySelector('.mobile-nav-active')) {
                e.preventDefault();
                this.parentNode.classList.toggle('active');
                this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
                e.stopImmediatePropagation();
              }
            });
        });

        document.addEventListener('scroll', toggleScrolled);
        window.addEventListener('load', toggleScrolled);

    }, []);

    return (
        <header id="header" className="header  sticky-top">
            <TopHeader />
            <div className="p-2 container-fluid container-xl position-relative d-flex align-items-center">
                <Link to="/" className="logo d-flex align-items-center me-auto">
                    <img src={Logo} alt="" />
                    <h3 className="sitename m-0 d-none d-lg-block">Merger Magic</h3>
                </Link>
                <Navigation />
                
                <Link to="/user/listings/type"  className="btn btn-outline-light order-2 mx-3" >Start Selling</Link>
            </div>
        </header>
    );
}