import axios from "axios";

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND}/api/`,
    headers: {
        "Content-Type": "application/json"
    }
});

instance.interceptors.request.use((config) => {

    const storage = localStorage.getItem('user');

    if (storage === 'undefined') {
        return config;
    }

    const user = JSON.parse(localStorage.getItem('user') || '{}') ;
    if (user && user !== 'undefined' && user.token) {
        config.headers.Authorization = `Bearer ${user.token}`
    }
    return config;
});

export default instance;