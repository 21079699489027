import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";
import GoogleService from '../../services/GoogleService';


import { usePageTitle } from "../../hooks/usePageTitle";

import { Card, Form, Button, Row, Col, InputGroup, ListGroup, Alert } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";



export const IntegrationAccount = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(5);
    const [title, setTitle] = useState('Integration');
    const [accounts, setAccounts] = useState([]);
    const [properties, setProperties] = useState([]);
    const [current, setCurrent] = useState(null);
   
    const { register, handleSubmit, formState: { errors}   } = useForm();

    

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name + ' - Integrations');
        })
        .catch(e => {
            console.log(e);
        });
        
        GoogleService.accounts()
        .then(res => {
            setAccounts(res.accounts);
        })
        .catch(e => console.log(e));

    }, []);
    usePageTitle(title);

    const onSubmit = data => {
      console.log(data);  
    }

    const handleChange = data => {
      setCurrent(data);
      const startDate = '2020-01-01';
      const endDate = '2024-12-31';
      const metrics = [{ name: 'activeUsers' }];
      const dimensions = [{ name: 'country' }];

      const requestBody = {
        dateRanges: [{ startDate, endDate }],
        metrics,
        dimensions,
      };

      setProperties([]);
      setSpinner(true)
      GoogleService.properties(data.name)
      .then(res => {
        setSpinner(false)
        if (res && res.properties) {
          setProperties(res.properties)
        }
      })
      .catch(e => {
        console.log(e)
        setSpinner(false)
      })


    }



    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Select Account to proceed!
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            
                            <Row>
                                <Col>

                                    <ListGroup variant="flush">
                                      {accounts && accounts.map((account, index) => (
                                        <ListGroup.Item key={index}>
                                          <Form.Check
                                            {...register('account', {required: true})}
                                            name="account"
                                            type="radio"
                                            id={`account-${account.name}`}
                                            label={account.displayName}
                                            value={account.name}
                                            onChange={() => handleChange(account)}
                                          />

                                          {current && current.name === account.name && (
                                            <>
                                            {properties && properties.map((property, i) => (
                                              <div key={i} className="d-flex justify-content-between">
                                                
                                                <div>
                                                  {property.displayName}
                                                </div>
                                                <div>
                                                  {property.industryCategory}
                                                </div>
                                                <div>
                                                  {property.name}
                                                </div>
                                              </div>
                                              ))}

                                              {!properties.length && !spinner && (
                                                <span>No property is listed!</span>
                                              )}
                                            </>
                                            
                                          )}
                                          
                                        </ListGroup.Item>
                                      ))}
                                    </ListGroup>
                                    {(errors.account && errors.account.type==='required') && <ErrorLabel >You must select an account to proceed</ErrorLabel>}
                                
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/integration/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        
    )
}