import { Outlet } from "react-router-dom";
import { HeaderSection } from './HeaderSection';
import { FooterSection } from './FooterSection';

export const PublicLayout = () => {
  return (
    <>
      <HeaderSection />
      <Outlet />
      <FooterSection />
    </>

  );
};
