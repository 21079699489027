import { Alert, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import ListingService from "../services/ListingService"
import { useToast } from "../hooks/ToastContext";


export const ContactSeller = ({listing, info, handleShow}) => {

    const navigate = useNavigate();
    const showToast = useToast();
    
    const handleContact = () => {
        if (!info) {
            handleShow(true);
            return;
        }

        ListingService.conversationStart(listing.id, listing.user.id)
        .then((res) => {
            navigate(`/user/conversation/${res.data.id}/${listing.user.id}/${listing.id}`);
        })
        .catch(e => showToast({ severity: 'error',  detail: e.response?.data?.message }));
        
    }
    
    return (
        <>
            {info && info.id ===  listing.user_id 
            ? (<Alert variant="info">You are the owner of this listing</Alert>) 
            : (<Button variant="primary"  onClick={()=>handleContact()} size="lg" type="button">Contact Seller</Button>)}
            
        </>
    )
}