import { useEffect, useState } from "react";
import { Badge, Card, Table } from "react-bootstrap";
import moment from"moment";

import { Link } from "react-router-dom";

import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";

export const AuctionPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage Auction');
    const [listings, setListings] = useState(null);
    const [code, setCode] = useState('');
    const [listing, setListing] = useState(null)
    const [spinner, setSpinner] = useState(null);
    const showToast = useToast();

    useEffect(()=> {
        ListingService.listing()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })
    }, []);

    

    const saveChanges = () => {
        setSpinner(true);
        const payload = {
            type: listing.type,
            google_tracking_id: code
        }

        ListingService.update(listing.id, payload)
        .then(() => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0">
                Manage Auctions
                <Link className="btn btn-sm btn-success float-right" to="/user/listings/type">Post a Listing</Link>
            </Card.Header>
            <Card.Body>
            
                <Table>
                    <tbody>
                        {listings && listings.data.map(item => {
                            return(
                                <tr key={item.id}>
                                    <td>
                                        <strong>{item.website_business_name}</strong>
                                        <div>
                                            <Badge className="m-1">{item.type}</Badge>
                                            {item.status === 1 && <Badge className="m-1">Active</Badge>}
                                        </div>
                                        <div>
                                            
                                        </div>
                                        
                                    </td>
                                    <td className="text-right">
                                        Expiring { moment(item.expire_date, "YYYYMMDDh").fromNow() }
                                    </td>    
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Card.Body>
            {spinner && <Spinner />}
        </Card>
    );
}