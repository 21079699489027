import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListingService from "../../services/ListingService";
import { Button, Form, Spinner, Card, InputGroup } from "react-bootstrap";
import { Spinner as Spin } from 'react-bootstrap'
import { FaArrowLeft, FaArrowsRotate } from "react-icons/fa6";
import { ChatBoxRight } from "./ChatBoxRight";
import { ChatBoxLeft } from "./ChatBoxLeft";
import { useAuth } from "../../hooks/useAuth";

export const ThreadPage = () => {

    const { conversationId, receiverId, listingId } = useParams();
    const [messages, setMessages] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [spinner, setSpinner] = useState(true);
    const [sending, setSending] = useState(false);
    const [reloading, setReloading] = useState(false);
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState('Conversation')
    const { user } = useAuth();
    const [info, setInfo] = useState(user?.user)
    

    useEffect(() => {
        setSpinner(false);
        ListingService.messages(listingId, receiverId)
        .then((res) => {
            setMessages(res.data.messages)
            setPageTitle(res.data.listing.website_business_name)
        })
    }, [conversationId]);

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;
        setSending(true);
        ListingService.send(conversationId, {
            message: newMessage.trim(),
            receiver_id: receiverId,
        })
        .then((res) => {
            setSending(false)
            const updatedMessages = [... messages];
            updatedMessages.push(res.data.data)
            setMessages(updatedMessages);
            setNewMessage('');
        })
        .catch(e => {
            console.log(e)
            setSending(false);
        });

    };

    const handleReload = () => {
        setReloading(true);
        ListingService.messages(listingId, receiverId)
        .then((res) => {
            setMessages(res.data.messages);
            setReloading(false);
        })
        .catch(e => console.error('Error fetching conversations', e))
    }

    const handleBack = () => {
        navigate('/user/conversations')
    }

    return (
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                <div className="d-flex justify-content-between">
                    <span>
                        <Button variant="light" size="sm" onClick={() => handleBack()}><FaArrowLeft /> </Button>
                        {pageTitle}
                    </span>
                    <Button variant="primary" size="md" onClick={()=>handleReload()}>
                        {reloading ? (<Spin size="sm" />) : <FaArrowsRotate /> } 
                    </Button>
                </div>
            </Card.Header>
            <Card.Body>

                <div className="position-relative">
                    <div className="chat-messages p-4">
                        {messages && messages.map((message) => (
                            <div key={message.id}>
                            { message.receiver_id !== info.id 
                                ? (<ChatBoxRight  data={message} />) 
                                : (<ChatBoxLeft  data={message} />)
                            }
                            </div>
                        ))}                        
                    </div>
                </div>

            
                        
                
                <Form.Group className="mb-3" controlId="messageInput">

                <InputGroup className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <Button disabled={sending} variant="primary" onClick={handleSendMessage}>
                    {sending ? (
                        <Spinner size="sm" />
                    ) : (
                        `Send`
                    ) }
                    </Button>
                </InputGroup>

                    
                    
                </Form.Group>
                
    
            </Card.Body>
        </Card>


        
    );
}