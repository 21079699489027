import React from 'react';
import { Pagination } from 'react-bootstrap';

export const PaginationComponent = ({ currentPage, pages, onPageChange, itemsPerPge }) => {
    const maxPageNumbersToShow = 5;
    const perPage = itemsPerPge || process.env.REACT_APP_PERPAGE;
    const totalPages = Math.ceil(pages / perPage);

    const getPaginationItems = () => {
        const items = [];
        const startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
        const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

        if (startPage > 1) {
            items.push(
                <Pagination.Item key={1} onClick={() => onPageChange(1)}>
                    1
                </Pagination.Item>
            );
            if (startPage > 2) {
                items.push(<Pagination.Ellipsis key="start-ellipsis" />);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                items.push(<Pagination.Ellipsis key="end-ellipsis" />);
            }
            items.push(
                <Pagination.Item key={totalPages} onClick={() => onPageChange(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination className='justify-content-center'>
            <Pagination.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />
            
            {getPaginationItems()}

            <Pagination.Next onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
};

