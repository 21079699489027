
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { ErrorLabel, Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ListingService from "../../services/ListingService";
import { useToast } from "../../hooks/ToastContext";
import userPlaceholder from '../../assets/svgs/user.png'

export const UserDetailPage = () => {

	// step 1

	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);
	const [thumbnail, setThumbnail] = useState(null);
	const showToast = useToast();


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				about_me: user.data.about_me,
				photo: user.data.photo
			})
			setThumbnail(user.data.photo)
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		data['photo'] = thumbnail;
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/complete/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	const handleThumbnail = (e) => {
        e.preventDefault();
        if (e.target.files.length) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            ListingService.upload(formData)
            .then(file => {
                if (file.data && file.data.path) {
                    setThumbnail(file.data.path);
                }
            })
            .catch(e => {
                showToast({ severity: 'error',  detail: e.response?.data?.message })
            });
        }
    }

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={81} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-5 text-center">
							<label className="w-100 h3">Lastly, add a photo and description</label>

							<p className="text-muted ">This helps create credibility and trust. </p>
							
						</div>

						<div className="form-group">
							<label htmlFor="thumbnail-image" className="image-upload">
								<div className="d-flex">
									<div className="p-2">
										{thumbnail 
											? (<img src={thumbnail} alt="user profile" className="user-avatar" />) 
											: (<img src={userPlaceholder} alt="user profile" className="user-avatar" />) 
										}
									</div>
									<div className="p-2">
										<small>Something that represents you or your company. We recommend a photo of 120px x 120px. </small>
									</div>
								</div>
							</label>
							<input type="file" className="hidden" id="thumbnail-image" accept="image/*" onChange={handleThumbnail} />
							
							
							
							
						</div>

						<div className="form-group my-4">
							<textarea rows={4} 
								className="form-control" name="about_me" 
								placeholder="e.g. I'm a digital asset investor looking to purchase a revenue generating eCommerce business in the fashion industry..." 
								{...register('about_me', {required: true})}></textarea>

								{(errors.about_me && errors.about_me.type==='required') && <ErrorLabel >Please let us know about yout</ErrorLabel>}
							
						</div>

						

						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/seller-location/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
