import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import ReactQuill from 'react-quill';

import { ErrorLabel, Spinner } from "../../components";
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { DomainLinks } from "./DomainLink";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";

export const DomainDescription = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(4);
    const [title, setTitle] = useState('Domain Description');
    const [description, setDescription] = useState(null);
    const showToast = useToast();
   
    const { register, handleSubmit, formState: { errors}, reset   } = useForm();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'domain';
        data.description = description;
        data.step = step;

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/domain-cover/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Domain Description' : 'Domain Description');
            setDescription(res.data.description);
        })
        .catch(e => {
            console.log(e);
        })

    }, [step, id, reset]);

    usePageTitle(title);


    return (
        <Row>
            <Col lg={3}>
                <DomainLinks step={step} id={id} current={4} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Describe your domain
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row className="my-3">
                                <Col lg={12}>
                                    <Form.Label >Tell us about your business so potential buyers get excited. What does your business do?</Form.Label>
                                    <ReactQuill theme="snow"   
                                        value={description} onChange={(e)=>{
                                            setDescription(e ? e : '')
                                        }} 
                                        rows={3} 
                                        
                                    />
                                    {(errors.description) && <ErrorLabel >Description is required</ErrorLabel>}
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/domain-meta/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}