import http from './http-common';
import http2 from './http-backend';

const login = async (payload) => {
  const response = await http.post("/login", payload);
  return response.data;
}

const logout = async () => {
  return await http.get("/logout")
  .then(response => {
    return response.data
  })
  
}

const register = async (payload) => {
  return await http.post("/register", payload)
  .then(response => {
    return response.data
  })
}

const addInfo = async (id, payload) => {
  return await http.post(`/register/info/${id}`, payload);
}

const update = async (id, payload) => {
  return await http.put(`/users/${id}`, payload);
}

const googleAuth = async (payload) => {
  return await http.post('/google-auth', payload)
  .then(response => {
    return response.data;
  })
  .catch(e => {
    console.log(e);
  });
}

const verifyEmail = async (url) => {
  return await http2.get(url);
}

const resend = async () => {
  return await http2.get(`${process.env.REACT_APP_BACKEND}/api/email/resend`);
}

const recover = async (payload) => {
  return await http.post(`password/email`, payload);
}

const reset = async (payload) => {
  return await http.post(`password/reset`, payload);
}

const createPassword = async (payload) => {
  return await http.post(`/password/store`, payload);
}


const user = async (id) => {
  return await http.get(`users/${id}`);
}

const countries = async (id) => {
  return await http.get(`countries`);
}

const visibility = async (payload) => {
  return await http.post(`/users/update-visibility`, payload);
}

const sources = () => {
  return ['From a blog', 'Podcast', 'Google ad', 'Facebook ad', 'YouTube', 'Friend', 'Google search', 'Other'];
}

const AuthService = {
  login,
  logout,
  register,
  addInfo,
  update,
  googleAuth,
  verifyEmail,
  resend,
  recover,
  reset,
  createPassword,
  user,
  sources,
  countries,
  visibility
};

export default AuthService;
