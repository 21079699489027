import { useEffect, useState } from "react";
import ListingService from "../services/ListingService";
import { Badge, Card, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { CURRENCY_STYLE } from "../constants";

export const MoreLikeThis = ({id, listingId}) => {
    const [listing, setListing] = useState([]);

    useEffect(()=>{
        ListingService.relatedListings(id, listingId)
        .then(res => {
            setListing(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    },[id, listingId])

    return (
        <Card className="border-1">
            <Card.Header className="border-0">More businesses like this</Card.Header>
            <Card.Body>
                <ListGroup variant="flush">
                    {listing && listing.map(item => (
                        <ListGroup.Item key={item.id} className="d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold"><NavLink to={`/${item.slug}/${item.id}`} >{item.website_business_name}</NavLink></div>
                                <p className="text-secondary">{item.type} 

                                {item.category && (
                                    <> | {item.category.name}</>
                                ) }
                                </p>
                            </div>

                            <Badge bg="primary" pill>
                            { `${Intl.NumberFormat(process.env.REACT_APP_LOCALE_LANG, CURRENCY_STYLE).format(item.buy_now_price)}` }
                            </Badge>
                            
                        </ListGroup.Item>
                    ))}

                    {!listing.length && (
                        <ListGroup.Item>{process.env.REACT_APP_NO_DATA_AVAILABLE}</ListGroup.Item>
                    )}
                </ListGroup>
            </Card.Body>
        </Card>
    )
}