import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";



import { ErrorLabel, Spinner } from "../../components";

import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';

import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { WebsiteLinks } from "./WebsiteLinks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";



export const WebsiteUrl = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(1);
    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();
    usePageTitle('Create Your Listing');
    const showToast = useToast();

    const onSubmit = data => {
        setSpinner(true)
        data.type = 'website';
        data['step'] =  step;
        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/business/${id}`);
        })
        .catch(e =>{
            showToast({ severity: 'error', detail: e.response?.data?.message })
            setSpinner(false)
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setStep(res.data?.step > step ? res.data?.step : step);
        })
        .catch(e => {
            console.log(e);
        })
    }, []);

    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step} current={1} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Create Website Listing 
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={12}>
                                    <Form.Label htmlFor="url">Enter URL</Form.Label>
                                    <Form.Control type="text" size="lg" 
                                        {...register('url', {
                                            required: true,
                                            pattern: {
                                                value: /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                                                message: 'Enter a valid url'
                                            }  
                                        })}
                                        placeholder="https://yourdomain.com"
                                        
                                    />
                                    {(errors.url && errors.url.type==='required') && <ErrorLabel >URL is required</ErrorLabel>}
                                    {(errors.url?.message) && <ErrorLabel >{errors.url?.message}</ErrorLabel>}
                                </Col>
                            </Row>

                            

                            

                            <Row>
                                <Col lg={12} className="text-right">
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}