import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, Form } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useToast } from "../../hooks/ToastContext";




export const ProfilePage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Profile');
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();

    const [spinner, setSpinner] = useState(false);
    const [serverError, setServerError] = useState([]);
    const { login } = useAuth()
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setCShowPassword] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [user, setUser] = useLocalStorage("user", {});
    const [info, setInfo] = useState(user?.user || null);
    const showToast = useToast();

    useEffect(()=>{
        setInfo(user?.user);
        setValue('name', info.name);
    }, [user])
    
    
    const onSubmit = data => {
        setServerError([])
        setSpinner(true)

        const payload = {
            name: data.name
        }

        if (changePassword) {
            payload['change_password'] = true;
            payload['password'] = data.password;
            payload['cpassword'] = data.cpassword
        }

        AuthService.update(info.id, payload)
        .then(async (res) => {
            setSpinner(false)

            await login({user: res.data, token: user.token});
            showToast({severity: 'success', detail: 'Your profile is successfully updated'});
            setInfo(res.data)

        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    const togglePassword = () => {
        setShowPassword((prevIsActive) => !prevIsActive); 
    }

    const toggleCPassword = () => {
        setCShowPassword((prevIsActive) => !prevIsActive); 
    }

    const handleChangePassword = (e) => {
        setChangePassword(e.target.checked);
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="h3 mb-3 fw-normal">Profile</h1>
                        { serverError && serverError.map((e, i) => (
                            <p key={i} className="text-danger">{e}</p>
                        ))}

                        <div className="form-group my-2">
                            <label >Email</label>
                            <div className="form-control">{info.email}</div>
                            
                        </div>  
                        
                        <div className="form-group">
                            <label >Name</label>
                            <input type="text" 
                                    className="form-control" 
                                    placeholder="Full Name"
                                    {...register('name', {
                                        required: true,
                                        minLength: {
                                            value: 6,
                                            message: 'At least 6 characters'
                                        }
                                    })} 
                            />

                            {(errors.name && errors.name.type==='required') && <div className='text-danger'>Name is required</div>}
                            {(errors.name?.message) && <div className='text-danger'>{errors.name?.message}</div>}
                            
                        </div>

                        <div className="form-group mt-3">
                            <div className="form-check form-check-inline">    
                                

                                <label><Form.Check
                                    type="checkbox"
                                    name="terms"
                                    onClick={(e)=>{handleChangePassword(e)}}
                                    
                                /> Change Password</label>
                            </div>
                                

                        </div>
                        
                        
                        {changePassword && 
                            <>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <div className="input-group">
                                        <input type={showPassword ? 'text' : 'password'}
                                            className="form-control"
                                            placeholder="Password"
                                            {...register('password', {required: true, 
                                                maxLength: 100,
                                                minLength: {
                                                    value: 6,
                                                    message: 'Password must be at least 6 character long'
                                                }})} 
                                        />

                                        <div className="input-group-append _append-text">
                                            <button className="btn btn-" onClick={togglePassword} type="button">{ showPassword ? <FaEyeSlash  /> : <FaEye />  } </button>
                                        </div>
                                    </div>
                                    

                                    {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                                    {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                                </div>

                                <div className="form-group mt-3">
                                <label>Confirm Password</label>
                                <div className="input-group">
                                    <input type={showCPassword ? 'text' : 'password'}
                                            className="form-control"
                                            placeholder="Confirm Password"
                                            {...register('cpassword', {required: true, 
                                                validate: (value) => value === watch("password") || "Password do not match"
                                            })} 
                                    />
                                    <div className="input-group-append _append-text">
                                        <button className="btn btn-" onClick={toggleCPassword} type="button">{ showCPassword ? <FaEyeSlash  /> : <FaEye />  } </button>
                                    </div>

                                </div>


                                {(errors.cpassword && errors.cpassword.type==='required') && <div className='text-danger'>Confirm Password is required</div>}
                                {(errors.cpassword?.message) && <div className='text-danger'>{errors.cpassword?.message}</div>}
                                </div>
                            </>    
                        }
                        

                        

                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Update</button>
                        
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}