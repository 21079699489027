import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa"
export const SocialLinks = () => {
    return(
        <>
            <a href="!#" target="_blank" className="mx-2 text-primary"><FaInstagram /></a>
            <a href="!#" target="_blank" className="mx-2 text-primary"><FaFacebook /></a>
            <a href="!#" target="_blank" className="mx-2 text-primary"><FaTwitter /></a>
            <a href="!#" target="_blank" className="mx-2 text-primary"><FaLinkedin /></a>
        </>
    )
}