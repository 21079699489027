import { ListGroup } from "react-bootstrap"
import { FaEye, FaTag, FaUserCircle } from "react-icons/fa"
import { FaEarthEurope, FaGauge, FaMessage } from "react-icons/fa6"
import { NavLink } from "react-router-dom"
import { LogoutButton } from "./LogoutButton"

export const DashboardNav = () => {
    return (
        <ListGroup className=" m-2 dashboard-nav d-none d-md-block d-lg-block">
            <ListGroup.Item><NavLink to={`/user/dashboard`}> <FaGauge /> Dashboard</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/user/conversations`}> <FaMessage /> Messages</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/user/watchlist`}> <FaEye /> Watching</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/user/selling`}> <FaEarthEurope /> Selling</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/user/buying`}> <FaTag /> Buying</NavLink></ListGroup.Item>
            <ListGroup.Item><NavLink to={`/user/profile`}> <FaUserCircle /> Profile</NavLink></ListGroup.Item>
            <ListGroup.Item><LogoutButton icon={true} /></ListGroup.Item>
        </ListGroup>
    )
}