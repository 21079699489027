import { useEffect, useState } from "react";
import { Accordion, Badge, Card } from "react-bootstrap";
import moment from"moment";

import { usePageTitle } from "../../hooks/usePageTitle";

import ListingService from "../../services/ListingService";
import { Spinner } from "../../components";
import { BuyerBiddingDetail } from "../../components/BuyerBiddingDetail";
import { ConfirmDialog } from "primereact/confirmdialog";

export const BuyPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Manage Listings');
    const [listings, setListings] = useState(null);
    const [spinner, setSpinner] = useState(null);

    useEffect(()=> {
        ListingService.buying()
        .then(res => {
            setListings(res.data)
        })
        .catch(e => {
            console.log(e);
        })
    }, []);


    return(
        <Card className="shadow-sm border-0">
            <Card.Header className="border-0 h2">
                Buying
            </Card.Header>
            <Card.Body>

                <Accordion defaultActiveKey={0}>
                    {listings && listings.map((item, i) => (
                        <Accordion.Item key={i} eventKey={i}>
                            <Accordion.Header>
                            
                                <div className="d-flex justify-content-between w-75">
                                    <div>
                                        <Badge bg="success" className="m-1">{item.type}</Badge> {item.website_business_name}
                                    </div>
                                    
                                    <div>
                                        {item.status === 1 && <Badge bg="success" className="m-1">Active</Badge>} Expiring { moment(item.expire_date, "YYYYMMDDh").fromNow() }
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <h5 className="text-dark">Offers</h5>
                                <BuyerBiddingDetail data={item.bids} />
                            </Accordion.Body>
                        </Accordion.Item>
                        
                    ))}
                </Accordion>
            </Card.Body>
            <ConfirmDialog />
            {spinner && <Spinner />}
        </Card>
    );
}