
import { Badge } from "react-bootstrap";

export const DomainAnalytics = ({item}) => {


    return (
        <>
            <h5 className="text-dark">About domain</h5>
            <div dangerouslySetInnerHTML={{__html: item.description}}></div>
            <hr />
            <div>
                <h5 className="text-dark">Meta Description</h5>
                <p>{item.meta_description}</p>
            </div>
            <hr />
            <div>
                <h5 className="text-dark">Tags</h5>
                <p>
                    {item.meta_keywords && item.meta_keywords.split(',').map((tag,i) => (<Badge key={i} bg="secondary" className="m-1">{tag}</Badge>))}
                </p>
            </div>
            
            
        </>

    )
} 