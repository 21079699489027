import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, Form } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";

import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";




export const SignupPage = () => {
    usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - Signup');
    const navigate = useNavigate();

    const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();

    const [spinner, setSpinner] = useState(false);
    const [serverError, setServerError] = useState([]);
    const { login } = useAuth()
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setCShowPassword] = useState(false);
    
    
    const onSubmit = data => {
        setServerError([])
        setSpinner(true)
        AuthService.register(data)
        .then(async (res) => {
            setSpinner(false)
            await login(res);
            navigate('/profile/info/' + res.user.id)
        })
        .catch(e =>{
            setSpinner(false)
            setServerError(e.response?.data?.errors);
        });
    }

    const togglePassword = () => {
        setShowPassword((prevIsActive) => !prevIsActive); 
    }

    const toggleCPassword = () => {
        setCShowPassword((prevIsActive) => !prevIsActive); 
    }

    const recaptchaRef = useRef();

    const onRecaptcha = (token) => {
        setValue('recaptcha', token);
    }

    return (
        <div className="form-signin w-100 m-auto">

            {spinner && <Spinner />}

            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="h3 mb-3 fw-normal">Welcome to {process.env.REACT_APP_SITE_NAME}</h1>
                        { serverError && serverError.map((e, i) => (
                            <p key={i} className="text-danger">{e}</p>
                        ))}
                        
                        <div className="form-group">
                            <label >Name</label>
                            <input type="text" 
                                    className="form-control" 
                                    placeholder="Full Name"
                                    {...register('name', {
                                        required: true,
                                        minLength: {
                                            value: 6,
                                            message: 'At least 6 characters'
                                        }
                                    })} 
                            />

                            {(errors.name && errors.name.type==='required') && <div className='text-danger'>Name is required</div>}
                            {(errors.name?.message) && <div className='text-danger'>{errors.name?.message}</div>}
                            
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="floatingInput">Email</label>
                            <input type="text" 
                                    className="form-control" 
                                    id="floatingInput" 
                                    placeholder="name@example.com"
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9])?/,
                                            message: 'Enter a valid email address'
                                        }
                                    })} 
                            />

                            {(errors.email && errors.email.type==='required') && <div className='text-danger'>Email is required</div>}
                            {(errors.email?.message) && <div className='text-danger'>{errors.email?.message}</div>}
                            
                        </div>
                        
                        <div className="form-group mt-3">
                            <label>Password</label>
                            <div className="input-group">
                                <input type={showPassword ? 'text' : 'password'}
                                    className="form-control"
                                    placeholder="Password"
                                    {...register('password', {required: true, 
                                        maxLength: 100,
                                        minLength: {
                                            value: 6,
                                            message: 'Password must be at least 6 character long'
                                        }})} 
                                />

                                <div className="input-group-append _append-text">
                                    <button className="btn btn-" onClick={togglePassword} type="button">{ showPassword ? <FaEyeSlash  /> : <FaEye />  } </button>
                                </div>
                            </div>
                            

                            {(errors.password && errors.password.type==='required') && <div className='text-danger'>Password is required</div>}
                            {(errors.password?.message) && <div className='text-danger'>{errors.password?.message}</div>}
                        </div>

                        <div className="form-group mt-3">
                            <label>Confirm Password</label>
                            <div className="input-group">
                                <input type={showCPassword ? 'text' : 'password'}
                                        className="form-control"
                                        placeholder="Confirm Password"
                                        {...register('cpassword', {required: true, 
                                            validate: (value) => value === watch("password") || "Password do not match"
                                        })} 
                                />
                                <div className="input-group-append _append-text">
                                    <button className="btn btn-" onClick={toggleCPassword} type="button">{ showCPassword ? <FaEyeSlash  /> : <FaEye />  } </button>
                                </div>

                            </div>
                            

                            {(errors.cpassword && errors.cpassword.type==='required') && <div className='text-danger'>Confirm Password is required</div>}
                            {(errors.cpassword?.message) && <div className='text-danger'>{errors.cpassword?.message}</div>}
                        </div>

                        <div className="form-group mt-3">
                            <div className="form-check form-check-inline">    
                                <Form.Check
                                    type="checkbox"
                                    name="terms"
                                    id="terms"
                                    value={1}
                                    {...register('terms', {required: true})}
                                />

                                <label>I accept <a target="_blank" href="/term-of-service">Terms &amp; Conditions</a></label>
                            </div>
                                

                            {(errors.terms) && <div className='text-danger'>You must accept the terms and conditions</div>} 
                        </div>

                        <div className="form-group mt-3">
                            <ReCAPTCHA
                                sitekey={`${process.env.REACT_APP_RECAPRCHA_KEY}`}
                                onChange={(e) => onRecaptcha(e)}
                                ref={recaptchaRef}
                            />
                            <input
                                    type="hidden"
                                    {...register('recaptcha', {
                                    required: 'Please complete the reCAPTCHA',
                                    })}
                            />
                            {(errors.recaptcha && errors.recaptcha.type==='required') && <div className='text-danger'>Please complete the reCAPTCHA</div>}
                        </div>

                    
                        <button className="btn btn-primary w-100 py-2 my-3" type="submit">Continue</button>
                        
                        <p className="text-sm ">
                            Already have an account? <Link to="/login">Login here</Link>
                        </p>
                    </form>
                </CardBody>
            </Card>
            
            
        </div>
    );
}