import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import ReactQuill from 'react-quill';


import { ErrorLabel, Spinner } from "../../components";
import data from '../../assets/data/countries.json'
import ListingService from "../../services/ListingService";

import 'react-quill/dist/quill.snow.css';


import { Card, Form, Button, Row, Col, InputGroup, ListGroup } from "react-bootstrap";

import { WebsiteLinks } from "./WebsiteLinks";
import { usePageTitle } from "../../hooks/usePageTitle";
import { useToast } from "../../hooks/ToastContext";


export const WebsiteFinancial = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [step, setStep] = useState(3);
    const [title, setTitle] = useState('Your business details');
    const showToast = useToast();

    const [financialUploadVisual, setFinancialUploadVisual] = useState(null);
    const [financialUploadPL, setFinancialUploadPL] = useState(null); 

    const { register, handleSubmit, formState: { errors}, reset, getValues, setValue   } = useForm();


    const calculateProfit = () => {
        const [revenue, expense] = getValues(['last_12months_revenue', 'last_12months_expenses']);
        const profit = parseFloat(revenue) - parseFloat(expense);
        if(!isNaN(profit)) {
            setValue('annual_profit', profit);
        }
    }

    const handleFileRevenue = (e) => {
        e.preventDefault();
        if (e.target.files.length) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            ListingService.upload(formData)
            .then(file => {
                if (file.data && file.data.path) {
                    setFinancialUploadVisual(file.data.path);
                }
            })
            .catch(e => {
                showToast({ severity: 'error',  detail: e.response?.data?.message })
            });
        }
    }

    const handleFileProfitLoss = (e) => {
        e.preventDefault();
        if (e.target.files.length) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            ListingService.upload(formData)
            .then(file => {
                if (file.data && file.data.path) {
                    setFinancialUploadPL(file.data.path);
                }
            })
            .catch(e => {
                showToast({ severity: 'error',  detail: e.response?.data?.message })
            });
        }
    }

    const onSubmit = data => {
        
        setSpinner(true)

        data.type = 'website';
        data.financial_upload_visual = financialUploadVisual;
        data.financial_upload_pl = financialUploadPL;
 

        ListingService.update(id, data)
        .then((res) => {
            showToast({ severity: 'success',  detail: 'Your information are successfully stored' })
            setSpinner(false);
            navigate(`/user/listings/monetization/${id}`);
        })
        .catch(e =>{
            setSpinner(false)
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        });
    }

    useEffect(()=>{
        ListingService.getOne(id)
        .then(res => {
            reset(res.data);
            setFinancialUploadVisual(res.data.financial_upload_visual);
            setFinancialUploadPL(res.data.financial_upload_pl);
            setStep(res.data?.step > step ? res.data?.step : step);
            setTitle(res.data.website_business_name ? res.data.website_business_name + ' - Financial Information' : ' Financial Information')
        })
        .catch(e => {
            showToast({ severity: 'error',  detail: e.response?.data?.message })
        })        

    }, []);

    usePageTitle(title);


    return (

        <Row>
            <Col lg={3}>
                <WebsiteLinks id={id} step={step} current={3} />
            </Col>
            <Col lg={9}>
                <Card className="shadow-sm border-0">
                    <Card.Header className="border-0 h2">
                        Financial Information
                    </Card.Header>
                    <Card.Body>
                        <Form  onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label>Last 12 months revenue</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                            <Form.Control 
                                                type='text'  size="lg"
                                                {...register('last_12months_revenue', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(0|[1-9][0-9]*)$/,
                                                        message: 'Enter a valid number'
                                                    }
                                                })}
                                                onKeyUp={calculateProfit}
                                                onChange={calculateProfit}
                                            />
                                            
                                        </InputGroup>
                                        {(errors.last_12months_revenue && errors.last_12months_revenue.type==='required') && <ErrorLabel >Last 12 month revenue is required</ErrorLabel>}
                                        {(errors.last_12months_revenue?.message) && <ErrorLabel >{errors.last_12months_revenue?.message}</ErrorLabel>}
                                    </Form.Group>
                                </Col>

                                <Col lg={4}>
                                    <Form.Group>
                                        

                                        <Form.Label >Last 12 months expenses</Form.Label>

                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                            <Form.Control 
                                                type='text'  size="lg"
                                                {...register('last_12months_expenses', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(0|[1-9][0-9]*)$/,
                                                        message: 'Enter a valid number'
                                                    }
                                                })}
                                                onKeyUp={calculateProfit}
                                                onChange={calculateProfit}
                                            />
                                            
                                        </InputGroup>
                                        

                                        {(errors.last_12months_expenses && errors.last_12months_expenses.type==='required') && <ErrorLabel >Last 12 month expense is required</ErrorLabel>}
                                        {(errors.last_12months_expenses?.message) && <ErrorLabel >{errors.last_12months_expenses?.message}</ErrorLabel>}
                                    </Form.Group>
                                </Col>

                                <Col lg={4}>
                                    <Form.Group>
                                        <Form.Label >Annual Profit</Form.Label>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text>{process.env.REACT_APP_CURRENCY || '$'}</InputGroup.Text>
                                            <Form.Control 
                                                type='text'  size="lg"
                                                {...register('annual_profit', {
                                                    required: true,
                                                    pattern: {
                                                        value: /^(0|[1-9][0-9]*)$/,
                                                        message: 'Enter a valid number'
                                                    }
                                                })}
                                                onFocus={calculateProfit}
                                            />
                                        </InputGroup>
                                        
                                    
                                        {(errors.annual_profit && errors.annual_profit.type==='required') && <ErrorLabel >Annual profit is required</ErrorLabel>}
                                        {(errors.annual_profit?.message) && <ErrorLabel >{errors.annual_profit?.message}</ErrorLabel>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={6}>
                                    <Form.Label >Financial </Form.Label>
                                    <Form.Control type="file" id="file-upload" accept="image/*" onChange={handleFileRevenue} />
                                    
                                    {financialUploadVisual && <><img src={financialUploadVisual} className="img-fluid p-3" /> <br/></>}
                                    
                                    <small>Visual Evidence of Revenue Screenshot or video walkthrough. Can be from Quickbooks, AdSense, Shopify, Amazon, PayPal, etc.</small>
                                </Col>

                                <Col lg={6}>
                                    <Form.Label >P&amp;L Statement</Form.Label>
                                    <Form.Control type="file" id="file-upload-pl"  onChange={handleFileProfitLoss}  accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    { financialUploadPL && <a className="p-3" target="_blank" href={financialUploadPL} >Download</a>}
                                    <p></p>
                                    <small>P&amp;L (Profit and Loss Statement), Please ensure this is up to date to gain customer trust towards your listings.Ignore if you don't have this</small>
                                </Col>
                            </Row>

                            


                            <Row>
                                <Col lg={12} className="d-flex justify-content-between">
                                    <Link className="my-4 btn btn-secondary"  to={`/user/listings/business/${id}`}> Back</Link>
                                    <Button className="my-4" type="submit" color="primary" >Next</Button>
                                </Col>
                            </Row>
                            

                            {spinner && (
                                <Spinner />
                            )}
                            
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        


    )
}