import { useEffect, useState } from "react";
import { PageHeaderComponent } from "../../components/PageHeaderComponent"
import { AdvanceSearchbox } from "./AdvanceSearchbox"
import { FaFilter } from "react-icons/fa";
import { Button } from "react-bootstrap";
import ListingService from "../../services/ListingService";
import { Spinner } from "../../components/Spinner";
import { ListingItem } from "../../components/ListingItem";
import { PaginationComponent } from "../../components/PaginationComponent";

export const SearchPage = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [page, setPage] = useState(1);
    const [listings, setListings] = useState(null);
    const [payload, setPayload] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSearchSubmit = (data) => {
        setPayload(data);
        fetchData()

        // navigate(`?query=${data.query}&age=${data.age ?? ''}&revenue_min=${data.revenue_min ?? ''}&revenue_max=${data.revenue_max ?? ''}&profit_min=${data.profit_min ?? ''}&profit_max=${data.profit_max ?? ''}&range_min=${data.range_min ?? ''}&range_max=${data.range_max ?? ''}`)
    }

    const handlePageChange = (page) => {
        setPage(page);
        fetchData();
    }

    const fetchData = () => {
        setSpinner(true);
        ListingService.searchListings(`?page=${page}`, payload)
        .then(res => {
            setSpinner(false);
            setListings(res.data)
        })
        .catch(e => {
            setSpinner(false);
            console.log(e);
        })
    } 

    useEffect(()=> {
        fetchData();
    }, [])

    return (
        <>
            <PageHeaderComponent title={'Search'}  />
            <section className="container team">

                <div className="row">
        
                    <div className={`col-12 col-md-3 bg-light sidebar ${isSidebarOpen ? 'd-block' : 'd-none d-md-block'}`}>
                        <div className="py-2 d-md-none d-flex justify-content-between">
                            <Button size="sm" variant="secondary" onClick={toggleSidebar}><FaFilter /> Filter by</Button>
                            <Button size="sm" variant="outline-danger">Clear</Button> 
                        </div>

                        <div className="py-2 d-none d-lg-block ">
                            <div className="d-flex justify-content-between">
                                <div><FaFilter /> Filter by</div>
                                <Button size="sm" variant="outline-danger">Clear</Button> 
                            </div>
                        </div>

                        <hr className="m-0 my-1" />

                        <div className="py-1">
                            <AdvanceSearchbox handleSearchSubmit={(data) => handleSearchSubmit(data)} query={'find me'} />
                        </div>
                    </div>

                    <div className="col-12 col-md-9">
                        <div className="d-md-none p-2">
                            <Button size="sm" variant="light" onClick={toggleSidebar}><FaFilter /> Filter by</Button>
                        </div>

                        <div className="p-3">
                            {spinner && <Spinner />}
                            { listings && listings.data.map((listing) => {
                                return (<ListingItem key={listing.id} item={listing}  />)
                            })}

                            { (listings && listings.data.length) && 
                                (<PaginationComponent 
                                    currentPage={listings.current_page}
                                    pages={listings.total}
                                    onPageChange={handlePageChange}
                                    itemsPerPge={10} />)
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}