import http from './http-common';


const users = async (page) => {
  return await http.get(`/users${page}`)
};



const UserService = {
  users
};

export default UserService;
