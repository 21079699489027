import http from './http-google';

const accounts = async () => {
    const response = await http.get("https://analyticsadmin.googleapis.com/v1beta/accounts");
    return response.data;
}


const properties = async (id) => {
    const response = await http.get(`https://analyticsadmin.googleapis.com/v1beta/properties?filter=parent:${ id }`);
    return response.data;
};


const GoogleService = {
    accounts,
    properties
};

export default GoogleService;