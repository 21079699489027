
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEnvelopeOpenText, FaGauge, FaRing } from "react-icons/fa6";
import { SocialLinks } from "../components/SocialLinks";

export const TopHeader = () =>  {

    const [user] = useLocalStorage("user", {});
    const [info, setInfo] = useState(user?.user || null);

    useEffect(()=>{
        setInfo(user?.user)
    }, [user])

    return (
        <div className=" bg-white">
            <div className="container-fluid container-xl p-2">
                <div className="d-flex justify-content-between">
                    <div>
                        <Link to={'/contact-us'} className="mx-2 text-secondary"><FaEnvelopeOpenText /> <span className="d-none d-md-inline">Contact us</span> </Link>
                        <span className="mx-2 text-secondary d-none d-md-inline">|</span>
                        <SocialLinks />
                    </div>

                    {info 
                        ? (<div>
                            <span className="d-none d-md-inline">Welcome back </span>
                            {info.name} |
                            <Link className="mx-2 text-secondary" to={info.is_admin ? '/admin/dashboard' : '/user/dashboard'}><FaGauge /> <span className="d-none d-md-inline">Dashboard</span></Link>
                        </div>) 
                        : (<div>
                            <Link className="mx-2 text-secondary" to={`/login`}>Login</Link>
                            <span className="mx-2 text-secondary">|</span>
                            <Link className="mx-2 text-secondary" to={`/register`}>Create account</Link>
                        </div>)}
                </div>
                
            </div>

        </div>
    )
}