import { Card, CardBody } from "react-bootstrap"

export const ResetLinkSuccessPage = () => {
    return (
        <div className="form-signin w-100 m-auto">
            <Card>
                <CardBody>
                    <p>We have emailed your password reset link.</p>
                    <p>Follow the instructions to reset your password.</p>
                </CardBody>
            </Card>
        </div>
    )
}