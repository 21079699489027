
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Spinner } from "../../components";

import AuthService from "../../services/AuthService";
import { Card, CardBody, ProgressBar, Button } from "react-bootstrap";
import { usePageTitle } from "../../hooks/usePageTitle";
import whos from '../../assets/data/who_am_i.json'

import { useNavigate, useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

export const UserOnlineBusinessPage = () => {

	// step 1

	usePageTitle( process.env.REACT_APP_SITE_NAME +  ' - User information');
	const navigate = useNavigate();
	const {id} = useParams();
	
	const { register, handleSubmit, formState: { errors }, reset } = useForm();

	const [spinner, setSpinner] = useState(false);
	const [serverError, setServerError] = useState([]);


	useEffect(()=> {
		AuthService.user(id)
		.then(user => {
			reset({
				why_online_business: user.data.why_online_business || "I represent a company or investment fund, and we're acquiring a portfolio of assets.",
				invest_rather_than_buy: user.data.invest_rather_than_buy ?  'Yes' : 'No',
				accredited_investor: user.data.accredited_investor ? 'Yes' : 'No',
			})
		})
		.catch(e => {
			console.log(e);
		})
	}, [id, reset])

	

	const onSubmit = data => {
		setServerError([])
		setSpinner(true)
		data['info'] = '1';
		data['invest_rather_than_buy'] = data.invest_rather_than_buy === 'Yes' ? 1 : 0
		data['accredited_investor'] = data.accredited_investor === 'Yes' ? 1 : 0
		
		AuthService.addInfo(id, data)
		.then(async (res) => {
			setSpinner(false)
			navigate('/profile/business-experience/' + id);
		})
		.catch(e =>{
			setSpinner(false)
			setServerError(e.response?.data?.errors);
		});
	}

	
	return (
		<div className="w-50 m-auto">
					
			{spinner && <Spinner />}

			<Card className="border-0">
				<CardBody>
					<ProgressBar variant="success" now={1} />
					<form onSubmit={handleSubmit(onSubmit)}>
							
						{ serverError && serverError.map((e, i) => (
							<p key={i} className="text-danger">{e}</p>
						))}

						<div className="form-group my-4">
							<label className="strong">Why are you looking for an online business?</label>
							{whos && whos.map((item, i) => (
								<div className="py-2 " key={i}>
									<label htmlFor={`why-${i}`}>
										<input name="why_online_business" 
												id={`why-${i}`} className="mr-1" 
												type="radio" value={item} 
												{...register('why_online_business')} /> { item }
									</label>	
								</div>
							))}
							{(errors.type ) && <div className='text-danger'>You must select an option</div>}
						</div>

						<div className="form-group my-4">
							<label className="strong">Would you consider investing in businesses rather than buying? </label>
							<div className="d-flex">
								<div>
									<input type="radio" 
										className="mr-1" id="invest_yes" 
										name="invest_rather_than_buy" value="Yes"
										{...register('invest_rather_than_buy')}/> <label htmlFor="invest_yes">Yes</label>
								</div>
								<div className="mx-5">
									<input type="radio" 
										className="mr-1" id="invest_no" 
										name="invest_rather_than_buy" value="No" 
										{...register('invest_rather_than_buy')}/> <label htmlFor="invest_no">No</label>
								</div>
							</div>
						</div>

						<div className="form-group my-4">
							<label className="strong">Are you an accredited investor?</label>
							<div className="d-flex">
								<div>
									<input type="radio" 
										className="mr-1" id="accredited_yes" 
										name="accredited_investor" value="Yes" 
										{...register('accredited_investor')}/> <label htmlFor="accredited_yes">Yes</label>
								</div>
								<div className="mx-5">
									<input type="radio" 
										className="mr-1" id="accredited_no" 
										name="accredited_investor" value="No" 
										{...register('accredited_investor')}/> <label htmlFor="accredited_no">No</label>
								</div>
							</div>
						</div>

						<div className="d-flex justify-content-between my-5">
							<Button onClick={()=>{navigate('/profile/tips/' + id)}} variant="outline-secondary" size="lg" ><FaAngleLeft /> Back</Button>
							<Button type="submit" variant="primary" size="lg" >Continue <FaAngleRight /></Button>
						</div>
							
							
					</form>
				</CardBody>
			</Card>
			
		</div>
	);
}
